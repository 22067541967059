import { container, inject,singleton } from 'tsyringe';
import { LoginToken } from '../models/login';
import { Space } from '../models/space';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';

@singleton()
export class SpaceBookmarkApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async createAsync(spaceId: number) {
        return this.postAsync(`/api/space-bookmark/${spaceId}`, this._authService.token);
    }

    async removeAsync(spaceId: number) {
        return this.deleteObjectAsync(`/api/space-bookmark/${spaceId}`, this._authService.token);
    }
}