import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { ModalDialogService, ModelDialog } from "../../services/modal-editor.service";
import { TextAreaEditorElement } from "../editors/textarea-editor.element";
import { container } from "tsyringe";

@customElement("se-confirm-with-comments-dialog")
export class ConfirmWithCommentsDialogElement extends LitElement implements ModelDialog {
    private _modalService: ModalDialogService;
    @state() private _title: string;
    @state() private _body: string;
    @state() private _saveCaption: string;
    @state() private _cancelCaption: string;
    @state() private _defaultComments: string;

    @query("#editorForm") private _editorForm: HTMLFormElement;
    @query("#editorContainer") private _editorContainer: HTMLDivElement;
    @query("#comments") private _comments: TextAreaEditorElement;

    @property() name: string;

    constructor() {
        super();

        this._modalService = container.resolve(ModalDialogService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    showDialog(options: { title: string; body: string; comments: string; saveCaption?: string; cancelCaption?: string }) {
        this._title = options.title;
        this._body = options.body;
        this._defaultComments = options.comments;
        this._saveCaption = options.saveCaption;
        this._cancelCaption = options.cancelCaption;
    }

    private cancel(ev) {
        ev.preventDefault();
        this._modalService.close(false);
    }

    private async saveAsync(ev) {
        ev.preventDefault();
        this._modalService.close(true, this._comments.liveValue);
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="header">
                    <div style="font-weight: bold">${this._title}</div>
                    <div class="icon" @click=${this.cancel}><fa-icon scale="1.2" fa-class="fas fa-times-circle"></fa-icon></div>
                </div>
                <div class="body">
                    <div class="container" id="editorContainer">${unsafeHTML(this._body)}
                    <br/><br/>
                    <se-textarea-editor id="comments" label="Comments (optional)" .value="${this._defaultComments}" rows="4"></se-textarea-editor>
                    </div>
                    <div class="savePanel">
                        <se-primary-button @click="${this.saveAsync}" text=${this._saveCaption ?? "Save"}></se-primary-button>
                        <se-secondary-button @click="${this.cancel}" text="${this._cancelCaption ?? "Cancel"}"></se-secondary-button>
                    </div>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
        }
        .editor {
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            margin: auto;
            margin-top: 20vh;
            box-shadow: 4px 4px 4px rgb(80, 80, 80);
            font: var(--font);
            width: 350px;
        }
        .body {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;            
            min-height: 0;
            padding: 0px;
            border-radius: 0px 0px 5px 5px;
            width: 100%;
        }
        .header {
            background-color: var(--color-light);
            padding: 5px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
            align-items: center;
            color: black;
            border-radius: 5px 5px 0px 0px;
            border-bottom: solid 1px whitesmoke;
        }
        .icon {
            color: gray;
        }
        .icon:hover {
            color: darkred;
        }
        .container {
            height: 100%;
            min-height: 60px;
            overflow: auto;
            padding: 15px;
            background-color: white;
            box-sizing: border-box;
            border-top: 1px solid gray;
            border-bottom: 1px solid gray;
            border-radius: 3px 3px;
        }
        .text-container {
            height: 100%;
            min-height: 60px;
            overflow: auto;
            padding: 15px;
            background-color: white;
            box-sizing: border-box;
            border-top: 1px solid gray;
            border-bottom: 1px solid gray;
            border-radius: 3px 3px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            padding: 8px 5px 10px 50px;
        }
    `;
}
