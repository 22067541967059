export enum AuditLogOperation {
    Add = 1,
    Update = 2,
    Delete = 3,
}
export enum ConfigAuditObjectType {
    Agent = 1,
    Template = 2,
    File = 3,
    Task = 4,
}
export enum AuditAccountObjectType {
    Organization = 1,
    User = 2,
    UserGroup = 3,
    ProxyPool = 4,
    ApiKey = 5,
    Space = 6,
    Label = 7,
    Destination = 8,
}
export enum SystemAuditObjectType {
    RateLimit = 1,
    Organization = 2,
    Destination = 3,
}

export class RunAuditLogModel {
    id: number;
    runId?: number;
    sequence?: number;
    spaceId?: number;
    spaceName?: string;
    configId?: number;
    configName: string;
    organizationId?: number;
    organizationName: string;
    initiatedBy?: string;
    status: string;
    proxyPools?: string;
    startUrl?: string;
    operation: AuditLogOperation;
    created: Date;
}
export class AccountAuditLogModel {
    id: number;
    organizationId: number;
    type?: string;
    description?: string;
    initiatedBy?: string;
    created: Date;
}

export class ConfigAuditLogModel {
    id: number;
    organizationId: number;
    spaceId?: number;
    spaceName?: string;
    configId?: number;
    configName: string;
    objectType?: ConfigAuditObjectType;
    name?: string;
    initiatedBy?: string;
    description?: string;
    operation: AuditLogOperation;
    created: Date;
}
export class LoginAuditLogModel {
    id: number;
    organizationId?: number;
    userName?: string;
    loginName?: string;
    isFailure: boolean;
    accessLevel?: string;
    ipAddress: string;
    location?: string;
    created: Date;
}
export class SystemAuditLogModel {
    id: number;
    organizationId: number;
    type?: string;
    name?: string;
    initiatedBy?: string;
    created: Date;
}
