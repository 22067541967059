import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { FileUploadApi } from "../../services/file-upload.api";
import { ModalDialogService } from "../../services/modal-editor.service";
import { UserState } from "../../services/user.state";
import { BaseEditor } from "../editors/base-editor";
/*import { SyntaxEditorElement } from "../components/syntax-editor.element";*/

@customElement("se-shared-file-editor")
export class SeSharedFileEditorElement extends LitElement {
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _fileUploadApi: FileUploadApi;
    private _modalService: ModalDialogService;
    private _configService: ConfigService;
    private _userState: UserState;
    private _sessionId: string;
    private _configId: number;
    private _configName: string;
    @state() _configFileText: string | ArrayBuffer;

    @query("#textFileEditor") private _textFileEditor: HTMLTextAreaElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        //this._userApi = container.resolve(UserApi);
        this._userState = container.resolve(UserState);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
        this._fileUploadApi = container.resolve(FileUploadApi);
        this._configService = container.resolve(ConfigService);
        this._sessionId = sessionStorage.getItem("sessionId");
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isUser) {
            return commands.redirect("/login");
        }
        if (location.params.configId) {
            this._configId = parseInt(location.params.configId.valueOf() as string);
            const queryPars = new URLSearchParams(location.search);
            this._configName = queryPars.get("name");

            this._configFileText = "";
            this.openFile(this._configId, this._configName);
        } else {
            return commands.prevent();
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async openFile(configId: number, configName: string) {
        try {
            const result = await this._fileUploadApi.downloadSharedFileAsync(configId, configName);
            if (result.isOk) {
                const data = result.value;

                this._configFileText = await this._configService.readTextFromFileAsync(data);
            } else {
                const error = result.err;
                this._toasterService.showNetworkError(error);
            }
        } catch (error) {
            this._toasterService.showNetworkError(error);
        }
    }

    async saveAsync() {
        if (this.reportValidity()) {
            const text = this._textFileEditor.value;

            const res = await this._configService.api.updateSharedFileAsync(this._configId, text, this._configName);
            if (res.isOk) {
                this._toasterService.showSuccessTitle("File updated", `${this._configName} updated successfully.`);
                history.back();
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="scroll-container" style="display: flex;gap: 10px;flex-direction: column;">
                    <div class="h3">${this._configName}</div>
                    <textarea
                        style="width:100%;height:100%;box-sizing: border-box;resize: none;"
                        id="textFileEditor"
                        .value=${this._configFileText}
                    ></textarea>
                </div>

                <div class="savePanel">
                    <se-primary-button .action="${() => this.saveAsync()}" action-delay="500" text="Save Shared File"></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            font-weight: bold;
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
            width: 100%;
            height: 100%;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            width: 100%;
        }
        .upload-area {
            width: 500px;
            height: 500px;
            line-height: 500px;
            border: 1px dashed blue;
            text-align: center;
            vertical-align: middle;
        }
        .upload-area span {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
