import { container, inject,singleton } from 'tsyringe';
import { UserGroup } from '../models/user-group';
import { User } from '../models/user';
import { UserGroupsViewModel } from '../models/user-groups-view-model';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';

@singleton()
export class UserGroupApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<UserGroup>(`/api/user-group/${id}`, this._authService.token);
    }
    async getGroupUsersAsync(id: number, pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<UserGroup[]>(`/api/user-group/users/${id}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, this._authService.token);
    }
    async getAllAsync(pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<UserGroupsViewModel>(`/api/user-group?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, this._authService.token);
    }
    async getListAsync() {
        return this.getObjectAsync<UserGroupsViewModel>(`/api/user-group/list`, this._authService.token);
    }
    async createAsync(name: string, description: string, userIds: number[]) {
        return this.postObjectAsync(`/api/user-group`, { name: name, description: description, ids: userIds }, this._authService.token);
    }

    async updateAsync(id: number, name: string, description: string, userIds: number[]) {
        return this.putObjectAsync(`/api/user-group/${id}`, { name: name, description: description, ids: userIds }, this._authService.token);
    }

    async deleteAllAsync(ids: number[]) {
        return this.deleteAllObjectsAsync(`/api/user-group/all`, { ids: ids }, this._authService.token);
    }

    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/user-group/${id}`, this._authService.token);
    }
}