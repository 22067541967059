import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import instagram from "../../../../assets/instagram.svg";
import linkedin from "../../../../assets/linkedin.svg";
import logo from "../../../../assets/logo.png";
import twitter from "../../../../assets/twitter.svg";
import { AuthService } from "../../services/auth.service";
import { MultiFactorAuthService } from "../../services/multi-factor-auth.service";
import "../components/primary-button.element";
import "../components/secondary-button.element";
import { BaseEditor } from "../editors/base-editor";
import "../editors/input-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";

@customElement("se-multi-factor-authentication-setup")
export class MultiFactorAuthenticationSetupElements extends LitElement {
    private _toasterService: ToasterService;
    private _multiFactorAuthService: MultiFactorAuthService;
    private _authService: AuthService;
    @state() private _step = 1;
    @state() private _qrCodeSetupImageUrl: string;
    @state() private _setupCode: string;
    @state() private _account: string;

    @query("#securityCode") private _securityCodeEditor: InputEditorElement;

    @state() private _hasChanged = false;

    constructor() {
        super();

        this._toasterService = container.resolve(ToasterService);
        this._authService = container.resolve(AuthService);
        this._multiFactorAuthService = container.resolve(MultiFactorAuthService);
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }
        return undefined;
    }

    async connectedCallback() {
        super.connectedCallback();
        await this.GetTwoFactorAuthentication();
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
    }
    disconnectedCallback() {
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        super.disconnectedCallback();
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }

    updateChangedValues() {}

    show() {
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }

    firstUpdated() {}

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async GetTwoFactorAuthentication() {
        const result = await this._multiFactorAuthService.GetTwoFactorAuthentication();

        if (result.isOk) {
            this._qrCodeSetupImageUrl = result.value.qrCodeSetupImageUrl;
            this._setupCode = result.value.setupCode;
            this._account = result.value.account;
        } else {
            this._toasterService.showError(result.err.message);
        }
    }

    private async next() {
        if (this._step === 3) {
            if (this.reportValidity()) {
                this._hasChanged = false;

                await this._multiFactorAuthService
                    .EnableTwoFactorAuthentication(this._securityCodeEditor.liveValue)
                    .then((result) => this.success(result))
                    .catch((err) => this.error(err));
            }
        } else {
            this._step++;
        }
    }

    private success(result) {
        if (!result.value) {
            this._toasterService.showError("Two-factor authentication code is invalid. Please try again.", "Error");
        } else {
            this._toasterService.showSuccess(
                "You have successfully enabled two-factor authentication for your account. When you log in next time, you will be prompted for an additional authentication step.",
                50000
            );
            this._authService.logout();
            Router.go("/login");
        }
    }

    private async cancel(ev) {
        ev.preventDefault();
        this._authService.logout();
        Router.go("/login");
    }

    private error(err: Error) {
        alert(err.message);
        this._hasChanged = true;
    }

    render() {
        return html`
            <div class="header"></div>
            <form id="editorForm" class="editor">
                <p class="h3"><img src=${logo} class="header-logo" background /></p>
                ${this._step === 1
                    ? html`
                          <div class="scroll-container">
                              <h4>Secure your account with multi-factor authentication</h4>
                              <p>
                                  Your administrator has required you to set up multi-factor authentication (MFA). MFA makes it harder for
                                  potential intruders to steal your username and password and gain access to your data.
                              </p>
                              <p>
                                  Once you have registered for MFA, you will be prompted for additional authentication steps when logging
                                  into Sequentum Cloud.
                              </p>
                              <p>
                                  In order to enable multi-factor authentication (MFA), you need to use an authenticator app. The most
                                  common of these is 'Google Authenticator', but there are other compatible apps that may be available to
                                  you as well.
                              </p>
                              <p>
                                  Once you've successfully installed a compatible authenticator app from your app store, please click the
                                  'Next' button below to continue.
                              </p>
                          </div>
                      `
                    : html``}
                ${this._step === 2
                    ? html`
                          <div class="scroll-container">
                              <h4>Step 1: Configure the app</h4>
                              Install and launch Google Authenticator app on your phone, tap the '+' icon, then scan the following QR code.
                              <div class="text-center"><img src="${this._qrCodeSetupImageUrl}" /></div>
                              If you can not scan, you can manually enter the following
                              <div>Account: ${this._account}</div>
                              <div class="setupCode">Key: ${this._setupCode}</div>
                          </div>
                      `
                    : html``}
                ${this._step === 3
                    ? html`
                          <div class="scroll-container">
                              <p>Please enter the 6-digit security code your authenticator app generates</p>
                              <se-input-editor
                                  class="inputEditor"
                                  id="securityCode"
                                  name="securityCode"
                                  type="text"
                                  labelPosition="top"
                                  required
                                  size="7"
                              ></se-input-editor>
                              <p>
                                  Do not delete the Google Authenticator app on your phone. Otherwise, you won't be able to log into your
                                  account.
                              </p>
                          </div>
                      `
                    : html``}

                <div class="savePanel">
                    <se-primary-button .action="${() => this.next()}" action-delay="500" text="Next"></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
            <div class="footer">
                <div>
                    © 2022 Sequentum • <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a> •
                    <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a>
                </div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter} /></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin} /></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram} /></a>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .header {
            box-sizing: border-box;
            background-color: var(--color-primary);
            color: white;
            padding-left: 15px;
            padding-top: 15px;
            display: flex;
            gap: 15px;
            font: var(--font-h3);
        }
        .h3 {
            font: var(--font-h3);
            background-color: var(--color-primary);
            padding: 10px 10px 10px 10px;
        }
        h4 {
            font: var(--font-h4);
            padding: 10px 10px 10px 10px;
            margin: 0;
            text-align: center;
            font-weight: bold;
        }
        .header-logo {
            width: 175px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
            padding: 0px 30px 20px 30px;
            padding-top: 0px;
            border-radius: 5px 5px;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: hidden;
            padding: 10px 10px 20px 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            max-width: 500px;
        }
        .inputEditor {
            padding: 10px 10px 10px 10px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 10px;
        }
        .linkLabel {
            display: flex;
            flex: 2;
            font: var(--font-small);
            align-items: right;
            text-decoration: none;
            padding-top: 15px;
            flex-direction: row;
            align-items: center;
        }
        .footer {
            color: rgba(83, 86, 122, 1);
            font: var(--font-small);
            padding: 0px 20px 10px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .footer a {
            color: rgba(83, 86, 122, 1);
            text-decoration: none;
        }
        .footer a:hover {
            color: var(--color-secondary);
            text-decoration: none;
        }
        .setupCode {
            word-wrap: break-word;
        }
        .text-center {
            text-align: center !important;
        }
    `;
}
