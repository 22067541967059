export class OrganizationViewModel {
    constructor() {}

    id: number;
    name: string;
    isBillable: boolean;
    isApproved?: boolean;
    isMfaRequired: boolean;
    adminEmail?: number;
    userCount?: number;
    balance?: number;
    lastActivity?: Date;
    selected = false;
    created: Date;
    dailyEmailQuota?: number;
    dailyEmailCounter?: number;
}
