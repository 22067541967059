import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { Chart, registerables } from "chart.js";
import { endOfMonth, format, startOfDay, startOfMonth, startOfTomorrow, startOfYear, sub } from "date-fns";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { SortOrder } from "se-shared/enums/sort-order";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import search from "../../../../assets/search.svg";
import { AuthService } from "../../services/auth.service";
import { BillingService } from "../../services/billing.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { UsageService } from "../../services/usage.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { SePaginationElement } from "../components/pagination.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { SelectEditorElement } from "../editors/select-editor.element";

Chart.register(...registerables);

@customElement("se-usage")
export class SeUsageElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _usageService: UsageService;
    private _billingService: BillingService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];

    private _pageIndex = 1;
    private _recordsPerPage = 20;
    private _totalRecordCount: number;
    private _name = "";
    private _sortColumn = "ConfigName";
    private _sortOrder = -1;

    private _savedPageIndex = 1;
    private _agentName = "";
    private _isProgrammaticallySet = true;
    private lastSearchedValue = "";
    private searchQueue = [];
    private processingSearch = false;

    // UI constants
    private RUN_USAGE = "Server Time";
    private DATA_USAGE = "Data Transfer";
    private INPUT_COUNT = "Agent Inputs";
    private PROXY_USAGE = "Proxy Data";

    @state() private _usageDateRangeValue = "1";
    @state() private _usageTimeUnit = "day";
    @state() private _isCustomerExist = false;
    @state() private _orgBilling: any;

    @query("#chartCanvas") private _chartCanvas: HTMLCanvasElement;
    @query("#usageDateRange") private _usageDateRange: SelectEditorElement;

    @property({ type: Date }) startDate = startOfMonth(new Date());
    @property({ type: Date }) endDate = startOfTomorrow();
    @property({ type: Array }) datasets: any = [];
    @property({ type: Array }) labels: string[] = [];
    @property({ type: Number }) totalCost = 0.0;
    @property({ type: Number }) availableCredits = 0.0;

    private chart?: Chart;
    private boundHandlePopState;

    @query("se-pagination") private _pagination: SePaginationElement;
    @query("#search") private searchInput: InputEditorElement;

    @state() private _data: any[] = [];

    @query("se-data-grid") private _dataGrid: SeDataGrid;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._usageService = container.resolve(UsageService);
        this._billingService = container.resolve(BillingService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();


        this.getOrCreateCustomer();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.checkoutStatus) {
            const status = location.params.checkoutStatus.valueOf() as string;
            if (status === "success") {
                console.log("Checkout Payment successful..");
                this._toasterService.showSuccess("Payment successfull.");
            }

            if (status === "cancel") {
                console.log("Checkout Payment cancelled..");
                this._toasterService.showWarning("Payment canceled.");
            }
            history.replaceState(null, null, "/usage");
        }
    }

    async firstUpdated() {
        this._usageDateRangeValue = this.getCookie("usageDateRangeValue") || "1d";
        this.lastSearchedValue = this.getCookie("lastSearchedValue") || "";

        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        const pulledPage = Number(searchParams.get("gridPage") || this._pageIndex);
        if (this.lastSearchedValue != "") {
            this._agentName = this.lastSearchedValue;
            this._pagination.changePage(pulledPage || 1);
        }

        const customEvent = new CustomEvent("customPageChange", {
            detail: { pageIndex: pulledPage },
        });
        //false to just adjust the chart
        await this.updateDateRange(false);
        this.onPageChanged(customEvent);
        this.loadChart();
    }

    private async loadData(nameValue: string = this.lastSearchedValue) {
        const creditRequest = await this._billingService.api.getAvailableCreditsAsync(this._authService.user.organizationId);
        if (creditRequest.isOk) {
            this.availableCredits = Number(creditRequest.value);
        } else {
            this._toasterService.showUnexpectedError(creditRequest.err.message);
        }

        const orgId = this._authService.user.organizationId;
        this.lastSearchedValue = nameValue;
        this.setCookie("lastSearchedValue", this.lastSearchedValue, 15);

        const usageRequest = {
            name: this.lastSearchedValue,
            orgId,
            timeUnit: this._usageTimeUnit,
            startDate: format(this.startDate, "yyyy-MM-dd"),
            endDate: format(this.endDate, "yyyy-MM-dd"),
            pageIndex: this._pageIndex,
            recordsPerPage: this._recordsPerPage,
            sortColumn: this._sortColumn,
            sortOrder: this._sortOrder,
        };

        const result = await this._usageService.api.getOrgPipesUsageAsync(usageRequest);
        if (result.isOk) {
            this._data = result.value.pipesUsage;
            this._totalRecordCount = result.value.totalRecordCount;

            this.calculateDistribution();
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private getColumns() {
        if (this._columns == null || this._columns.length == 0) {
            //expand decimals places if ALL of the numbers are very small
            const decimalPlaces = this.totalCost >= 0.01 ? 2 : 3;

            this._columns = [
                {
                    field: "name",
                    title: "Agent Name",
                    sortable: true,
                    cellStyle: { textDecoration: "underline" },
                    action: this.viewUsageDetails.bind(this),
                },
                { field: "source", title: "Source" },
                //{ field: 'destination', title: 'Destination' },
                {
                    field: "cost",
                    title: "Cost",
                    sortable: true,
                    align: "right",
                    template: (row, col) =>
                        html`<span class="numbers" style="font-feature-settings: var(--font-feature-numbers);"
                            >${"$" + row.cost.toFixed(decimalPlaces)}</span
                        >`,
                },
                {
                    field: "distribution",
                    title: "Distribution",
                    align: "center",
                    template: (row, col) => html`${this.getDistribution(row.distribution)}`,
                },
            ];
        }

        return this._columns;
    }
    private getTransaction(color: string, type: string) {
        return html`<div style="color: ${color}">${type}</div>`;
    }
    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        this._sortColumn = evt.detail.sortColumn;
        this._sortOrder = evt.detail.sortOrder == SortOrder.Ascending ? 1 : 0;
        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    /**
     * Loop over all of the data records and calculate the distribution of each individual Pipe as a percentage of the overall total price
     */
    private calculateDistribution() {
        if (this._data == null || this.totalCost == null) {
            return;
        }

        this._data.forEach((item) => {
            // To calculate the distribution of each individual Pipe as a percentage of the overall total price
            item.distribution = (item.cost / this.totalCost) * 100;

            //item.cost = '$' + item.cost.toFixed(6); // Assuming you want to format the cost with two decimal places
        });

        this.requestUpdate();
    }

    async loadRunUsageData() {
        try {
            const orgId = this._authService.user.organizationId;
            const response = await this._usageService.api.getOrgRunsDataUsageAsync(
                orgId,
                this._usageTimeUnit,
                format(this.startDate, "yyyy-MM-dd"),
                format(this.endDate, "yyyy-MM-dd"),
                

            );

            if (response.isOk) {
                const data = await response.value;
                if (data.labels !== null && data.labels.length > 0) {
                    this.labels = data.labels;
                    this.datasets = data.dataSets;
                    this.totalCost = data.totalCost;
                } else {
                    this.labels = [];
                    this.datasets = data.dataSets;
                    this.totalCost = 0;
                }

                //force the grid to render again
                this._columns = null;
                this.calculateDistribution();
            } else {
                this._toasterService.showUnexpectedError(`Usage API request failed with status: ${response.value}`);
            }
        } catch (error) {
            console.error("Error fetching data from API:", error);
        }
    }

    private async loadChart() {
        if (this._chartCanvas.getAttribute("prepared") == "true") {
            return;
        }
        this._chartCanvas.setAttribute("prepared", "true");
        const ctx = this._chartCanvas.getContext("2d");

        // Chart data
        const data = {
            labels: this.labels,
            datasets: this.getDataSets(),
        };

        // Chart options
        const options = {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: "Cost",
                        color: "#1a1f4b", //#4e4ef0
                        font: {
                            family: "Helvetica",
                            size: 16,
                            weight: "bold",
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const datasetLabel = context.dataset.label; // Get the label name
                            const value = context.parsed.y.toFixed(2); // Format the data to 6 decimal places
                            return `${datasetLabel}: $${value}`; // Combine label name and "$" cost value in the tooltip label
                        },
                    },
                },
            },
        };

        // Create Chart.js instance
        this.chart = new Chart(ctx, {
            type: "bar",
            data: data,
            options: options,
        });
    }

    private getDataSets() {
        const datasets = [];

        this.datasets.forEach((item) => {
            if (item.label == "RunUsage") {
                const dataset = {
                    label: this.RUN_USAGE,
                    data: item.data,
                    backgroundColor: ["#3F8CD9"],
                    borderColor: ["#3F8CD9"],
                    borderWidth: 1,
                };
                datasets.push(dataset);
            }

            if (item.label == "DataUsage") {
                const dataset = {
                    label: this.DATA_USAGE,
                    data: item.data,
                    backgroundColor: ["#93bfea"],
                    borderColor: ["#93bfea"],
                    borderWidth: 1,
                };
                datasets.push(dataset);
            }

            if (item.label == "InputCount") {
                const dataset = {
                    label: this.INPUT_COUNT,
                    data: item.data,
                    backgroundColor: ["#4E4EF0"],
                    borderColor: ["#4E4EF0"],
                    borderWidth: 1,
                };
                datasets.push(dataset);
            }

            if (item.label == "ProxyUsage") {
                const dataset = {
                    label: this.PROXY_USAGE,
                    data: item.data,
                    backgroundColor: ["#FC833F"],
                    borderColor: ["#FC833F"],
                    borderWidth: 1,
                };
                datasets.push(dataset);
            }
        });

        return datasets;
    }

    private setCookie(name, value, minutes) {
        const now = new Date();
        now.setTime(now.getTime() + minutes * 60 * 1000);
        const expires = "expires=" + now.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }
    private getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    private async filterByAgentName() {
        const newValue = this.searchInput.liveValue;
        const trimmedNewValue = newValue.trim();
        if (
            (this.lastSearchedValue === "" || (this.lastSearchedValue === "null" && this._isProgrammaticallySet)) &&
            trimmedNewValue !== ""
        ) {
            this._savedPageIndex = this._pageIndex;
        }
        this._isProgrammaticallySet = false;

        if (this._agentName.trim() === "" && trimmedNewValue === "") {
            this.searchQueue = [];
            return;
        }
        this._agentName = newValue;
        if (trimmedNewValue === "") {
            this._pageIndex = this._savedPageIndex || 1;
            this._dataGrid.pageIndex = this._savedPageIndex || 1;
            this._pagination.changePage(this._savedPageIndex || 1);
        } else {
            this._pageIndex = 1;
            this._dataGrid.pageIndex = this._pageIndex;
        }

        if (trimmedNewValue === "") {
            this._agentName = "";
            this._isProgrammaticallySet = true;
        }
        if (this.lastSearchedValue === this._agentName) {
            return;
        }
        if (this.processingSearch == false) {
            this.searchQueue.push(this._agentName);
            this.processingSearch = true;
            await this.loadData(this.searchQueue[0]);
            this.processingSearch = false;
            this.searchQueue.shift();
        } else {
            if (this.searchQueue.length !== 1) {
                this.searchQueue.pop();
            }
            const repeating = setInterval(() => {
                if (this.processingSearch == false) {
                    clearInterval(repeating);
                    this.filterByAgentName();
                }
            }, 100);
        }
    }
    async getOrCreateCustomer() {
        const result = await this._billingService.api.getOrCreateCustomer();
        if (result.isOk) {
            this._orgBilling = result.value;
            this._isCustomerExist = true;
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }
    private handleDateChange() {
        this._usageDateRangeValue = this._usageDateRange.liveValue;
        this.setCookie("usageDateRangeValue", this._usageDateRange.liveValue, 15);
        this.updateDateRange();
    }

    private async filterList() {}
    private async updateDateRange(loadData = true) {
        //we may have to change the end date logic for Months filter once we have proper data.
        const today = new Date();
        switch (this._usageDateRangeValue) {
            case "1d":
                this.startDate = startOfMonth(today);
                this.endDate = startOfTomorrow();
                this._usageTimeUnit = "day";
                break;
            case "1m":
                this.startDate = startOfMonth(sub(today, { months: 1 }));
                this.endDate = endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = "day";
                break;
            case "3m":
                this.startDate = startOfMonth(sub(today, { months: 3 }));
                this.endDate = startOfTomorrow(); //endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = "day";
                break;
            case "6m":
                this.startDate = startOfMonth(sub(today, { months: 6 }));
                this.endDate = startOfTomorrow(); //endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = "day";
                break;
            case "1y":
                this.startDate = startOfYear(sub(today, { years: 1 }));
                this.endDate = startOfTomorrow();
                this._usageTimeUnit = "year";
                break;
            default:
                // Default to the current date range
                this.startDate = startOfDay(today);
                this.endDate = startOfTomorrow();
                break;
        }

        this._totalRecordCount = 0;
        this._data = [];
        this.totalCost = 0.0;
        this.loadRunUsageData();
        if (loadData) {
            await this.loadData();
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
    }
    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
    }
    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        this.loadData();
    }

    private getDistribution(value: number): TemplateResult {
        return html` <span style="display: flex; justify-content: flex-start;">
            <div class="distribution">
                <span style="height: 100%; width: ${value}%"></span>
            </div>
        </span>`;
    }
    private async viewUsageDetails(row: any, col: DataGridColumn) {
        Router.go("/agent-usage/" + row.configId);
    }

    updated(changedProperties: Map<string | number | symbol, unknown>): void {
        super.updated(changedProperties);
        if (changedProperties.has("labels")) {
            this.updateChart();
        }
    }

    private updateChart(): void {
        if (this.chart) {
            this.chart.data.labels = this.labels;
            this.chart.data.datasets = this.getDataSets();
            this.chart.update();
        }
    }

    private async handleCheckout() {
        try {
            const response = await this._billingService.api.getCheckoutSession({
                orgId: this._orgBilling.orgId,
                customerId: this._orgBilling.customerId,
            });
            if (response.isOk) {
                window.location.href = response.value; // Redirect to the session URL
            } else if (response?.err?.message != null) {
                this._toasterService.showError(response.err.message);
            } else {

                this._toasterService.showUnexpectedError("Could not create a checkout session");
            }
        } catch (error) {
            this._toasterService.showUnexpectedError("Could not create a checkout session");
        }
        this.requestUpdate();
    }

    render() {
        return html`
                <div class="title-header"><h1>Usage</h1> <se-primary-button
                        @click=${this.handleCheckout}
                        style="margin-left: auto;"
                        class="tabs inputEditor"
                        text="Buy Credits"
                    ></se-primary-button></div>
                <div class="container-content scroll-container">
                    <div class="table-header">
                        <div class="left-header">
                            <se-select-editor class="inputEditor" id="usageDateRange" .value="${this._usageDateRangeValue}" width="100%" name="usageDateRange" label="" labelPosition="top"
                                    @valueChanged=${this.handleDateChange}
                                    .options=${[
                                        { id: "1d", name: "This Month" },
                                        { id: "1m", name: "Last Month" },
                                        { id: "3m", name: "3 Months" },
                                        { id: "6m", name: "6 Months" },
                                        { id: "1y", name: "1 Year" },
                                    ]}>
                            </se-select-editor>
                        </div>
                        <div class="right-header">
                            <div>Credits Available: <span class="numbers">$${this.availableCredits.toFixed(2)}</span></div>
                            <div>Credits Used: <span class="numbers">$${this.totalCost.toFixed(2)}</span></div>
                        </div>
                    </div>
                    <div class="usage-list">
                        <div style="position: relative; height:30vh; flex:1;">
                            <canvas id="chartCanvas"></canvas>
                        </div>
                        <div class="labelsFilters">
                            <div class="market-item-div"><div class="usage-color" style="background-color: var(--color-purple)"></div><span>Agent Inputs</span></div> 
                            <div class="market-item-div"><div class="usage-color" style="background-color: var(--color-blue-50)"></div><span>Data Delivery</span></div>
                            <div class="market-item-div"><div class="usage-color" style="background-color: var(--color-status-orange)"></div><span>Proxy Data</span></div>
                            <div class="market-item-div"><div class="usage-color" style="background-color: var(--color-status-blue)"></div><span>Server Time</span></div> 
                        </div>
                    </div>
                </div>
                <div class="container-content" >
                        <div class="header">
                            <div class="left-header">
                                <se-input-editor style="margin-bottom: 5px;" class="inputEditor" .icon=${search} .customStyle=${{ alignItems: "unset" }} gap="5px" .value=${this._agentName === "null" && this._isProgrammaticallySet ? "" : this._agentName} @editorChanged=${this.filterByAgentName} id="search" label="Filter by Agent Name" name="search" type="text" placeholder="Search" labelPosition="left" input-type="search" required ></se-input-editor>
                            </div>
                        </div>
                        <div class="table-list" style="height: calc(100vh - 30vh - 290px);">
                            <se-data-grid .selectAllCheckbox=${true} class="grid" .rows=${this._data} .recordsPerPage=${this._recordsPerPage} .pageIndex=${this._pageIndex} .columns=${this.getColumns()} selectable @selectionchanged=${this.onGridSelectionChanged} @sortdata=${this.sortDataGrid} placeholder="No usage available."></se-data-grid>
                        </div>
                        <div style="overflow:hidden;display:flex;justify-content: center; margin-top: 5px;">
                            <se-pagination
                                .recordsPerPage=${this._recordsPerPage}
                                .recordCount=${this._totalRecordCount}
                                @pagechanged=${this.onPageChanged}
                            ></se-pagination>
                        </div>
                    </div>
                </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
            padding: 15px;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .title-header {
            padding: 15px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .container-header {
            justify-content: space-between;
        }
        .usage-item-label::before {
            content: "";
            position: absolute;
            top: -5px;
            left: -10px;
            height: 30px;
            width: calc(100% + 20px);
            border-radius: 15px;
            z-index: -1;
        }
        .transparent-bg {
            background-color: #ffffff00;
        }
        .labelsFilters {
            display: flex;
            gap: 8px;
            flex-direction: column;
            place-content: center flex-start;
            align-items: center;
            height: 100%;
        }
        .market-item-div {
            padding: 15px;
            width: 200px;
            text-align: center;
            border-radius: 9px;
            border: 1px solid var(--color-gray-2, #d0d3dc);
            display: flex;
            gap: 15px;
        }
        .usage-color {
            border-radius: 50%;
            height: 15px;
            width: 15px;
            align-self: center;
        }
        .usage-list {
            display: flex;
            gap: 20px;
        }
        .right-header {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .right-header .numbers {
            font-feature-settings: var(--font-feature-numbers);
            font-weight: bold;
        }
        .table-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-self: flex-end;
            padding: 25px 30px;
            box-sizing: border-box;
        }
        .scroll-container {
            min-height: 0;
            overflow: hidden;
            padding: 10px 10px 20px 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            margin-bottom: 5px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
        }
    `;
}
