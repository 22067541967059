import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import cronstrue from "cronstrue";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { SortOrder } from "se-shared/enums/sort-order";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ParallelExport } from "../../enums/parallel-export";
import { isRunningOnServer, isRunningOnServerOrStarting, RunStatus } from "../../enums/run-status";
import { ScheduleType } from "../../enums/schedule-type";
import { RunViewModel } from "../../models/run-view-model";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { RunService } from "../../services/run.service";
import { ServerService } from "../../services/server.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import "./run-details.element";
import { RunDetailsElement } from "./run-details.element";

@customElement("se-agent-tasks")
export class SeAgentTasksElement extends LitElement {
    @property() configId;
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _runService: RunService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _data: any[];
    private _configName: string;
    private _serverService: ServerService;
    private _runRefresh = true;

    @state() private _hasSelectedRows = false;
    @state() private _isLoading = true;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;
    @query("#runDetails") private _runDetails: RunDetailsElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._runService = container.resolve(RunService);
        this._menuService = container.resolve(MenuService);
        this._serverService = container.resolve(ServerService);
    }

    private menu(row: any, col: DataGridColumn): MenuItem[] {
        const isRunning = isRunningOnServer(row.status);
        const isRunningOrStarting = isRunningOnServerOrStarting(row.status);
        return [
            { text: "Edit", action: this.editTaskAsync.bind(this, row, col), hidden: isRunningOrStarting },
            { text: "Delete", action: this.deleteTaskAsync.bind(this, row, col), hidden: isRunningOrStarting },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this._runRefresh = true;
        this.loadDataAsync();
    }
    disconnectedCallback() {
        this._runRefresh = false;
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {}

    private async deleteTaskAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Task",
            body: `Are you sure you want to delete the task named ${row.name}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._runService.api.deleteTaskAsync(this.configId, row.id);
            if (result.isOk) {
                const index = this._data.indexOf(row);
                this._data.splice(index, 1);
                if (row.selected) this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async editTaskAsync(row: any, col: DataGridColumn) {
        Router.go(`space/${this._userState.selectedSpaceId}/config/${row.configId}/task/${row.id}`);
    }

    firstUpdated() {}

    private async updateDataAsync() {
        if (!this._runRefresh) {
            return;
        }
        if (!this._hasSelectedRows && !this._menuService.isMenuOpen()) {
            const result = await this._runService.api.getManyTasksAsync(this.configId);
            if (result.isOk) {
                this._data = result.value.agentTasks;
            }
        }
        const isQuickUpdate = this._data.some(
            (row) => row.status === RunStatus.queuing || row.status === RunStatus.starting || row.status === RunStatus.stopping
        );
        setTimeout(() => this.updateDataAsync(), isQuickUpdate ? 2000 : 10000);
    }

    private getRate(row) {
        const date = new Date(row.startTime);
        if (row.endTime) {
            const now = new Date(row.endTime);
            return (row.pageCount / ((now.getTime() - date.getTime()) / 1000)).toFixed(2);
        } else {
            const now = new Date();
            return (row.pageCount / ((now.getTime() - date.getTime()) / 1000)).toFixed(2);
        }
    }

    private getStats(row: any): TemplateResult {
        if (!row.actionCount && !row.dataCount && !row.errorCount) {
            if (isRunningOnServer(row.status)) {
                return html`<fa-icon single-color="silver" fa-class="far fa-spinner fa-spin"></fa-icon>`;
            } else {
                return html`n/a`;
            }
        } else {
            const rate = this.getRate(row);
            return html`<span
                ${htmlTitle(
                    html` <style>
                            .stats-table td:nth-child(2) {
                                text-align: right;
                            }
                            .stats-table td:nth-child(1) {
                                padding-right: 5px;
                            }
                        </style>
                        <table class="stats-table">
                            <tr>
                                <td>Actions:</td>
                                <td>${row.actionCount}</td>
                            </tr>
                            <tr>
                                <td>Data:</td>
                                <td>${row.dataCount}</td>
                            </tr>
                            <tr>
                                <td>Errors:</td>
                                <td>${row.errorCount}</td>
                            </tr>
                        </table>
                        <hr />
                        <table class="stats-table">
                            <tr>
                                <td>Total Pages:</td>
                                <td>${row.pageCount}</td>
                            </tr>
                            <tr>
                                <td>Dynamic pages:</td>
                                <td>${row.dynamicPageCount}</td>
                            </tr>
                            <tr>
                                <td>Rate (pages/sec):</td>
                                <td>${rate}</td>
                            </tr>
                            <tr>
                                <td>Requests:</td>
                                <td>${row.requestCount}</td>
                            </tr>
                        </table>`,
                    true
                )}
                style="border-bottom: 1px dotted;cursor:pointer;user-select: none;"
                >a:${row.actionCount}&nbsp;d:${row.dataCount}&nbsp;e:${row.errorCount}</span
            >`;
        }
    }

    private getStatus(color: string, text: string, row: any): TemplateResult {
        if (row.message && !isRunningOnServer(row.status)) {
            return html`<span
                ${htmlTitle(row.message, true)}
                style="border-bottom: 1px dotted;color:${color};cursor:pointer;user-select: none;"
                >${text}</span
            >`;
        } else {
            return html`<span style="color:${color};">${text}</span>`;
        }
    }

    public static getStat(color: string, text: string): string {
        return `<span style='color:${color};'>${text}</span>`;
    }

    private getParallelism(row: RunViewModel): TemplateResult {
        return html`<span
            ${htmlTitle(
                html`<table>
                    <tr>
                        <td>Total runs:</td>
                        <td>${row.parallelism}</td>
                    </tr>
                    <tr>
                        <td>Concurrency:</td>
                        <td>${row.parallelMaxConcurrency ?? "Unlimited"}</td>
                    </tr>
                    <tr>
                        <td>Export:</td>
                        <td>${row.parallelExport ?? ParallelExport.Combined}</td>
                    </tr>
                </table>`,
                true
            )}
            style="border-bottom: 1px dotted;cursor:pointer;user-select: none;"
            >${row.parallelism}</span
        >`;
    }

    private async loadDataAsync() {
        this._columns = [
            { field: "name", title: "Name", align: "left" },
            {
                field: "parallelism",
                title: "Parallelism",
                align: "center",
                hidden: true,
                template: (row) =>
                    row.parallelSet
                        ? html`${row.parallelSet}/${row.parallelism}`
                        : (row.parallelism ?? 1) > 1
                          ? this.getParallelism(row)
                          : html``,
            },
            {
                field: "schedule",
                title: "Schedule",
                template: (row, col) => {
                    return html`${row.scheduleType === ScheduleType.runOnce
                        ? "Run Once"
                        : row.localSchedule
                          ? cronstrue.toString(row.localSchedule)
                          : ""}`;
                },
            },
            {
                field: "nextRunTime",
                title: "Next Run",
                template: (row, col) => {
                    const date = row.nextRunTime ? new Date(row.nextRunTime) : row.startTime ? new Date(row.startTime) : undefined;
                    return date ? html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : html``;
                },
            },

            {
                field: "status",
                title: "Error Message",
                template: (row, col) =>
                    html`${choose(
                        row.status,
                        [
                            [undefined || null, () => html``],
                            [RunStatus.skipped, () => html`One or more runs were skipped because a run was already in progress`],
                        ],
                        () => html``
                    )}`,
            },
            { field: "startedBy", title: "Created By", align: "center", sortable: true },
            {
                field: "created",
                title: "Date Created",
                align: "center",
                sortable: true,
                template: (row, col) => {
                    const date = new Date(row.created);
                    return html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];

        this._isLoading = true;
        try {
            const result = await this._runService.api.getManyTasksAsync(this.configId);
            if (result.isOk) {
                this._data = result.value.agentTasks;
                this._configName = result.value.configName;
                this._columns.find((p) => p.field === "parallelism").hidden = !this._data.some((p) => p.parallelism && p.parallelism > 1);

                const isQuickUpdate = this._data.some(
                    (row) => row.status === RunStatus.queuing || row.status === RunStatus.starting || row.status === RunStatus.stopping
                );
                setTimeout(() => this.updateDataAsync(), isQuickUpdate ? 2000 : 10000);
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
        if (this._selectAll) this._selectAll.value = false;
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder == SortOrder.Ascending ? 1 : -1;
        this._data.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -sortOrder : a[sortColumn] > b[sortColumn] ? sortOrder : 0));
    }

    private refresh() {
        this.loadDataAsync();
    }

    private async onDeleteManyAsync(event: MouseEvent) {
        event.stopPropagation();

        if (this._dataGrid.selectedRows.some((p) => !RunViewModel.canDelete(p.status))) {
            this._toasterService.showError("You cannot delete active agentTasks. Please stop the agentTasks first.");
            return;
        }

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Agent Tasks",
            body: `Are you sure you want to delete ${count === 1 ? `1 task` : `${count} tasks`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const ids = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._runService.api.deleteTaskManyAsync(this.configId, ids);
            if (result.isOk) {
                this._selectAll.value = false;
                this._hasSelectedRows = false;
                this._data = this._data.filter((p) => !p.selected);
                this.requestUpdate();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        const canStop = this._hasSelectedRows && this._dataGrid.selectedRows.some((p) => RunViewModel.canStop(p.status));
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${(this._data?.length ?? 0) === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteManyAsync(event)}
                                      ${htmlTitle("Delete selected tasks")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No tasks available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .right-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
