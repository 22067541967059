import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { live } from "lit/directives/live.js";
import "element-internals-polyfill";
import { BaseEditor } from "./base-editor";
import { styleMap } from "lit/directives/style-map.js";

@customElement("se-textarea-editor")
export class TextAreaEditorElement extends LitElement implements BaseEditor {
    @property() value: string;
    get liveValue() {
        return this._inputElement?.value;
    }

    @property() label?: string;
    @property() labelPosition?: "left" | "top" | "right" | "bottom" = "top";

    @property() width?: string;
    @property({ type: Number }) minlength?: number;
    @property({ type: Number }) maxlength?: number;
    @property({ type: Number }) rows?: number;
    @property({ type: Number }) cols?: number;
    @property() pattern?: string;
    @property() placeholder?: string;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property() name: string;
    @property({ attribute: "input-resize" }) inputResize?: "vertical" | "horizontal" | "both" | "none" = "both";
    @property({ type: Boolean, attribute: "use-auto-complete" }) useAutoComplete = false;

    @query("#input") private _inputElement: HTMLTextAreaElement;

    constructor() {
        super();
    }
    valueChanged() {
        this.dispatchEvent(new CustomEvent("valueChanged", { bubbles: true, composed: true, detail: { editor: this } }));
    }
    updateValue(event) {
        if (this._inputElement.checkValidity()) this._inputElement.classList.remove("invalid");
        else this._inputElement.classList.add("invalid");
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }));
    }
    reportValidity(): boolean {
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        } else {
            this._inputElement.classList.add("invalid");
            return false;
        }
    }

    firstUpdated() {}

    cancel() {
        this._inputElement.classList.remove("invalid");
        this.requestUpdate();
    }

    render() {
        const flexDirection =
            (this.labelPosition === "left" && "row-reverse") ||
            (this.labelPosition === "top" && "column-reverse") ||
            (this.labelPosition === "right" && "column") ||
            (this.labelPosition === "bottom" && "row");
        const style = { display: "flex", flexDirection: flexDirection };
        return html`
            <div style="${styleMap(style)}">
                <textarea
                    style=${styleMap({ width: this.width, resize: this.inputResize })}
                    id="input"
                    name="${this.name}"
                    .value="${live(this.value)}"
                    @input="${this.updateValue}"
                    @change="${this.valueChanged}"
                    rows="${ifDefined(this.rows)}"
                    cols="${ifDefined(this.cols)}"
                    maxlength="${ifDefined(this.maxlength)}"
                    minlength="${ifDefined(this.minlength)}"
                    placeholder="${ifDefined(this.placeholder)}"
                    ?required="${this.required}"
                    ?disabled="${this.disabled}"
                    ?readonly="${this.readonly}"
                    autocomplete=${this.useAutoComplete ? "on" : "off"}
                ></textarea>
                <label for="input">${this.label}</label>
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: block;
        }
        textarea {
            font: var(--font-input);
        }
        label {
            font: var(--font-input-label);
        }
        textarea:disabled + label {
            color: gray;
        }
        textarea.invalid {
            outline: 2px solid pink;
        }
    `;
}
