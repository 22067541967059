
import { html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";




@customElement("se-subscriptions")
export class SeSubscriptionsElement extends LitElement {

    @state()
    private scriptLoaded = false;

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        script.onload = () => {
            this.scriptLoaded = true;
        };
        document.head.appendChild(script);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }



    firstUpdated() {}

    

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();

    }

    render() {
        if (!this.scriptLoaded) {
            return html`<p>Loading...</p>`;
        }

        return html`
        <div>
            <stripe-pricing-table pricing-table-id="prctbl_1Q1BYcGs49FA0uLcW4J5Zonf"
            publishable-key="pk_test_51OHpunGs49FA0uLcm1rOABiCIDu7Ai3w3jWn9JGTaRgvMqHFjprkjJCfoI7K7mBIEyhfhmxebPyQ9FawIk8W1zf700ZYcG9GEj">
            </stripe-pricing-table>
            </div>
        `;
    }

    
}
