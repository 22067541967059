import { container, inject,singleton } from 'tsyringe';
import { DraftsSummaryViewModel } from '../models/drafts-summary-view-model';
import { LoginToken } from '../models/login';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';

@singleton()
export class DraftApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();

        this._authService = container.resolve(AuthService);
    }
    
    async getAllAsync(pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<DraftsSummaryViewModel>(`/api/draft/user?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, this._authService.token);
    }

    async deleteAsync(draftId: number) {
        return this.deleteObjectAsync(`/api/draft/${draftId}`, this._authService.token);
    }
    async deleteAllAsync(draftIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/draft/all`, {ids: draftIds}, this._authService.token);
    }
    async publishAsync(draftId: number, allowOverwrite) {
        return this.postObjectAsync(`/api/draft/${draftId}/publish`, { allowOverwrite: allowOverwrite}, this._authService.token);
    }
    async publishToSpaceAsync(draftId: number, newSpaceId: number, name: string, allowOverwrite: boolean) {
        return this.postObjectAsync(`/api/draft/${draftId}/publish/space/${newSpaceId}`, { name: name, allowOverwrite: allowOverwrite}, this._authService.token);
    }
}