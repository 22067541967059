import { LitElement } from "lit";

export function dispatchCustomEvent(component: LitElement, name: string, data?: any) {
    component.dispatchEvent(new CustomEvent(name, { bubbles: true, composed: true, detail: data }));
}

export function getPlural(count: number, str: string): string {
    if (count === 1) return `${count} ${str}`;
    else return `${count} ${str}s`;
}
export function isEqual(str1: string, str2: string): boolean {
    if (!str1 || !str2) return false;

    return str1.toLowerCase() === str2.toLowerCase();
}
export function splitValAndUnit(unsplitData: string): { val: number; unit: string } {
    const split = unsplitData.match(/[\d.]+|\D+/g);
    if (split.length === 2) {
        const val = parseFloat(split[0]);
        const unit = split[1].trim();
        if (!isNaN(val)) {
            return { val, unit };
        }
    }
    return undefined;
}
export function secondsToNamedDuration(seconds: number) {
    if (seconds >= 60 && (seconds % 60 === 0 || seconds > 1000)) {
        let uvalue = seconds / 60;
        if (uvalue >= 60 && (uvalue % 60 === 0 || uvalue > 1000)) {
            uvalue = uvalue / 60;
            if (uvalue >= 24 && (uvalue % 24 === 0 || uvalue > 1000)) {
                uvalue = uvalue / 24;
                return getPlural(parseFloat(uvalue.toFixed(2)), "day");
            }
            return getPlural(parseFloat(uvalue.toFixed(2)), "hour");
        }
        return getPlural(parseFloat(uvalue.toFixed(2)), "minute");
    }
    return getPlural(seconds, "second");
}
export function namedDurationToSeconds(duration: string) {
    const val = splitValAndUnit(duration);
    if (!val) return undefined;

    if (
        isEqual(val.unit, "s") ||
        isEqual(val.unit, "sec") ||
        isEqual(val.unit, "secs") ||
        isEqual(val.unit, "seconds") ||
        isEqual(val.unit, "second")
    ) {
        return val.val;
    }
    if (
        isEqual(val.unit, "m") ||
        isEqual(val.unit, "min") ||
        isEqual(val.unit, "mins") ||
        isEqual(val.unit, "minutes") ||
        isEqual(val.unit, "minute")
    ) {
        return val.val * 60;
    }
    if (isEqual(val.unit, "h") || isEqual(val.unit, "hours") || isEqual(val.unit, "hour")) {
        return val.val * 60 * 60;
    }
    if (isEqual(val.unit, "d") || isEqual(val.unit, "days") || isEqual(val.unit, "day")) {
        return val.val * 60 * 60 * 24;
    }
    return null;
}
