import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { classMap } from "lit/directives/class-map.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AccessLevel } from "../../enums/access-level";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { OrganizationApi } from "../../services/organization.api";
import { UserService } from "../../services/user.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { AppSettings } from "../../models/app-settings";
import "../components/side-nav.element"

@customElement("se-organization-settings")
export class SeOrganizationUsersElement extends LitElement {
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _settings: AppSettings;
    private _userService: UserService;
    private _organizationService: OrganizationApi;
    //private _labelService: LabelService;
    //private _spaceService: SpaceService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _currentSlot: string = 'users';

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);

        this._settings = container.resolve(AppSettings);
        //       this._labelService = container.resolve(LabelService);
        //       this._spaceService = container.resolve(SpaceService);
        this._organizationService = container.resolve(OrganizationApi);
        this._userService = container.resolve(UserService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }

        // Extract section from URL if present
        const pathParts = location.pathname.split('/');
        const section = pathParts[pathParts.length - 1];
        if (section && section !== 'org') {
            this._currentSlot = section;
        }
    }

    private _handleNavChange(e: CustomEvent) {
        this._currentSlot = e.detail.slotName;
    }

    render() {
        return html`
            <div class="body">
                <div class="top-bar">
                    <div class="breadcrumbs">
                        <a>Manage</a>
                        <span>/</span>
                        <a>${this._authService.user.organizationName}</a>
                    </div>
                </div>
                <se-nav baseUrl="/manage/org" @nav-changed=${this._handleNavChange}>
                    <div slot="users" label="Users">
                        ${this._currentSlot === 'users' ? html`<se-organization-users></se-organization-users>` : html``}
                    </div>
                    <div slot="proxies" label="Proxy Pools">
                        ${this._currentSlot === 'proxies' ? html`<se-proxies></se-proxies>` : html``}
                    </div>
                    <div slot="destinations" label="Destinations">
                        ${this._currentSlot === 'destinations' ? html`<se-destinations></se-destinations>` : html``}
                    </div>
                    <div slot="transactions" label="Transactions">
                        ${this._currentSlot === 'transactions' ? html`<se-transactions></se-transactions>` : html``}
                    </div>
                    ${(this._authService.isOrgAdmin) ? html`
                        <div slot="account-logs" label="Audit Logs">
                            ${this._currentSlot === 'account-logs' ? html`<se-account-audit-log></se-account-audit-log>` : html``}
                        </div>
                        <div slot="agent-logs" label="Audit Agents">
                            ${this._currentSlot === 'agent-logs' ? html`<se-config-audit-log></se-config-audit-log>` : html``}
                        </div>
                        <div slot="run-logs" label="Audit Runs">
                            ${this._currentSlot === 'run-logs' ? html`<se-run-audit-log></se-run-audit-log>` : html``}
                        </div>
                        <div slot="login-logs" label="Audit Logins">
                            ${this._currentSlot === 'login-logs' ? html`<se-login-audit-log></se-login-audit-log>` : html``}
                        </div>
                    ` : html``}
                </se-nav>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100vh;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        .top-bar {
            background-color: var(--color-blue-25);
            padding: 15px;
            border-bottom: solid 1px var(--color-navy-3);
            width: 100%;
        }
        .breadcrumbs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            font: var(--font-smaller);
        }
        .breadcrumbs a{
            color: var(--color-primary);
            text-decoration: none;
        }
        .breadcrumbs a:hover{
            text-decoration: underline;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .header {
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .tabs-header{
            display:flex;
            gap: 10px;
            background-color: var(--color-light);
            border-bottom: solid 1px var(--color-gray-2);
            margin-bottom: 10px;
        }
        .active-tab{
            color: var(--color-purple);
        }
        .active-tab::before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .tabs{
            cursor:pointer;
            padding: 10px;
            position: relative;
        }
        .tabs:hover{
            background-color: rgb(49 54 93 / 9%);
            border-radius: 8px 8px 0 0;
        }
        .container-content{
            overflow-y: auto;
            overflow-x: hidden;
        }
    `;
}
