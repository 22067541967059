import { container, singleton } from "tsyringe";
import { AuditLogApi } from "./audit-log.api";

@singleton()
export class AuditLogService {
    private _auditLogApi: AuditLogApi;

    get api() {
        return this._auditLogApi;
    }

    constructor() {
        this._auditLogApi = container.resolve(AuditLogApi);
    }
}
