import { inject, singleton } from "tsyringe";
import { integer } from "vscode-languageserver-protocol";
import { LoginToken } from "../models/login";
import { User } from "../models/user";
import { BaseApi } from "./base.api";
//import { AuthService } from './auth.service';

@singleton()
export class AuthApi extends BaseApi {
    //private _authService: AuthService;
    constructor() {
        super();
        //this._authService = container.resolve(AuthService);
    }

    async loginAsync(email: string, password: string, isKeepMeLoggedIn?: boolean) {
        return this.postObjectReturnObjectAsync<LoginToken>(`/api/auth/login`, {
            email: email,
            password: password,
            isKeepMeLoggedIn: isKeepMeLoggedIn,
        });
    }

    async loginWithGoogleIdToken(idToken: string) {
        return this.postObjectReturnObjectAsync<LoginToken>(`/api/auth/login/google/token`, { idToken: idToken });
    }

    async RecoverPassword(email: string) {
        return this.postObjectAsync(`/api/auth/recoverpassword`, { email: email });
        //return this.postObjectAsync(`/api/auth/recoverpassword`, { email: email, emailTemplate: emailTemplate });
    }

    async ValidateToken(userId: string, token: string) {
        //return this.postObjectAsync(`/api/auth/recoverpassword`, { email: email });
        return this.postObjectReturnObjectAsync<User>(`/api/auth/validatetoken`, { userId: userId, token: token });
    }

    async getOrgSettings(authToken: string) {
        return this.getObjectAsync<{ isAuditLog: boolean; isProxyRequired: boolean }>(`/api/auth/org-settings`, authToken);
    }

    /*
    async ResetPassword(userAccountId: integer, email: string, password: string) {
        return this.postObjectAsync(`/api/user/${userAccountId}/reset-password`, { email: email, password: password }, this._authService.token);
        
    }
*/
}
