import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { SortOrder } from "se-shared/enums/sort-order";
import { AuthService } from "../../services/auth.service";
import { LabelService } from "../../services/label.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { SeDataGrid } from "../components/data-grid.element";
import { DataGridColumn } from "../components/data-grid-template";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";

@customElement("se-labels")
export class SeLabelsElement extends LitElement {
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _labelService: LabelService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _sortColumn: string;
    private _sortOrder = -1;
    @state() private _data: any[] = [];

    @state() private _hasSelectedRows = false;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);
        this._labelService = container.resolve(LabelService);
    }

    private menu(row: any, col: DataGridColumn): MenuItem[] {
        return [
            { text: "Edit", action: this.editLabel.bind(this, row, col) },
            { text: "-" },
            { text: "Delete", action: this.deleteLabelAsync.bind(this, row, col) },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }
    }

    private editLabel(row: any, col: DataGridColumn) {
        Router.go(`/edit/label/${row.id}/space/${this._userState.selectedSpaceId}`);
    }

    private async deleteLabelAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Label",
            body: `Are you sure you want to delete ${row.name}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._labelService.api.deleteAsync(row.id);
            if (result.isOk) {
                await this._userState.removeUserLabelsAsync([row.id]);
                await this._userState.refreshUserLabelsAsync(
                    this._pageIndex,
                    this._recordsPerPage,
                    this._sortColumn,
                    this._sortOrder,
                    true
                );
                this.loadData();
                this._userState.userLabelsChanged.triggerVoid();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    firstUpdated() {}

    private async loadData() {
        const space = await this._userState.getSelectedSpace(this._pageIndex);
        if (space.isErr) {
            this._toasterService.showNetworkError(space.err);
        }
        const spaceName = space.value.name;
        this._columns = [
            {
                field: "name",
                title: "Label Name",
                cellStyle: { textDecoration: "underline" },
                sortable: true,
                action: this.editLabel.bind(this),
            },
            {
                field: "isAdminLabel",
                title: "Space",
                template: (row, col) => html`${row.isAdminLabel ? "All" : spaceName}`,
            },
            { field: "labelFilter", title: "Filter", template: (row, col) => html`${row.labelFilter === 1 ? "Configs" : "None"}` },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];
        const result = await this._userState.getUserLabelsAsync(this._pageIndex, this._recordsPerPage);
        if (result.isOk) {
            this._data = result.value;
            this._totalRecordCount = this._userState.totalLabelCount;
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private gridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private async sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        await this._userState.refreshUserLabelsAsync(this._pageIndex, this._recordsPerPage, this._sortColumn, this._sortOrder, true);
        this.loadData();
        this._userState.userLabelsChanged.triggerVoid();
    }

    private async onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;

        await this._userState.refreshUserLabelsAsync(this._pageIndex, this._recordsPerPage, this._sortColumn, this._sortOrder, true);
        this.loadData();
        this._userState.userLabelsChanged.triggerVoid();
    }

    private refresh() {
        this.loadData();
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Labels",
            body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].name}` : `${count} labels`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const labelIds = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._labelService.api.deleteAllAsync(labelIds);
            if (result.isOk) {
                await this._userState.removeUserLabelsAsync(labelIds);
                await this._userState.refreshUserLabelsAsync(
                    this._pageIndex,
                    this._recordsPerPage,
                    this._sortColumn,
                    this._sortOrder,
                    true
                );
                this.loadData();
                this._userState.userLabelsChanged.triggerVoid();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    newLabel() {
        Router.go(`/edit/label/space/${this._userState.selectedSpaceId}`);
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${this._data.length === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete Selected")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    </div>
                    <se-primary-button text="New Label" @click="${this.newLabel}"></se-primary-button>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.gridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No labels available."
                ></se-data-grid>
                <div style="overflow:hidden;display:flex;justify-content: center;">
                    <se-pagination .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
            padding: 15px;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
