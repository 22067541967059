import "element-internals-polyfill";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { styleMap } from "lit/directives/style-map.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { BaseEditor } from "./base-editor";

@customElement("se-typeahead-editor")
export class TypeaheadEditorElement extends LitElement implements BaseEditor {
    @property() value: string;

    get liveValue() {
        return this._inputElement?.value;
    }
    set liveValue(liveValue: string) {
        if (this._inputElement) this._inputElement.value = liveValue;
    }

    @property({ attribute: false, type: Array }) textOptions?: string[];
    @property({ attribute: false }) getTextOptions?: () => Promise<string[]>;

    @property() label?: string;
    @property() labelPosition?: "left" | "top" | "right" | "bottom" = "top";

    @property({ type: Number }) step?: number;
    @property({ type: Number }) size?: number;
    @property({ type: Number }) minlength?: number;
    @property({ type: Number }) maxlength?: number;
    @property() width?: string;
    @property({ attribute: "input-width" }) inputWidth?: string;
    @property({ attribute: "min-suggestion-width" }) minSuggestionWidth?: string;
    @property() min?: string;
    @property() max?: string;
    @property() pattern?: string;
    @property() placeholder?: string;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property({ type: Boolean }) refresh = false;
    @property({ attribute: "show-all", type: Boolean }) showAll = false;
    @property({ attribute: "auto-open", type: Boolean }) autoOpen = false;
    @property() name: string;
    @property({ attribute: "editor-size" }) editorSize: "small" | "nomal";
    @property() gap;

    @state() private _opened = false;

    private _highlightedElement: HTMLLIElement;
    private _matches: string[] = [];

    @query("#defaultInput") private _inputElement: HTMLInputElement;
    @query("#suggestions") private _suggestionElement: HTMLUListElement;

    constructor() {
        super();
    }

    disable(isDisable = true) {
        this.disabled = isDisable;
    }

    refreshData() {
        this.textOptions = undefined;
    }

    valueChanged() {
        this.dispatchEvent(
            new CustomEvent("valueChanged", {
                bubbles: true,
                composed: true,
                detail: { editor: this, value: this.liveValue, oldValue: this.value },
            })
        );
    }

    async updateValue() {
        if (this.textOptions ?.length) {
            if (!this._inputElement.value) {
                if (!this.showAll) this.close();
            } else {
                if (!this._opened) {
                    this.open();
                } else {
                    this._matches = this.showAll ? this.textOptions : this.getSuggestions();
                    this._suggestionElement.style.top = "0px";
                    this._suggestionElement.style.left = "0px";
                    this.requestUpdate();
                    await this.updateComplete;
                    this.onResize();
                }
            }
        }
        this._setHighlightedElement();

        if (this._inputElement.checkValidity()) this._inputElement.classList.remove("invalid");
        else this._inputElement.classList.add("invalid");

        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }));
    }
    reportValidity(): boolean {
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        } else {
            this._inputElement.classList.add("invalid");
            return false;
        }
    }

    setCustomValidity(error: string) {
        this._inputElement.setCustomValidity(error);
        this.reportValidity();
    }

    clearCustomValidity() {
        this._inputElement.setCustomValidity("");
        this.reportValidity();
    }

    cancel() {
        this._inputElement.classList.remove("invalid");
        this._inputElement.value = this.value;
    }

    _onKeyDown(ev) {
        if (ev.key === "ArrowUp" || ev.key === "ArrowDown") {
            ev.preventDefault();
            ev.stopPropagation();
            if (ev.key === "ArrowUp") {
                this._markPreviousElement();
            } else if (ev.key === "ArrowDown") {
                this._markNextElement();
            }
        } else if (ev.key === "Tab" || (this.autoOpen && ev.key === "Enter")) {
            if (this._opened) {
                ev.preventDefault();
                ev.stopPropagation();
                this._highlightedElement && this._highlightedElement.dispatchEvent(new MouseEvent("mousedown"));
            }
        }
    }

    async _onKeyUp(ev) {
        switch (ev.key) {
            case "ArrowUp":
                ev.preventDefault();
                ev.stopPropagation();
                break;

            case "ArrowDown":
                ev.preventDefault();
                ev.stopPropagation();
                break;

            case "Enter":
                this._highlightedElement && this._highlightedElement.dispatchEvent(new MouseEvent("mousedown"));
                this.blur();
                break;
            case "Escape":
                this.close();
                this.blur();
                break;
        }
    }

    _markPreviousElement() {
        if (!this._highlightedElement || !this._highlightedElement.previousElementSibling) {
            if (
                !this._highlightedElement &&
                this._suggestionElement.lastElementChild &&
                this._suggestionElement.lastElementChild instanceof HTMLLIElement
            ) {
                this._highlightedElement = this._suggestionElement.lastElementChild;
                this._highlightedElement.classList.add("active");
                this._highlightedElement.scrollIntoView();
            }
            return;
        }

        this._highlightedElement.classList.remove("active");
        if (this._highlightedElement.previousElementSibling instanceof HTMLLIElement) {
            this._highlightedElement = this._highlightedElement.previousElementSibling;
            this._highlightedElement.classList.add("active");
            this._highlightedElement.scrollIntoView();
        }
    }

    _markNextElement() {
        if (!this._highlightedElement || !this._highlightedElement.nextElementSibling) {
            if (
                !this._highlightedElement &&
                this._suggestionElement.firstElementChild &&
                this._suggestionElement.firstElementChild instanceof HTMLLIElement
            ) {
                this._highlightedElement = this._suggestionElement.firstElementChild;
                this._highlightedElement.classList.add("active");
                this._highlightedElement.scrollIntoView();
            }
            return;
        }

        this._highlightedElement.classList.remove("active");
        if (this._highlightedElement.nextElementSibling instanceof HTMLLIElement) {
            this._highlightedElement = this._highlightedElement.nextElementSibling;
            this._highlightedElement.classList.add("active");
            this._highlightedElement.scrollIntoView();
        }
    }

    private async _onFocusAsync() {
        if (!this._opened) {
            if (!this.textOptions) {
                this.textOptions = await this.getTextOptions();
            }
            if (this.textOptions?.length) {
                this.open();
                //this.suggest(this.getSuggestions(), true);
                //this.suggest(this.textOptions, true);
            }
        }
    }

    _onBlur() {
        this.close();

        this.dispatchEvent(new CustomEvent("editorBlur", { bubbles: true, composed: true, detail: { editor: this } }));
    }

    _handleItemMouseEnter() {
        //Do nothing
    }

    _handleItemMouseLeave() {
        //Do nothing
    }

    private _setHighlightedElement() {
        if (this._highlightedElement) {
            this._highlightedElement.classList.remove("active");
            this._highlightedElement = null;
        }
        if (this.showAll) {
            const value = this.liveValue;
            if (value) {
                for (const item of Array.from(this._suggestionElement.children)) {
                    if (item instanceof HTMLLIElement) {
                        //check for exact match first
                        if (item.textContent.toLowerCase() === value.toLowerCase()) {
                            this._highlightedElement = item;
                            break;
                        }

                        if (!this._highlightedElement) {
                            if (item.textContent.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                                this._highlightedElement = item;
                            }
                        }
                    }
                }
            }

            if (this._highlightedElement) {
                this._highlightedElement.classList.add("active");
                this._highlightedElement.scrollIntoView();
            }
        } else {
            if (this._suggestionElement.children[0] instanceof HTMLLIElement) {
                this._highlightedElement = this._suggestionElement.children[0];
                if (this._highlightedElement) this._highlightedElement.classList.add("active");
            }
        }
    }

    async open() {
        if (this.textOptions?.length) {
            this._matches = this.showAll ? this.textOptions : this.getSuggestions();
            this._suggestionElement.style.top = "0px";
            this._suggestionElement.style.left = "0px";
            this._opened = true;
            await this.updateComplete;
            this.onResize();

            for (const item of Array.from(this._suggestionElement.children)) {
                item.classList.remove("active");
            }
            this._setHighlightedElement();
        }
    }
    async close() {
        this._opened = false;
        await this.updateComplete;
        if (this._highlightedElement) this._highlightedElement.classList.remove("active");
        this._highlightedElement = null;
    }

    autocomplete(value) {
        this._inputElement.value = value;
        this.updateValue();
        this.valueChanged();
        this.close();
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener("resize", this._handleResize);
    }
    disconnectedCallback() {
        window.removeEventListener("resize", this._handleResize);
        super.disconnectedCallback();
    }

    private _handleResize = () => this.onResize();

    private onResize() {
        const contentRec = this._inputElement.getBoundingClientRect();

        if (this.minSuggestionWidth) this._suggestionElement.style.minWidth = this.minSuggestionWidth;
        this._suggestionElement.style.width = contentRec.width - 2 + "px"; //This can make the suggestion box higher because of text wrapping, so must come before next line.
        const seggestRec = this._suggestionElement.getBoundingClientRect();

        this._suggestionElement.style.left = contentRec.left + "px";

        if (contentRec.bottom + seggestRec.height < window.innerHeight) {
            this._suggestionElement.style.top = contentRec.bottom + "px";
            this._suggestionElement.classList.remove("position-top");
            this._suggestionElement.classList.add("position-bottom");
        } else {
            this._suggestionElement.style.top = contentRec.top - seggestRec.height + "px";
            this._suggestionElement.classList.remove("position-bottom");
            this._suggestionElement.classList.add("position-top");
        }
    }

    highlightChars(item) {
        const value = this.liveValue;
        if (value) {
            const index = value.length > 0 ? item.toLowerCase().indexOf(value.toLowerCase()) : -1;
            if (index > -1) {
                return html`${item.substring(0, index)}<b>${item.substring(index, index + value.length)}</b>${item.substring(
                    index + value.length
                )}`;
            }
        }
        return item;
    }

    firstUpdated() {
        if (this.autoOpen) {
            this._inputElement.focus();
            this._onFocusAsync();
        }
    }

    getSuggestions() {
        const value = this.liveValue;

        if (this.textOptions && value) {
            const suggestions = this.textOptions.filter(
                (item) =>
                    item
                        .replace(",", "")
                        .replace(/\s/g, "")
                        .toLowerCase()
                        .search(value.replace(",", "").replace(/\s/g, "").toLowerCase()) !== -1
            );
            return suggestions;
        } else {
            return [];
        }
    }

    private getBodyHtml() {
        const flexDirection =
            (this.labelPosition === "left" && "row-reverse") ||
            (this.labelPosition === "top" && "column-reverse") ||
            (this.labelPosition === "right" && "column") ||
            (this.labelPosition === "bottom" && "row");
        const align = this.labelPosition === "left" || this.labelPosition === "right" ? "center" : "start";
        const gap = {
            gap: this.label
                ? this.gap !== undefined
                    ? this.gap
                    : this.labelPosition === "left" || this.labelPosition === "right"
                      ? "5px"
                      : "2px"
                : undefined,
        };
        const style = { display: "flex", flexDirection: flexDirection, alignItems: align };
        const font = { font: this.editorSize === "small" ? "var(--font-input-small)" : "var(--font-input)" };

        return html`
            <div style="${styleMap({ ...style, ...gap, ...font })}">
                <input
                    id="defaultInput"
                    name="${this.name}"
                    style="${styleMap({ ...font, width: this.inputWidth })}"
                    .value="${this.value}"
                    @input="${this.updateValue}"
                    @change="${this.valueChanged}"
                    min="${ifDefined(this.min)}"
                    max="${ifDefined(this.max)}"
                    maxlength="${ifDefined(this.maxlength)}"
                    minlength="${ifDefined(this.minlength)}"
                    step="${ifDefined(this.step)}"
                    size="${ifDefined(this.size)}"
                    pattern="${ifDefined(this.pattern)}"
                    placeholder="${ifDefined(this.placeholder)}"
                    ?required="${this.required}"
                    ?disabled="${this.disabled}"
                    @mousedown=${this._onFocusAsync}
                    @blur=${this._onBlur}
                    @keydown=${this._onKeyDown}
                    @keyup=${this._onKeyUp}
                    spellcheck="false"
                    autocomplete="off"
                />
                <label for="defaultInput">${this.label}</label>
            </div>
            <ul
                ?hidden=${!this._opened}
                id="suggestions"
                @mouseenter=${this._handleItemMouseEnter}
                @mouseleave=${this._handleItemMouseLeave}
                style="${styleMap(font)}"
            >
                ${this._matches.map((item) => html`<li @mousedown=${() => this.autocomplete(item)}>${this.highlightChars(item)}</li>`)}
            </ul>
        `;
    }

    render() {
        if (this.refresh) {
            return html`<div style="display:flex; align-items: center">
                ${this.getBodyHtml()}<se-secondary-button
                    icon="far fa-refresh"
                    @click=${this.refreshData}
                    ${htmlTitle("Refresh list")}
                ></se-secondary-button>
            </div>`;
        } else {
            return this.getBodyHtml();
        }
    }

    static styles = css`
        :host {
            display: block;
        }
        :host([hidden]) {
            display: none;
        }

        input {
            box-sizing: border-box;
        }

        input:disabled + label {
            color: gray;
        }
        input:read-only + label {
            color: gray;
        }
        input:read-only {
            color: gray;
        }
        input.invalid {
            outline: 2px solid pink;
        }
        .wrapper {
            position: relative;
        }
        ul {
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            padding: 0;
            z-index: 5000;
            background: white;
            display: block;
            list-style-type: none;
            border-left: 1px solid grey;
            box-shadow: grey 1px 1px 1px;
            border-radius: 2px;
            max-height: 300px;
            overflow: auto;
        }
        ul.position-top {
            border-top: 1px solid grey;
        }

        li {
            padding: 6px;
            cursor: pointer;
        }

        li:hover {
            outline: 1px solid var(--color-secondary);
            outline-offset: -2px;
            border-radius: 3px;
        }
        li.active {
            color: white;
            background-color: var(--color-secondary);
        }

        [hidden] {
            display: none;
        }
    `;
}
