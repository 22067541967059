import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

@customElement("se-pagination")
export class SePaginationElement extends LitElement {
    @property({ type: Number }) pageIndex? = 1;
    @property({ type: Number }) recordsPerPage? = 100;
    @property({ type: Number }) recordCount? = 0;

    onPageChange(e: Event, pageIndex: number) {
        this.pageIndex = pageIndex;
        this.dispatchEvent(new CustomEvent("pagechanged", { bubbles: true, composed: true, detail: { pageIndex: pageIndex } }));
    }
    changePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.recordCount = this.pageIndex * this.recordsPerPage;
        history.pushState({ gridPage: this.pageIndex }, null, this.getPageUrl(this.pageIndex));
    }
    private getPageUrl(pageIndex: number) {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        searchParams.set("gridPage", pageIndex.toString());
        url.search = searchParams.toString();
        return url.href;
    }
    firstUpdated() {}

    render() {
        const pageCount = Math.floor(this.recordCount / this.recordsPerPage) + (this.recordCount % this.recordsPerPage > 0 ? 1 : 0);
        if (!this.pageIndex || this.pageIndex < 1) this.pageIndex = 1;
        if (this.pageIndex > pageCount) this.pageIndex = pageCount;

        return html`
            <div style="display: flex; gap:5px">
                ${this.pageIndex > 1
                    ? html`<button @click="${(evt) => this.onPageChange(evt, this.pageIndex - 1)}">Previous</button>`
                    : html`<button class="disabled">Previous</button>`}

                <button @click=${(evt) => this.onPageChange(evt, 1)} class="${classMap({ selected: this.pageIndex === 1 })}">1</button>
                ${when(this.pageIndex > 4, () => html`<div>...</div>`)}
                ${when(
                    this.pageIndex < 5 && pageCount > 2,
                    () =>
                        html`<button @click="${(evt) => this.onPageChange(evt, 2)}" class="${classMap({ selected: this.pageIndex === 2 })}">
                            2
                        </button>`
                )}
                ${when(
                    this.pageIndex < 5 && pageCount > 3,
                    () =>
                        html`<button @click=${(evt) => this.onPageChange(evt, 3)} class=${classMap({ selected: this.pageIndex === 3 })}>
                            3
                        </button>`
                )}
                ${when(
                    this.pageIndex >= 3 && this.pageIndex < 5 && pageCount > 4,
                    () =>
                        html`<button @click=${(evt) => this.onPageChange(evt, 4)} class=${classMap({ selected: this.pageIndex === 4 })}>
                            4
                        </button>`
                )}
                ${when(
                    this.pageIndex === 4 && pageCount > 5,
                    () =>
                        html`<button @click=${(evt) => this.onPageChange(evt, 5)} class=${classMap({ selected: this.pageIndex === 5 })}>
                            5
                        </button>`
                )}
                ${when(
                    this.pageIndex >= 6 && this.pageIndex === pageCount,
                    () =>
                        html`<button
                            @click=${(evt) => this.onPageChange(evt, this.pageIndex - 2)}
                            class=${classMap({ selected: this.pageIndex === this.pageIndex - 2 })}
                        >
                            ${this.pageIndex - 2}
                        </button>`
                )}
                ${when(
                    this.pageIndex >= 5,
                    () =>
                        html`<button
                            @click=${(evt) => this.onPageChange(evt, this.pageIndex - 1)}
                            class=${classMap({ selected: this.pageIndex === this.pageIndex - 1 })}
                        >
                            ${this.pageIndex - 1}
                        </button>`
                )}
                ${when(
                    this.pageIndex >= 5 && this.pageIndex < pageCount,
                    () =>
                        html`<button
                            @click=${(evt) => this.onPageChange(evt, this.pageIndex)}
                            class=${classMap({ selected: this.pageIndex === this.pageIndex })}
                        >
                            ${this.pageIndex}
                        </button>`
                )}
                ${when(
                    this.pageIndex >= 5 && this.pageIndex < pageCount - 1,
                    () =>
                        html`<button
                            @click=${(evt) => this.onPageChange(evt, this.pageIndex + 1)}
                            class=${classMap({ selected: this.pageIndex === this.pageIndex + 1 })}
                        >
                            ${this.pageIndex + 1}
                        </button>`
                )}
                ${when(pageCount > 5 && this.pageIndex < pageCount - 2, () => html`<div>...</div>`)}
                ${when(
                    pageCount > 1,
                    () =>
                        html`<button
                            @click=${(evt) => this.onPageChange(evt, pageCount)}
                            class=${classMap({ selected: this.pageIndex === pageCount })}
                        >
                            ${pageCount}
                        </button>`
                )}
                ${this.pageIndex < pageCount
                    ? html`<button @click=${(evt) => this.onPageChange(evt, this.pageIndex + 1)}>Next</button>`
                    : html`<button class="disabled">Next</button>`}
            </div>
        `;
    }

    static styles = css`
        :host {
        }
        button {
            border: 1px solid Silver;
            background-color: var(--color-light);
            padding: 1px 5px 1px 5px;
        }
        button:hover {
            background-color: white;
            cursor: pointer;
        }
        .selected {
            border: none;
            font-weight: bold;
        }
        .selected:hover {
            background-color: var(--color-light);
            cursor: default;
        }
        .disabled {
            color: DarkGray;
        }
        .disabled:hover {
            background-color: var(--color-light);
            cursor: default;
        }
    `;
}
