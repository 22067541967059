export class ServerKeysViewModel {
        
    constructor() {        
    }

    id?: number;
    clientId?: string;
    clientName?: string;
    clientSecret?: string;
    status?: string;
    created?: string;
    expirationDate?: string;
}