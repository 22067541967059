import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import "element-internals-polyfill";
import { BaseEditor } from './base-editor';
import { styleMap } from 'lit/directives/style-map.js';
import { FaIconElement } from '../components/fa-icon.element';

export type checkboxOption = { id: number, name: string, value: boolean, isNew?: boolean, tristate?: 'never' | 'always' | 'auto' };

export type liveCheckboxOption = { id: number, name: string, liveValue: boolean, oldValue: boolean, tristate?: 'never' | 'always' | 'auto' };

@customElement('se-checkbox-list-editor')
export class CheckboxListEditorElement extends LitElement implements BaseEditor {

    @property({
        type: Array, hasChanged: (value: checkboxOption[], oldValue: checkboxOption[]) => {
            if (value !== oldValue)
                return true;
            else
                return false;
        }
    }) value: checkboxOption[];
    
    willUpdate(changedProperties) {
        if (changedProperties.has('value')) {
            this._liveValue = this.value.map(p => {
                let option: liveCheckboxOption = { oldValue: p.isNew ? undefined : p.value, liveValue: p.value, id: p.id, name: p.name };
                if (!p.tristate) {
                    if (this.tristate === undefined && p.value === undefined) {
                        option.tristate = "always";
                    }
                    else {
                        option.tristate = this.tristate ?? 'never';
                    }
                }
                else {
                    option.tristate = p.tristate;
                }
                return option;
            });
        }              
    };
    private _liveValue: liveCheckboxOption[];
    get liveValue() { return this._liveValue; }

    @property() tristate?: 'never' | 'always' | 'auto';

    @property() label?: string;
    @property() labelPosition?: 'left' | 'top' | 'right' | 'bottom' = 'right';
    @property() direction?: 'row' | 'column' = 'column';
    @property() gap?= "10px";
    @property() labelGap? = "10px";

    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) disabled = false;
    @property() name: string;
    @property() filter: string;
    @property({ type: Boolean }) isShowSelectedFirst = true;

    @query('#editor') private _editorElement: HTMLDivElement;

    constructor() {
        super();
    }

    hasChanged() {
        return ;
    }

    reportValidity(): boolean {
        return true;
    }

    firstUpdated() {
        
    }

    cancel() {
        this._liveValue.forEach(p => { p.liveValue = p.oldValue });
        this.requestUpdate();
    }

    onMouseDown(option: liveCheckboxOption) {
        if (this.disabled || this.readonly)
            return;

        const liveOption = this.liveValue.find(p => p.id === option.id);
        switch (liveOption.liveValue) {
            case undefined:
                if (liveOption.tristate === 'auto')
                    liveOption.liveValue = false;
                else
                    liveOption.liveValue = true;
                break;
            case true:
                liveOption.liveValue = false;
                break;
            case false:
                if (liveOption.tristate === 'always')
                    liveOption.liveValue = undefined;
                else
                    liveOption.liveValue = true;
                break;
        }
        const icon = this.shadowRoot.getElementById(`icon${liveOption.id}`) as FaIconElement;
        icon.style.color = this.getIconStyle(liveOption).color;
        icon.faClass = `far ${this.getIconClass(liveOption)}`;
        this.dispatchEvent(new CustomEvent("valueChanged", { bubbles: true, composed: true, detail: { editor: this, value: this._liveValue, hasChanged: this._liveValue.some(s => s.liveValue !== s.oldValue) } }));
    }

    getIconStyle(option: liveCheckboxOption) {
        return (option.liveValue || (option.tristate !== 'never' && option.liveValue === undefined)) ? { color: "black" } : { color: "gray" };
    }
    getIconClass(option: liveCheckboxOption) {
        return (option.liveValue === undefined && (option.tristate === 'always' || option.tristate === 'auto')) ? "fa-minus-square" : (option.liveValue === true ? "fa-check-square" : "fa-square");
    }

    render() {
        
        const flexDirection = (
            (this.labelPosition === 'left' && 'row-reverse') ||
            (this.labelPosition === 'top' && 'column-reverse') ||
            (this.labelPosition === 'right' && 'row') ||
            (this.labelPosition === 'bottom' && 'column')
        );
        const gap = this.labelGap??((this.labelPosition === 'left' && '5px') ||
            (this.labelPosition === 'top' && '0px') ||
            (this.labelPosition === 'right' && '5px') ||
            (this.labelPosition === 'bottom' && '0px'));

        const style = { display: 'flex', flexDirection: flexDirection, gap: gap, alignItems: 'center', opacity: this.disabled ? '0.7' : '1' };
        const editorStyle = { flexDirection: this.direction, gap: this.gap }
        const options = (this.filter ? this._liveValue.filter(p => p.name.toLowerCase().startsWith(this.filter.toLowerCase())) : this._liveValue).sort((a, b) => {
            if (!this.isShowSelectedFirst) 
                return a.name.localeCompare(b.name);
            

            if (a.liveValue !== false && b.liveValue === false)
                return -1;
            else if (a.liveValue === false && b.liveValue !== false)
                return 1;
            else
                return a.name.localeCompare(b.name);
        });
        return html`
            <div class="editor" style="${styleMap(editorStyle)}">
                ${options.map(option => {
                    return html`
                    <span style="${styleMap(style)}" @mousedown=${()=>this.onMouseDown(option)}>
                        <fa-icon id="icon${option.id}" style=${styleMap(this.getIconStyle(option))} class="icon" scale="1.2" fa-class="far ${this.getIconClass(option)}"></fa-icon>
                        <label for="input">${option.name}</label>
                    </span>
                `})}
            </div>
        `;
    }

    static styles = css`
    :host {
        display: block;
    }
    :host([hidden]) {
        display: none;
    }
    .editor {
        
        display: inline-flex;
    }
    .icon {
        color: gray;
    }
    .icon:hover {
        color: black;
    }
    label {
        font: var(--font-input-label);        
    }
    input:disabled+label {
        color:gray;
    }    
  `;
}

