import { container, singleton } from 'tsyringe';
import { PipesUsageViewModel } from '../models/pipes-usage-view-model';
import { UsageCostModel } from '../models/usage-cost-model';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';


@singleton()
export class UsageApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }
    async connectAsync(id: number) {
        return this.postObjectAsync(`/api/server/${id}/connect`, undefined, this._authService.token);
    }
    async getAllUsageAsync(pageIndex = 1, recordsPerPage = 100, pipeUsageName: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<PipesUsageViewModel>(`/api/usage/pipeUsage?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&pipeUsageName=${pipeUsageName}&sortOrder=${sortOrder}`, this._authService.token);
    }

    async getServersUsageAsync(timeUnit: string, startDate, endDate) {
        return this.getObjectAsync(`/api/usage/serverUsage?timeUnit=${timeUnit}&startDate=${startDate}&endDate=${endDate}`, this._authService.token);
    }

    async getOrgRunsDataUsageAsync(orgId: number, timeUnit: string, startDate, endDate) {
        return this.getObjectAsync<UsageCostModel>(`/api/usage/runs-data/org/${orgId}/${timeUnit}?startDate=${startDate}&endDate=${endDate}`, this._authService.token);
    }

    async getOrgPipesUsageAsync(usageRequest: any) {
        return this.postObjectReturnObjectAsync<PipesUsageViewModel>(`/api/usage/pipes-usage/org?source=controlcenter`, usageRequest, this._authService.token);
    }
    async getOrgPipeUsageAsync(usageRequest: any) {
        return this.postObjectReturnObjectAsync<PipesUsageViewModel>(`/api/usage/pipe-runs?source=controlcenter`, usageRequest, this._authService.token);
    }
    async getOrgPipeRunsUsageAsync(pipeId: number, timeUnit: string, startDate, endDate) {
        return this.getObjectAsync<UsageCostModel>(`/api/usage/pipe-usage/${pipeId}/${timeUnit}?startDate=${startDate}&endDate=${endDate}`, this._authService.token);
    }
}