import { ConfigScope } from "../enums/config-scope";
import { SpacePermission } from "./space-permission";

export class Space {
        
    constructor() {
    }

    id?: number;
    name: string;
    isDefaultAccess = true;
    permissions : SpacePermission[];
    scope? : ConfigScope

}