import { container, inject, singleton } from "tsyringe";
import { RateLimitUpdateModel, RateLimit, RateLimitViewModel } from "../models/rate-limit";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";

@singleton()
export class RateLimitApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<RateLimitUpdateModel>(`/api/rate-limit/${id}`, this._authService.token);
    }
    async getAllAsync() {
        return this.getObjectAsync<RateLimitViewModel[]>(`/api/rate-limit`, this._authService.token);
    }
    async createAsync(rateLimit: RateLimitUpdateModel) {
        return this.postObjectAsync(`/api/rate-limit`, rateLimit, this._authService.token);
    }
    async updateAsync(rateLimit: RateLimitUpdateModel) {
        return this.putObjectAsync(`/api/rate-limit`, rateLimit, this._authService.token);
    }
    async deleteManyAsync(rateLimitIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/rate-limit/many`, { ids: rateLimitIds }, this._authService.token);
    }
    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/rate-limit/${id}`, this._authService.token);
    }
}
