import { container, singleton } from 'tsyringe';
import { UsageApi } from './usage.api';

@singleton()
export class UsageService {

    private _usageApi: UsageApi;

    get api() { return this._usageApi }
    
    constructor() {        
        this._usageApi = container.resolve(UsageApi);
    }    


}