import { container, inject,singleton } from 'tsyringe';
import { ServersAdminViewModel } from '../models/servers-admin-view-model';
import { ServersOrgViewModel } from '../models/servers-org-view-model';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { ServerType } from '../enums/server-type';
import { AgentEditorModel } from '../models/agent-editor-model';
import { ServerUsageViewModel } from '../models/server-usage-view-model';


@singleton()
export class UsageApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getServersUsageAsync(timeUnit: string, startDate, endDate) {
        return this.getObjectAsync<ServerUsageViewModel>(`/api/usage/servers/system/${timeUnit}?startDate=${startDate}&endDate=${endDate}`, this._authService.token);
    }

    async getOrgServersUsageAsync(orgId: number, timeUnit: string, startDate, endDate) {
        return this.getObjectAsync<ServerUsageViewModel>(`/api/usage/runs/org/${orgId}/${timeUnit}?startDate=${startDate}&endDate=${endDate}`, this._authService.token);
    }
}