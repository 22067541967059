import { PreventAndRedirectCommands } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AppSettings } from "../../models/app-settings";
import { AppSettingsApi } from "../../services/app-settings.api";
import { AuthService } from "../../services/auth.service";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";

@customElement("se-app-settings")
export class SeAppSettingsElement extends LitElement {
    private _authService: AuthService;
    private _appSettingsApi: AppSettingsApi;
    private _settings: AppSettings;
    private _toasterService: ToasterService;

    @state() private _isLoading = true;

    @query("#isSystemAuditEnabled") private _isSystemAuditEnabledEditor: CheckboxEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._appSettingsApi = container.resolve(AppSettingsApi);
        this._settings = container.resolve(AppSettings);
        this._toasterService = container.resolve(ToasterService);
    }

    async onBeforeEnter(commands: PreventAndRedirectCommands) {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
    }

    connectedCallback() {
        super.connectedCallback();

        this.loadSettingsAsync();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private async loadSettingsAsync() {
        try {
            this._isLoading = true;
            const res = await this._appSettingsApi.getAppSettingsAsync();
            if (res.isOk) {
                this._settings = res.value;
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        } finally {
            this._isLoading = false;
        }
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            this._settings.isSystemAuditEnabled = this._isSystemAuditEnabledEditor.liveValue;
            const res = await this._appSettingsApi.updateAppSettingsAsync(this._settings);
            if (res.isOk) {
                this._toasterService.showSuccess("App settings saved successfully");
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">App Settings</div>
                <se-loading-panel
                    id="loadingPanel"
                    .loadingStyle=${{ borderTop: "solid 1px lightgray", backgroundColor: "white", minHeight: "200px", minWidth: "200px" }}
                    .isLoading=${this._isLoading}
                >
                    <div class="scroll-container">
                        <se-checkbox-editor
                            style="margin-top:10px;min-width:200px;min-height:200px"
                            id="isSystemAuditEnabled"
                            label="Enable system audit log"
                            labelPosition="right"
                            style="width: 100%"
                            ?value=${this._settings.isSystemAuditEnabled}
                        ></se-checkbox-editor>
                    </div>
                    <div class="savePanel">
                        <se-primary-button .action="${() => this.saveAsync()}" action-delay="500" text="Save Settings"></se-primary-button>
                        <se-secondary-button @click="${this.cancel}" text="Close"></se-secondary-button>
                    </div>
                </se-loading-panel>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
