import { container, singleton } from "tsyringe";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";

@singleton()
export class FileUploadApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();

        this._authService = container.resolve(AuthService);
    }

    async uploadSharedFileAsync(file: File, spaceId: number, labelId: number, loadstartCallback: any, progressCallback: any) {
        return this.uploadFileAsync(
            `/api/config/space/${spaceId}/label/${labelId}/shared-file/modified/${file.lastModified}`,
            file,
            this._authService.token,
            loadstartCallback,
            progressCallback
        );
    }

    async downloadSharedFileAsync(configId: number, name: string) {
        return this.getBlobAsync<any>(`/api/config/${configId}/download-shared-file?path=${name}`, this._authService.token);
    }
}
