import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { when } from "lit/directives/when.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { isRunningOnServer, isRunningOnServerOrStarting, RunStatus } from "../../enums/run-status";
import { SortOrder } from "se-shared/enums/sort-order";
import { RunViewModel } from "../../models/run-view-model";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { RunService } from "../../services/run.service";
import { ServerService } from "../../services/server.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import "./run-details.element";
import { RunDetailsElement } from "./run-details.element";
import { ParallelExport } from "../../enums/parallel-export";
import { formatSize } from "se-shared/utils/utils";
import "../components/status.element";

@customElement("se-runs")
export class SeRunsElement extends LitElement {
    @property() configId;

    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _runService: RunService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _data?: RunViewModel[];
    private _configName: string;
    private _serverService: ServerService;
    private _runRefresh = true;
    private _lastRefresh = 0;

    @state() private _hasSelectedRows = false;
    @state() private _isLoading = true;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;
    @query("#runDetails") private _runDetails: RunDetailsElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._runService = container.resolve(RunService);
        this._menuService = container.resolve(MenuService);
        this._serverService = container.resolve(ServerService);
    }

    private isParallelMasterSet(row: RunViewModel) {
        return row.parallelism > 1 && row.parallelSet === 0;
    }
    private isParallelSubSet(row: RunViewModel) {
        return row.parallelism > 1 && row.parallelSet > 0;
    }

    private menu(row: RunViewModel, col: DataGridColumn): MenuItem[] {
        const isRunning = isRunningOnServer(row.status);
        const isRunningOrStarting = isRunningOnServerOrStarting(row.status);
        return [
            {
                text: "Stop Run",
                action: this.onStopAsync.bind(this, row),
                hidden: !isRunningOrStarting,
                disabled: row.status === RunStatus.stopping,
            },
            { text: "-" },
            {
                text: "Kill Run",
                action: this.onKillAsync.bind(this, row),
                hidden: !isRunningOrStarting || this.isParallelMasterSet(row),
            },
            { text: "-" },
            {
                text: "Restart",
                action: this.onRestartAsync.bind(this, row),
                hidden: isRunningOrStarting || this.isParallelMasterSet(row), //We should support restarting a complete parallel run at some point, but hide it for now.
            },
            { text: "-" },
            {
                text: "Continue",
                action: this.onContinueAsync.bind(this, row),
                hidden: isRunningOrStarting || row.status === RunStatus.completed || row.status === RunStatus.success || !row.runtimeDataSize,
            },
            { text: "-" },
            {
                text: row.status === RunStatus.stopped ? "Retry Errors and Continue" : "Retry Errors",
                action: this.onRetryAsync.bind(this, row),
                hidden: isRunningOrStarting || !row.runtimeDataSize || row.errorCount === 0,
            },
            { text: "-" },
            { text: "Refresh Status", action: this.onRefreshStatusAsync.bind(this, row), hidden: !isRunning },
            { text: "-" },
            { text: "Fail Run", action: this.onFailRunAsync.bind(this, row), hidden: isRunning },
            { text: "-" },
            { text: "Delete", action: this.deleteRunAsync.bind(this, row, col), hidden: isRunningOrStarting || this.isParallelSubSet(row) },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this._runRefresh = true;
        this.loadDataAsync();
    }
    disconnectedCallback() {
        this._runRefresh = false;
        super.disconnectedCallback();
    }

    private async deleteRunAsync(row: RunViewModel) {
        if (row.parallelism > 1 && row.parallelSet > 0) {
            this._toasterService.showError("You cannot delete parallel sub runs directly. Delete the parallel master run instead.");
            return;
        }
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Run",
            body: `Are you sure you want to delete run with sequence ${row.sequence}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._runService.api.deleteBySequenceAsync(this.configId, row.sequence);
            if (result.isOk) {
                const deletedRows = this._data.filter((p) => p.sequence === row.sequence);
                let selectionChanged = false;
                for (const deletedRow of deletedRows) {
                    const index = this._data.indexOf(deletedRow);
                    this._data.splice(index, 1);
                    if (row["selected"]) selectionChanged = true;
                }
                if (selectionChanged) this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async updateDataAsync() {
        if (!this._runRefresh) {
            return;
        }
        if (!this._hasSelectedRows && !this._menuService.isMenuOpen()) {
            const lastRefresh = this._lastRefresh;
            this._lastRefresh = Date.now();
            const result =
                lastRefresh === 0
                    ? await this._runService.api.getManyAsync(this.configId)
                    : await this._runService.api.getManySinceEpochAsync(this.configId, lastRefresh);
            if (result.isOk) {
                if (lastRefresh === 0 || this._data.length === result.value.runs.length) {
                    this._data = result.value.runs;
                } else {
                    if (result.value.runs.length > 0) {
                        for (let i = result.value.runs.length - 1; i >= 0; i--) {
                            const row = result.value.runs[i];
                            const index = this._data.findIndex((p) => p.id === row.id);
                            if (index >= 0) {
                                this._data[index] = row;
                            } else {
                                this._data.unshift(row);
                            }
                        }
                        this._dataGrid.requestUpdate();
                    }
                }
                if (this._data.some((s) => s.endTime)) {
                    this._columns.find((p) => p.field === "endTime").hidden = false;
                } else {
                    this._columns.find((p) => p.field === "endTime").hidden = true;
                }
                if (this._data.some((s) => isRunningOnServer(s.status) && s.message)) {
                    this._columns.find((p) => p.field === "message").hidden = false;
                } else {
                    this._columns.find((p) => p.field === "message").hidden = true;
                }
            } else {
                this._lastRefresh = 0;
            }
        }
        const isQuickUpdate = this._data.some(
            (row) => row.status === RunStatus.queuing || row.status === RunStatus.starting || row.status === RunStatus.stopping
        );
        setTimeout(() => this.updateDataAsync(), isQuickUpdate ? 2000 : 10000);
    }

    private getRate(row) {
        /*const date = new Date(row.startTime);
        if (row.endTime) {
            const now = new Date(row.endTime);
            return (row.pageCount / ((now.getTime() - date.getTime()) / 1000)).toFixed(2);
        } else {
            const now = new Date();
            return (row.pageCount / ((now.getTime() - date.getTime()) / 1000)).toFixed(2);
        }*/
        const seconds = row.runTimeSec ?? row.toalTime ?? 0;
        return seconds ? (row.pageCount / seconds).toFixed(2) : "n/a";
    }

    private getStats(row: RunViewModel): TemplateResult {
        if (!row.actionCount && !row.dataCount && !row.errorCount) {
            if (isRunningOnServer(row.status)) {
                return html`<fa-icon single-color="silver" fa-class="far fa-spinner fa-spin"></fa-icon>`;
            } else {
                return html`n/a`;
            }
        } else {
            const rate = this.getRate(row);
            const totalTime = this.formatTotalTime(row);
            return html`<span
                ${htmlTitle(
                    html` <style>
                            .stats-table td:nth-child(2) {
                                text-align: right;
                            }
                            .stats-table td:nth-child(1) {
                                padding-right: 5px;
                            }
                        </style>
                        <table class="stats-table">
                            <tr>
                                <td>Actions:</td>
                                <td>${row.actionCount}</td>
                            </tr>
                            <tr>
                                <td>Data:</td>
                                <td>${row.dataCount}</td>
                            </tr>
                            <tr>
                                <td>Errors:</td>
                                <td>${row.errorCount}</td>
                            </tr>
                        </table>
                        <hr />
                        <table class="stats-table">
                            <tr>
                                <td>Total Pages:</td>
                                <td>${row.pageCount}</td>
                            </tr>
                            <tr>
                                <td>Dynamic pages:</td>
                                <td>${row.dynamicPageCount}</td>
                            </tr>
                            <tr>
                                <td>Rate (pages/sec):</td>
                                <td>${rate}</td>
                            </tr>
                            <tr>
                                <td>Requests:</td>
                                <td>${row.requestCount}</td>
                            </tr>
                            ${row.inputCount
                                ? html`<tr>
                                      <td>Inputs:</td>
                                      <td>${row.inputCount}</td>
                                  </tr>`
                                : html``}
                            ${row.exportCount || row.exportCount === 0
                                ? html`<tr>
                                      <td>Exported data:</td>
                                      <td>${row.exportCount}</td>
                                  </tr>`
                                : html``}
                            ${row.parallelism > 1
                                ? html`<tr>
                                      <td>Parallel export:</td>
                                      <td>${row.parallelExport ?? "Combined"}</td>
                                  </tr>`
                                : html``}
                            ${row.traffic
                                ? html`<tr>
                                      <td>Traffic:</td>
                                      <td>${formatSize(row.traffic)}</td>
                                  </tr>`
                                : html``}
                            <tr>
                                <td>${row.parallelism > 1 && row.parallelSet === 0 ? "Active" : "Run time"}:</td>
                                <td style="white-space: nowrap;">${totalTime}</td>
                            </tr>
                        </table>`,
                    true
                )}
                style="border-bottom: 1px dotted;cursor:pointer;user-select: none;"
                >a:${row.actionCount}&nbsp;d:${row.dataCount}&nbsp;e:${row.errorCount}</span
            >`;
        }
    }

    private getParallelism(row: RunViewModel): TemplateResult {
        return html`<span
            ${htmlTitle(
                html`<table>
                    <tr>
                        <td>Total runs:</td>
                        <td>${row.parallelism}</td>
                    </tr>
                    <tr>
                        <td>Concurrency:</td>
                        <td>${row.parallelMaxConcurrency ?? "Unlimited"}</td>
                    </tr>
                    <tr>
                        <td>Export:</td>
                        <td>${row.parallelExport ?? ParallelExport.Combined}</td>
                    </tr>
                </table>`,
                true
            )}
            style="border-bottom: 1px dotted;cursor:pointer;user-select: none;"
            >${row.parallelism}</span
        >`;
    }

    private getStatus(color: string, text: string, row: RunViewModel): TemplateResult {
        if (row.message && !isRunningOnServer(row.status)) {
            return html`<span
                ${htmlTitle(row.message, true)}
                style="border-bottom: 1px dotted;color:${color};cursor:pointer;user-select: none;"
                >${text}</span
            >`;
        } else {
            return html`<span style="color:${color};">${text}</span>`;
        }
    }

    private formatTotalTime(row) {
        let seconds = 0;
        if (row.parallelism > 1 && row.parallelSet === 0 && row.startTime) {
            const endTime = row.endTime ? new Date(row.endTime) : new Date();
            const startTime = new Date(row.startTime);
            seconds = (endTime.getTime() - startTime.getTime()) / 1000;
        } else {
            seconds = row.runTimeSec ?? row.toalTime ?? 0;
        }

        if (!seconds) {
            return "n/a";
        }
        if (seconds < 60) {
            return Math.round(seconds) + " sec";
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = Math.round(seconds % 60);
            if (remainingSeconds === 0) {
                return minutes + " min";
            } else {
                return minutes + " min" + " " + remainingSeconds + " sec";
            }
        } else {
            const hours = Math.floor(seconds / 3600);
            const remainingMinutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = Math.round(seconds % 60);
            let result = "";
            if (hours > 1) {
                result += hours + " hours";
            } else {
                result += hours + " hour";
            }
            if (remainingMinutes > 0) {
                result += " " + remainingMinutes + " min";
            }
            if (remainingSeconds > 0) {
                result += " " + remainingSeconds + " sec";
            }
            return result;
        }
    }

    private async loadDataAsync() {
        this._columns = [
            { field: "sequence", title: "Sequence", align: "center" },
            {
                field: "parallelism",
                title: "Parallelism",
                align: "center",
                template: (row) =>
                    row.parallelSet
                        ? html`${row.parallelSet}/${row.parallelism}`
                        : (row.parallelism ?? 1) > 1
                          ? this.getParallelism(row)
                          : html``,
            },
            { field: "serverName", title: "Server" },
            { field: "configVersion", title: "Version", align: "center" },
            { field: "proxyPoolName", title: "Proxy Pool" },
            {
                field: "status",
                title: "Status",
                sortable: true,
                align: "center",
                template: (row) =>
                    html`<div style="width: 100%">${choose(
                        row.status,
                        [
                            [
                                undefined || null,
                                () =>
                                    html`<se-status status-message="No Activity" status-color="--color-gray-4"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.success,
                                () =>
                                    html`<se-status status-message="Success" status-color="--color-status-blue"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.failure,
                                () =>
                                    html`<se-status status-message="Failed" status-color="--color-status-red"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.failed,
                                () =>
                                    html`<se-status status-message="Failed" status-color="--color-status-red"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.completed,
                                () =>
                                    html`<se-status status-message="Completed" status-color="--color-status-blue"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.running,
                                () =>
                                    html`<se-status status-message="Running" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.exporting,
                                () =>
                                    html`<se-status status-message="Exporting" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.starting,
                                () =>
                                    html`<se-status status-message="Starting" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.stopped,
                                () =>
                                    html`<se-status status-message="Stopped" status-color="--color-gray-4"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.stopping,
                                () =>
                                    html`<se-status status-message="Stopping" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.queuing,
                                () =>
                                    html`<span style="color:var(--color-gray-4);text-decoration: underline;">Queuing</span>`,
                            ],
                            [
                                RunStatus.waiting,
                                () => html`<span style="color:teal;text-decoration: underline;">Waiting</span>`,
                            ],
                        ],
                        () => html`${row.status}`
                    )}`,
            },
            {
                field: "startTime",
                title: "Started",
                align: "center",
                sortable: true,
                template: (row) => {
                    const date = row.startTime ? new Date(row.startTime) : undefined;
                    return date ? html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : html``;
                },
            },
            {
                field: "endTime",
                title: "Completed",
                align: "center",
                sortable: true,
                hidden: false,
                template: (row) => {
                    const date = row.startTime && row.endTime ? new Date(row.endTime) : undefined;
                    return date ? html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : html``;
                },
            },
            { field: "message", title: "Progress", hidden: true },
            { title: "Stats", align: "center", template: (row) => this.getStats(row) },
            {
                // View details column
                name: "info",
                cellStyle: { textAlign: "center", width: "20px" },
                template: (row) => {
                    let tooltip = "";
                    if (isRunningOnServer(row.status)) {
                        tooltip = "View live information";
                    } else {
                        tooltip = "View run details and files";
                    }
                    return html`<se-secondary-button
                        title="${tooltip}"
                        icon="far fa-browser"
                        @click=${() => this.runDetails(row.id)}
                    ></se-secondary-button>`;
                },
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];

        this._lastRefresh = Date.now();

        this._isLoading = true;
        try {
            const result = await this._runService.api.getManyAsync(this.configId);
            if (result.isOk) {
                this._data = result.value.runs;
                this._configName = result.value.configName;
                this._columns.find((p) => p.field === "parallelism").hidden = !this._data.some((p) => p.parallelism && p.parallelism > 1);
                this._columns.find((p) => p.field === "endTime").hidden = !this._data.some((s) => s.endTime);
                this._columns.find((p) => p.field === "message").hidden = !this._data.some((s) => isRunningOnServer(s.status) && s.message);
                this._columns.find((p) => p.field === "proxyPoolName").hidden = !this._data.some((s) => s.proxyPoolName);

                const isQuickUpdate = this._data.some(
                    (row) => row.status === RunStatus.queuing || row.status === RunStatus.starting || row.status === RunStatus.stopping
                );
                setTimeout(() => this.updateDataAsync(), isQuickUpdate ? 2000 : 10000);
            } else {
                this._lastRefresh = 0;
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
        if (this._selectAll) this._selectAll.value = false;
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== (this._data?.length ?? 0)) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder == SortOrder.Ascending ? 1 : -1;
        this._data.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -sortOrder : a[sortColumn] > b[sortColumn] ? sortOrder : 0));
    }

    private refresh() {
        this.loadDataAsync();
    }

    private async onDeleteManyAsync(event: MouseEvent) {
        event.stopPropagation();

        if (this._dataGrid.selectedRows.some((p) => !RunViewModel.canDelete(p.status))) {
            this._toasterService.showError("You cannot delete active runs. Please stop the runs first.");
            return;
        }

        const deletedRows = this._dataGrid.selectedRows.filter((p) => !p.parallelSet);
        if (deletedRows.length === 0) {
            this._toasterService.showError("You cannot delete parallel sub runs directly. Delete the parallel master run instead.");
            return;
        }
        const count = deletedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Runs",
            body: `Are you sure you want to delete ${count === 1 ? `1 run` : `${count} runs`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const sequences = deletedRows.map((p) => p.sequence as number);
            const result = await this._runService.api.deleteManyBySequenceAsync(this.configId, sequences);
            if (result.isOk) {
                this._selectAll.value = false;
                this._hasSelectedRows = false;
                this._data = this._data.filter((p) => !sequences.includes(p.sequence));
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async onSetFailedStatusOfManyAsync(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Fail Runs",
            body: `Are you sure you want to set ${count === 1 ? `1 run` : `${count} runs`} to Failed status?`,
            saveCaption: "Set Status",
        });
        if (result.isSave) {
            const ids = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._runService.api.failMany(this.configId, ids);
            if (result.isOk) {
                this._dataGrid.selectedRows.forEach((p) => {
                    p.status = RunStatus.failed;
                    p.endTime = p.endTime ? p.endTime : new Date();
                });
                this._dataGrid.requestUpdate();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onStopManyAsync(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Stop Runs",
            body: `Are you sure you want to stop ${count === 1 ? `1 run` : `${count} runs`}?`,
            saveCaption: "Stop Runs",
        });
        if (result.isSave) {
            const runIds = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._runService.api.stopMany(this.configId, runIds);
            if (result.isOk) {
                this._dataGrid.selectedRows.forEach((p) => {
                    p.status = RunStatus.stopping;
                });
                this._dataGrid.clearSelection();
                this._selectAll.value = false;
                this._hasSelectedRows = false;
                this._dataGrid.requestUpdate();
                setTimeout(() => this.updateDataAsync(), 2000);
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onStopAsync(row) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Stop Run",
            body: `Are you sure you want to stop run with sequence ${row.sequence}?`,
            saveCaption: "Stop Run",
        });
        if (result.isSave) {
            const result = await this._runService.api.stopMany(this.configId, [row.id]);
            if (result.isOk) {
                row.status = RunStatus.stopping;
                this._dataGrid.requestUpdate();
                setTimeout(() => this.updateDataAsync(), 2000);
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onKillAsync(row) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Kill Run",
            body: `Are you sure you want to kill run with sequence ${row.sequence}? ${row.status !== RunStatus.stopping ? "An attempt will be made to stop the run gracefully. If this fails you can kill the run again to forcefully stop the run." : ""}`,
            saveCaption: row.status !== RunStatus.stopping ? "Stop Run" : "Kill Run",
        });
        if (result.isSave) {
            const result = await this._runService.api.kill(row.id);
            if (result.isOk) {
                row.status = RunStatus.stopping;
                this._dataGrid.requestUpdate();
                setTimeout(() => this.updateDataAsync(), 2000);
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onRestartAsync(row) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Restart Run",
            body: `Are you sure you want to restart run with sequence ${row.sequence}?`,
            saveCaption: "Restart Run",
        });
        if (result.isSave) {
            const result = await this._runService.api.restart(row.id);
            if (result.isOk) {
                row.status = RunStatus.queuing;
                this._dataGrid.requestUpdate();
                setTimeout(() => this.updateDataAsync(), 2000);
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onContinueAsync(row) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Continue Run",
            body: `Are you sure you want to continue run with sequence ${row.sequence}?`,
            saveCaption: "Continue Run",
        });
        if (result.isSave) {
            const result = await this._runService.api.continue(row.id);
            if (result.isOk) {
                row.status = RunStatus.queuing;
                this._dataGrid.requestUpdate();
                setTimeout(() => this.updateDataAsync(), 2000);
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onRetryAsync(row) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: row.status === RunStatus.stopped ? "Retry Errors and Continue" : "Retry Errors",
            body: `Are you sure you want to ${
                row.status === RunStatus.stopped ? "retry errors and continue" : "retry errors for"
            } run with sequence ${row.sequence}?`,
            saveCaption: row.status === RunStatus.stopped ? "Retry Errors and Continue" : "Retry Errors",
        });
        if (result.isSave) {
            const result = await this._runService.api.retry(row.id);
            if (result.isOk) {
                row.status = RunStatus.queuing;
                this._dataGrid.requestUpdate();
                setTimeout(() => this.updateDataAsync(), 2000);
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onRefreshStatusAsync(row) {
        const result = await this._runService.api.checkStatus(row.id);
        if (result.isOk) {
            row.status = result.value.status;
            this._dataGrid.requestUpdate();
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }
    private async onFailRunAsync(row) {
        const result = await this._runService.api.failRun(row.id);
        if (result.isOk) {
            if (row.parallelism > 1) {
                //Changing status on a parallel set may effect statuses on other sets of the sequence.
                this.refresh();
            } else {
                row.status = RunStatus.failed;
                row.endTime = row.endTime ? row.endTime : new Date();
                this._dataGrid.requestUpdate();
            }
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    private async runDetails(runId: number) {
        this.dispatchEvent(new CustomEvent("showRunDetails", { bubbles: true, composed: true, detail: { runId: runId } }));
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        const canStop = this._hasSelectedRows && this._dataGrid.selectedRows.some((p) => RunViewModel.canStop(p.status));
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${!this._data || this._data.length === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteManyAsync(event)}
                                      ${htmlTitle("Delete selected runs")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                                  ${when(
                                      canStop,
                                      () =>
                                          html`<se-secondary-button
                                              @mousedown=${(event) => this.onStopManyAsync(event)}
                                              ${htmlTitle("Stop selected runs")}
                                              .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                              icon="fas fa-square"
                                              iconColor="dimgray"
                                          ></se-secondary-button>`
                                  )}
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onSetFailedStatusOfManyAsync(event)}
                                      ${htmlTitle("Fail selected runs")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-times-hexagon"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No runs available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .right-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
