import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ConfigScope } from "../../enums/config-scope";
import { Space } from "../../models/space";
import { AuthService } from "../../services/auth.service";
import { SpaceApi } from "../../services/space.api";
import { SpaceService } from "../../services/space.service";
import { UserState } from "../../services/user.state";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { SelectEditorElement } from "../editors/select-editor.element";

@customElement("se-space-editor")
export class SeSpaceEditorElement extends LitElement {
    private _authService: AuthService;
    private _spaceService: SpaceService;
    private _spaceApi: SpaceApi;
    private _isNew = false;
    private _spaceId?: number;
    private _space: Space;
    private _userState: UserState;
    private _toasterService: ToasterService;

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#defaultAccess") private _defaultAccessEditor: CheckboxEditorElement;
    @query("#scope") private _scopeEditor: SelectEditorElement;

    private _configTypes: Array<{ id: string; name: string }>  = Object.entries(ConfigScope).map(([key, value]) => ({ id: value, name: value }));

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._spaceService = container.resolve(SpaceService);
        this._spaceApi = container.resolve(SpaceApi);
        this._userState = container.resolve(UserState);
        this._toasterService = container.resolve(ToasterService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.spaceId) {
            this._spaceId = parseInt(location.params.spaceId.valueOf() as string);
            this._isNew = false;
            //return this._spaceApi.getAsync(this._spaceId).then(space => this._space = space).catch(err => this.error(err));
            const res = await this._spaceApi.getAsync(this._spaceId);
            if (res.isOk) {
                this._space = res.value;
            } else {
                this._toasterService.showNetworkError(res.err);
                return commands.prevent();
            }
        } else {
            this._isNew = true;
            this._space = new Space();
        }

        this.updateConfigScopeValues();
    }


    /**
     * Change the display values of the scope drop down
     */
    private updateConfigScopeValues() {

        //remove the global scope if the user is not an SE4 admin
        if (!this._authService.isSE4Admin) {
            this._configTypes.splice(
                this._configTypes.findIndex((p) => p.name === ConfigScope.Global),
                1
            );
        }

        this._configTypes.forEach((scope) => {

            if (scope.name === ConfigScope.Private) {
                scope.name = "Space";
            } else if (scope.name === ConfigScope.Public) {
                scope.name = "Organization";
            }

        });
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {

            const res = this._isNew
                ? await this._spaceService.api.createAsync(
                      this._nameEditor.liveValue,
                    true, // this._defaultAccessEditor.liveValue
                      this._scopeEditor.liveValue as ConfigScope
                  )
                : await this._spaceService.api.updateAsync(
                      this._spaceId,
                      this._nameEditor.liveValue,
                    true, // this._defaultAccessEditor.liveValue
                      this._scopeEditor.liveValue as ConfigScope
                  );
            if (res.isOk) {
                await this._userState.refreshUserSpacesAsync();
                this._userState.userSpacesChanged.triggerVoid();
                history.back();
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "Create" : "Edit"} Space</div>
                <div class="scroll-container">
                    <se-input-editor
                        id="name"
                        name="name"
                        type="text"
                        label="Name"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._space.name}
                    ></se-input-editor>
                    <se-select-editor
                        style="margin-top:5px"
                        id="scope"
                        label="Template Scope"
                        .value=${this._space.scope ?? ConfigScope.Private}
                        .options=${this._configTypes}
                    ></se-select-editor>
                    <!--<se-checkbox-editor
                        style="margin-top:10px"
                        id="defaultAccess"
                        label="Default access"
                        labelPosition="right"
                        style="width: 100%"
                        ?value=${this._space.isDefaultAccess}
                    ></se-checkbox-editor>-->
                </div>
                <div class="savePanel">
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Create" : "Save"} Space"
                    ></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
