import { container, singleton } from "tsyringe";
import { RateLimitApi } from "./rate-limit.api";

@singleton()
export class RateLimitService {
    //private _userState: UserState;
    private _rateLimitApi: RateLimitApi;

    get api() {
        return this._rateLimitApi;
    }

    constructor() {
        //this._userState = container.resolve(UserState);
        this._rateLimitApi = container.resolve(RateLimitApi);
    }
}
