import { container, inject,singleton } from 'tsyringe';
import { ConfigScope } from '../enums/config-scope';
import { idName } from '../models/id-name';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';

@singleton()
export class AgentTemplateApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }    

    async addConfigAgentTemplateAsync(configId: number, agentTemplateId: number, sortOrder: number) {
        return this.postObjectAsync(`/api/agent-template/${agentTemplateId}/config/${configId}/sort/${sortOrder}`, {}, this._authService.token);
    }
    async removeConfigAgentTemplateAsync(configId: number, agentTemplateId: number) {
        return this.deleteObjectAsync(`/api/agent-template/${agentTemplateId}/config/${configId}`, this._authService.token);
    }
}