
export class DestinationViewModel {
        
    constructor() {        
    }
    id?: number;
    destinationName: string;
    description?: string;
    configJson: string;
    parsedConfigJson: any;
    destinationType: string;
    createdBy?: string;
    status?: string;
    organizationId?: number;
    created?: Date;
    updated?: Date;
}