import { container, inject, singleton } from 'tsyringe';
import { UserGroupApi } from './user-group.api';

@singleton()
export class UserGroupService {

    //private _userState: UserState;
    private _userGroupApi: UserGroupApi;

    get api() { return this._userGroupApi };
    
    constructor() {        
        //this._userState = container.resolve(UserState);
        this._userGroupApi = container.resolve(UserGroupApi);
    }    

    /*async createSpaceAsync(name: string, isDefaultAccess: boolean) {
        const res = await this.createAsync(name, isDefaultAccess);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }
    async updateSpaceAsync(id: number, name: string, isDefaultAccess: boolean) {
        const res = await this.updateAsync(id, name, isDefaultAccess);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }
    async removeSpaceAsync(id: number) {
        const res = await this.removeAsync(id);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }*/

}