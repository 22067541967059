import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import "element-internals-polyfill";
import { BaseEditor } from './base-editor';
import { styleMap } from 'lit/directives/style-map.js';
import { classMap } from 'lit/directives/class-map.js';

@customElement('se-radio-editor')
export class RadioEditorElement extends LitElement implements BaseEditor {
    @property({ type: Boolean }) set value(val: boolean) {
        this.oldValue = val;
        this.liveValue = val;        
    };
    oldValue?: boolean;
    @state() liveValue?: boolean;    
    
    @property() label?: string;
    @property() labelPosition?: 'left' | 'top' | 'right' | 'bottom' = 'top';
    
    @property({ type: Boolean }) readonly?: boolean;    
    @property({ type: Boolean }) disabled = false;
    @property() name: string;

    @query('#editor') private _editorElement: HTMLDivElement;

    constructor() {
        super();
    }        
    reportValidity(): boolean {
        return true;        
    }
    
    firstUpdated() {        
    }

    cancel() {
        this.liveValue = this.value;
        this.requestUpdate();
    }

    onMouseDown() {
        if (this.disabled || this.readonly)
            return;

        this.liveValue = !this.liveValue;

        this.dispatchEvent(new CustomEvent("valueChanged", { bubbles: true, composed: true, detail: { editor: this, value: this.liveValue, oldValue: this.value } }));
    }

    render() {
        const iconClass = this.liveValue ? "fa-dot-circle" : "fa-circle";
        const iconStyle = this.liveValue ? { color: "black" } : { color: "gray" };
        const flexDirection = (
            (this.labelPosition === 'left' && 'row-reverse') ||
            (this.labelPosition === 'top' && 'column-reverse') ||
            (this.labelPosition === 'right' && 'row') ||
            (this.labelPosition === 'bottom' && 'column')
        );
        const gap = (this.labelPosition === 'left' && '5px') ||
            (this.labelPosition === 'top' && '0px') ||
            (this.labelPosition === 'right' && '5px') ||
            (this.labelPosition === 'bottom' && '0px');

        const style = { display: 'flex', flexDirection: flexDirection, gap: gap, alignItems: 'center', opacity: this.disabled ?'0.7' : '1' };
        return html`
            <div id="editor" style="${styleMap(style)}" @mousedown=${this.onMouseDown}>
                <fa-icon style=${styleMap(iconStyle)} class="icon" scale="1.2" fa-class="far ${iconClass}"></fa-icon>
                <label for="input">${this.label}</label>
            </div>
        `;
    }

    static styles = css`
    :host {
        display: block;
    }
    :host([hidden]) {
        display: none;
    }
    .icon {
        color: gray;
    }
    .icon:hover {
        color: black;
    }
    label {
        font: var(--font-input-label);        
    }
    input:disabled+label {
        color:gray;
    }    
  `;
}

