import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { container } from "tsyringe";
import { Organization } from "../../models/organization";
import { ServerGroup } from "../../models/server-group";
import { AuthService } from "../../services/auth.service";
import { ServerGroupService } from "../../services/server-group.service";
import { BaseEditor } from "../editors/base-editor";
import { InputEditorElement } from "../editors/input-editor.element";
import { TextAreaEditorElement } from "../editors/textarea-editor.element";

@customElement("se-server-group-editor")
export class SeServerGroupEditorElement extends LitElement {
    private _authService: AuthService;
    private _serverGroupService: ServerGroupService;
    private _isNew = false;
    private _serverGroupId?: number;
    private _serverGroup: ServerGroup;
    private _orgs: Organization[];

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#description") private _descriptionEditor: TextAreaEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._serverGroupService = container.resolve(ServerGroupService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.serverGroupId) {
            this._serverGroupId = parseInt(location.params.serverGroupId.valueOf() as string);
            this._isNew = false;
            //return this._spaceApi.getAsync(this._spaceId).then(space => this._space = space).catch(err => this.error(err));
            const res = await this._serverGroupService.api.getAsync(this._serverGroupId);
            if (res.isOk) {
                this._serverGroup = res.value;
            } else {
                alert(res.err.message);
                return commands.prevent();
            }
        } else {
            this._isNew = true;
            this._serverGroup = new ServerGroup();
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {        
        if (this.reportValidity()) {
            const res = this._isNew
                ? await this._serverGroupService.api.createAsync(this._nameEditor.liveValue, this._descriptionEditor.liveValue)
                : await this._serverGroupService.api.updateAsync(
                      this._serverGroupId,
                      this._nameEditor.liveValue,
                      this._descriptionEditor.liveValue
                  );
            if (res.isOk) {
                history.back();
            } else {
                alert(res.err.message);
            }
        }
    }

    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "Add" : "Edit"} Server Group</div>
                <div class="scroll-container">
                    <se-input-editor
                        id="name"
                        name="name"
                        type="text"
                        label="Name"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._serverGroup.name}
                    ></se-input-editor>
                    <se-textarea-editor
                        id="description"
                        name="description"
                        type="text"
                        label="Description"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._serverGroup.description ?? ""}
                    ></se-textarea-editor>
                </div>
                <div class="savePanel">
                    <se-primary-button .action="${() => this.saveAsync()}" action-delay="500" text="${this._isNew ? "Add" : "Save"} Group"></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
