import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import { htmlTitle } from "se-shared/directives/html-title.directive.js";
import "./typeahead-editor.element";

@customElement("se-fancy-select")
export class SeFancySelect extends LitElement {
    @property() value: string;

    @property({ attribute: false, type: Array }) textOptions?: string[];
    @property({ attribute: false }) getTextOptions?: () => Promise<string[]>;

    @property({ attribute: "label" }) label?: string;
    @property({ attribute: "label-position" }) labelPosition?: "left" | "top" | "right" | "bottom" = "top";

    @property({ attribute: "icon" }) icon?: string;
    @property({ attribute: "right-icon" }) rightIcon?: string;
    @property({ attribute: "icon-color" }) iconColor?: string;
    @property({ attribute: "placeholder" }) placeholde?: string;
    @property({ attribute: "html-title" }) htmlTitle?: string;

    @property({ attribute: "min-suggestion-width" }) minSuggestionWidth?: string;

    @property({ attribute: "hover-background-color" }) hoverBackgroundColor?: string;

    @state() private _isSelecting = false;
    @state() private _selectorWidth: string = undefined;

    @query(".selector") private _selectorElement: HTMLDivElement;

    private _startSelectHandler = () => this.startSelect();

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private startSelect() {
        this._isSelecting = true;

        this._selectorWidth = this._selectorElement.clientWidth - 10 + "px"; //minus padding
    }
    private endSelect() {
        this._isSelecting = false;
    }

    private async selectAsync(evt: CustomEvent) {
        evt.stopPropagation();
        this.dispatchEvent(
            new CustomEvent("valueChanged", {
                bubbles: true,
                composed: true,
                detail: { editor: this, value: evt.detail.value, oldValue: evt.detail.oldValue },
            })
        );
        this.endSelect();
    }

    render() {
        return html` ${this._isSelecting
            ? html`<div
                  class="type-ahead"
                  style="color:black; ${styleMap({
                      minWidth: this._selectorWidth,
                  })}"
              >
                  <se-typeahead-editor
                      style="width:100%"
                      input-width="100%"
                      size="1"
                      .value=${this.value}
                      .getTextOptions=${this.getTextOptions}
                      .textOptions=${this.textOptions}
                      show-all
                      auto-open
                      min-suggestion-width=${this.minSuggestionWidth}
                      @editorBlur=${this.endSelect.bind(this)}
                      @valueChanged=${(evt) => this.selectAsync(evt)}
                      .label=${this.label}
                      .labelPosition=${this.labelPosition}
                  ></se-typeahead-editor>
              </div>`
            : html`<div
                  class="selector"
                  @click=${this._startSelectHandler}
                  ${htmlTitle(this.htmlTitle)}
                  @mouseover=${this.hoverBackgroundColor
                      ? () => (this._selectorElement.style.backgroundColor = this.hoverBackgroundColor)
                      : undefined}
                  @mouseout=${this.hoverBackgroundColor ? () => (this._selectorElement.style.backgroundColor = "") : undefined}
              >${this.icon
                          ? html`<fa-icon single-color=${this.iconColor} fa-class=${this.icon}></fa-icon>&nbsp;&nbsp;`
                    : html``}${this.value}
                          ${this.rightIcon ? html`<fa-icon single-color=${this.iconColor} fa-class=${this.rightIcon}></fa-icon>`
                    : html``}
              </div>`}`;
    }

    static styles = css`
        :host {
            display: block;
            height: 30px;
            display: flex;
            align-items: center;
        }

        :host([hidden]) {
            display: none;
        }

        .type-ahead {
            padding: 0px 5px;
            width: 100%;
        }
        .selector {
            height: 100%;
            width: 100%;
            padding: 0px 5px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 5px;
            gap: 5px;
        }
    `;
}
