import intersect from "fast_array_intersect";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import { container } from "tsyringe";
import { idName } from "../../models/id-name";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { LabelService } from "../../services/label.service";
import { MenuService } from "../../services/menu.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { MenuDialog } from "../components/menu-template";
import { BaseEditor } from "../editors/base-editor";
import "../editors/checkbox-list-editor.element";
import { CheckboxListEditorElement, checkboxOption } from "../editors/checkbox-list-editor.element";
import "../editors/input-editor.element";
import "../components/loading-panel.element";
import { SeLoadingPanel } from "../components/loading-panel.element";

@customElement("se-add-labels")
export class AddLabelsElement extends LitElement implements MenuDialog {
    private _userState: UserState;
    private _labelService: LabelService;
    private _configService: ConfigService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _menuService: MenuService;

    @query("#checkboxList") private _checkboxList: CheckboxListEditorElement;
    @query("#editorForm") private _editorForm: HTMLFormElement;
    @query("#editorContainer") private _editorContainer: HTMLDivElement;
    @query("#loadingPanel") private _loadingPanelElement: SeLoadingPanel;

    @property() name: string;

    @state() private _labels: checkboxOption[] = [];
    @state() private _hasChanged = false;
    @state() private _createLabel: string;
    @state() private _isLoading = true;

    private _selectedRows: any[];

    constructor() {
        super();

        this._userState = container.resolve(UserState);
        this._labelService = container.resolve(LabelService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._configService = container.resolve(ConfigService);
        this._menuService = container.resolve(MenuService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    /*async showMenu(input: { selectedRows?: any[] }) {

        this._selectedRows = input.selectedRows;

        let labels: checkboxOption[] = [];
        if (this._selectedRows.length > 1) {
            const labelArrays = this._selectedRows.map((p) => p.labels as idName[]);
            const common = intersect(labelArrays, (x: any) => x.id);
            const all = labelArrays.flat(1);
            const allLabels = [...all, ...common.map((p) => ({ ...p, value: true }))] as checkboxOption[];
            labels = [...new Map(allLabels.map((item) => [item["id"], item])).values()];
        } else if (this._selectedRows.length > 0) {
            labels = this._selectedRows[0].labels.map((p) => ({ ...p, value: true }));
        }

        this._labels = (await this._userState.getUserLabelsAsync()).value
            .filter((p) => !p.labelFilter)
            .map((p) => {
                const label = labels.find((s) => s.id === p.id);
                return { id: p.id, name: p.name, value: label ? label.value : false };
            });
    }*/

    async showMenu(input: { selectedRows?: any[]; configId?: number }) {
        this.showMenuAsync(input);
    }

    private async showMenuAsync(input: { selectedRows?: any[]; configId?: number }) {
        try {
            this._isLoading = true;
            if (input.configId) {
                const result = await this._configService.api.getConfigLabelIdsAsync(input.configId);
                if (result.isOk) {
                    this._selectedRows = [
                        {
                            id: input.configId,
                            labels: result.value.map((m) => ({
                                id: m,
                            })),
                        },
                    ];
                } else {
                    this._toasterService.showNetworkError(result.err);
                    return;
                }
            } else {
                this._selectedRows = input.selectedRows;
            }

            let labels: checkboxOption[] = [];
            if (this._selectedRows.length > 1) {
                const labelArrays = this._selectedRows.map((p) => p.labels as idName[]);
                const common = intersect(labelArrays, (x: any) => x.id);
                const all = labelArrays.flat(1);
                const allLabels = [...all, ...common.map((p) => ({ ...p, value: true }))] as checkboxOption[];
                labels = [...new Map(allLabels.map((item) => [item["id"], item])).values()];
            } else if (this._selectedRows.length > 0) {
                labels = this._selectedRows[0].labels.map((p) => ({ ...p, value: true }));
            }

            const allResult = await this._userState.getUserLabelsAsync();
            if (allResult.isOk) {
                this._labels = allResult.value
                    .filter((p) => !p.labelFilter)
                    .map((p) => {
                        const label = labels.find((s) => s.id === p.id);
                        return { id: p.id, name: p.name, value: label ? label.value : false };
                    });
            } else {
                this._toasterService.showNetworkError(allResult.err);
                return;
            }
        } finally {
            this._isLoading = false;
        }
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private onValueChanged(event: CustomEvent) {
        this._hasChanged = event.detail.hasChanged;
    }

    private searchChanged(event: CustomEvent) {
        if (this._checkboxList) this._checkboxList.filter = event.detail.value;
        if (!this._labels.some((s) => s.name.toLowerCase() === event.detail.value.toLowerCase())) {
            this._createLabel = event.detail.value;
        } else {
            this._createLabel = undefined;
        }
    }

    private async onApply() {
        const changedValues = this._checkboxList.liveValue.filter((p) => p.liveValue !== p.oldValue);
        for (const row of this._selectedRows) {
            const labels = row.labels as idName[];
            for (const value of changedValues) {
                if (value.liveValue) {
                    if (!labels.some((s) => s.id === value.id)) {
                        const result = await this._configService.api.addLabelAsync(row.id, value.id);
                        if (result.isErr) {
                            this._toasterService.showNetworkError(result.err);
                        } else {
                            labels.push({ id: value.id, name: value.name });
                        }
                    }
                } else {
                    const label = labels.find((s) => s.id === value.id);
                    if (label) {
                        const result = await this._configService.api.removeLabelAsync(row.id, value.id);
                        if (result.isErr) {
                            this._toasterService.showNetworkError(result.err);
                        } else {
                            labels.splice(labels.indexOf(label), 1);
                        }
                    }
                }
            }
        }
        this._userState.gridRowsChanged.triggerVoid();
        this._menuService.closeMenu();
    }
    private async onCreateLabel() {
        const label = {
            name: this._createLabel,
            spaceId: this._userState.selectedSpaceId > 0 ? this._userState.selectedSpaceId : null,
            userId: this._userState.selectedSpaceId === 0 ? this._authService.user.id : null,
            organizationId: this._userState.selectedSpaceId === 0 ? null : this._authService.user.organizationId,
            isApplyToAll: false,
        };

        const res = await this._labelService.api.createAsync(label);

        if (res.isOk) {
            await this._labelService.bookmarkApi.createAsync(res.value.id);
            const labels = await this._userState.refreshUserLabelsAsync();
            if (labels.isErr) {
                this._toasterService.showNetworkError(labels.err);
            }
            this._labels = [{ id: res.value.id, name: res.value.name, value: true, isNew: true }, ...this._labels];
            this._hasChanged = true;
            this._createLabel = undefined;
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="header">
                    <span>Label as:</span>
                </div>
                <se-loading-panel
                    id="loadingPanel"
                    .loadingStyle=${{ borderTop: "solid 1px lightgray", backgroundColor: "white", minHeight: "50px", minWidth: "125px" }}
                    .isLoading=${this._isLoading}
                >
                    <div class="search">
                        <se-input-editor maxlength="20" class="input" @editorChanged=${this.searchChanged}></se-input-editor
                        ><fa-icon class="icon" fa-class="far fa-search" style="margin-top:5px"></fa-icon>
                    </div>
                    <div class="body">
                        ${this._labels.length > 0
                            ? html`
                                  <div class="scroll-container" id="editorContainer">
                                      <se-checkbox-list-editor
                                          id="checkboxList"
                                          @valueChanged=${this.onValueChanged}
                                          .value=${this._labels}
                                      ></se-checkbox-list-editor>
                                  </div>
                              `
                            : html`
                                  <div style="padding:10px; background-color: white;margin-top:2px">
                                      <p>No labels available.</p>
                                  </div>
                              `}
                        <div class="savePanel" style="${styleMap({ display: this._hasChanged || this._createLabel ? "flex" : "none" })}">
                            <se-primary-button
                                .hidden=${!this._hasChanged}
                                .action="${() => this.onApply()}"
                                action-delay="500"
                                text="Apply"
                            ></se-primary-button>
                            <se-secondary-button
                                .hidden=${!this._createLabel}
                                .action="${() => this.onCreateLabel()}"
                                action-delay="500"
                                text='New Label "${this._createLabel}"'
                            ></se-secondary-button>
                        </div>
                    </div>
                </se-loading-panel>
            </form>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            font: var(--font);
            box-sizing: border-box;
            background-color: var(--color-light);
            box-shadow: 1px 2px 2px 1px Gray;
            border-radius: 2px 2px 2px 2px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
        }
        .body {
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            width: 100%;
            background-color: white;
        }
        .header {
            background-color: var(--color-light);
            padding: 5px 5px 0px 5px;
        }
        .search {
            background-color: white;
            padding: 5px 5px 0px 5px;
            border-top: solid 1px lightgray;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .icon {
            position: absolute;
            right: 15px;
        }
        .scroll-container {
            min-height: 0;
            overflow: auto;
            padding: 5px 10px 10px 10px;
            margin-top: 5px;
            background-color: white;
            box-sizing: border-box;
            max-height: 250px;
            max-width: 400px;
        }
        .savePanel {
            border-top: solid 1px lightgray;
            display: flex;
            flex-direction: rows;
            justify-content: end;
            padding: 8px 5px 10px 5px;
        }
    `;
}
