
export class Organization {
        
    constructor() {
    }

    id: number;
    name: string;
    isBillable: boolean;
    isApproved?: boolean;
    isMfaRequired: boolean;
    isProxyRequired: boolean;
    isAuditLog: boolean;
    adminEmail?: number;
    userCount?: string;
}