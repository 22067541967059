import { container, inject, singleton } from 'tsyringe';
import { SeEvent } from '../../utils/se-events';
import { AppConfigApi } from './app-config.api';
import { DraftApi } from './draft.api';

@singleton()
export class DraftService {

    private _draftApi: DraftApi;
    get api() { return this._draftApi };

    constructor() {
        this._draftApi = container.resolve(DraftApi);
    }        
}