interface SignalBindingAsync<T> {
    boundTo: Object;
    handler: (data: T) => Promise<any>;
}

interface SignalBinding<T> {    
    boundTo: Object;
    handler: (data: T) => void;
}

interface IAsyncSignal<T> {
    bind(boundTo: Object, handler: (data: T) => void): void;
    bindAsync(boundTo: Object, handler: (data: T) => Promise<any>): void;
    unbind(boundTo: Object, handler: (data: T) => void): void;
    unbindAsync(boundTo: Object, handler: (data: T) => Promise<any>): void;
}

export class SeEvent<T> implements IAsyncSignal<T> {
    private asyncHandlers: Array<SignalBindingAsync<T>> = [];
    private handlers: Array<SignalBinding<T>> = [];

    public bind(
        boundTo: Object,        
        handler: (data: T) => void
    ): void {
        if (this.handlers.some(h => h.handler === handler && h.boundTo === boundTo)) {
            this.unbind(boundTo, handler);
        }
        this.handlers.push({ boundTo: boundTo, handler: handler });
    }
    public bindAsync(
        boundTo: Object,        
        handler: (data: T) => Promise<any>,
    ): void {
        if (this.asyncHandlers.some(h => h.handler === handler && h.boundTo === boundTo)) {
            this.unbindAsync(boundTo, handler);
        }        
        this.asyncHandlers.push({ boundTo: boundTo, handler: handler });
    }

    public unbind(boundTo: Object, handler: (data: T) => void): void {
        this.handlers = this.handlers.filter(h => h.handler !== handler || h.boundTo !== boundTo);
        this.asyncHandlers = this.asyncHandlers.filter(h => h.handler !== handler || h.boundTo !== boundTo);
    }
    public unbindAsync(boundTo: Object, handler: (data: T) => Promise<any>): void {
        this.handlers = this.handlers.filter(h => h.handler !== handler || h.boundTo !== boundTo);
        this.asyncHandlers = this.asyncHandlers.filter(h => h.handler !== handler || h.boundTo !== boundTo);
    }

    public async triggerVoid(): Promise<void> {
        // Duplicate the array to avoid side effects during iteration.
        this.handlers.slice(0).map(h => h.handler.call(h.boundTo));
        this.asyncHandlers.slice(0).map(h => h.handler.call(h.boundTo));
    }

    public async trigger(data: T): Promise<void> {
        // Duplicate the array to avoid side effects during iteration.
        this.handlers.slice(0).map(h => h.handler.call(h.boundTo, data));
        this.asyncHandlers.slice(0).map(h => h.handler.call(h.boundTo, data));
    }

    public async triggerAwait(data: T): Promise<void> {
        // Duplicate the array to avoid side effects during iteration.
        this.handlers.slice(0).map(h => h.handler.call(h.boundTo, data));
        if (this.asyncHandlers.length > 0) {
            const promises = this.asyncHandlers.slice(0).map(h => h.handler.call(h.boundTo, data));
            await Promise.all(promises);
        };
        
    }
    public async triggerVoidAwait(): Promise<void> {
        // Duplicate the array to avoid side effects during iteration.
        this.handlers.slice(0).map(h => h.handler.call(h.boundTo));
        if (this.asyncHandlers.length > 0) {
            const promises = this.asyncHandlers.slice(0).map(h => h.handler.call(h.boundTo));
            await Promise.all(promises);
        };

    }

    public expose(): IAsyncSignal<T> {
        return this
    }
}