import { LitElement, html, css, unsafeCSS } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { library, dom, config } from "@fortawesome/fontawesome-svg-core";
import styles from "@fortawesome/fontawesome-svg-core/styles.css";
import { styleMap } from 'lit/directives/style-map.js';
import {
    faLink, faCode, faFile,
    faFileCheck, faFileTimes,
    faCheck, faThumbsUp, faThumbsDown, faSync,
    faExternalLink, faCogs, faPlay, faStop,
    faDatabase, faList, faSort, faSortUp, faSortDown, faSuperscript
} from "@fortawesome/pro-duotone-svg-icons";

import {
    faHomeAlt,
    faTimesCircle,
    faPlusCircle,
    faTrash,
    faEdit,
    faUserCircle,
    faCaretDown, faCaretUp, faCaretLeft,
    faCaretRight, faPlus, faQuestionCircle,
    faSquare as faSquareSolid,
    faMoon, faBuilding, faRepeat,
    faCircleXmark, faCircleExclamation, faCircleCheck, faCircleQuestion, faUser, faListUl, faChartSimple, faShield
} from "@fortawesome/pro-solid-svg-icons";

import {    
    faTrashAlt,
    faBars,
    faEllipsisV, faRedo, faRobot,
    faPlusCircle as falPlusCircle,
    faSquare, faCheckSquare, faMinusSquare,
    faCircle, faDotCircle, faTag, faSearch, faTruck, faCopy,
    faCheck as farCheck, faTimes, faPencil,
    faTimesHexagon, faBrowser, faSpinner, faCog,
    faAlignJustify, faImage, faCubes, faShapes, faUpload,
    faDatabase as farDatabase, faClipboardCheck, faLockAlt,
    faLockOpenAlt, faCube, faFile as farFiles, faRunning,
    faPersonRunningFast, faCircleStop, faSquareInfo, faPenToSquare, faXmark, faPlay as farPlay, faPause
} from "@fortawesome/pro-regular-svg-icons";


@customElement('fa-icon')
export class FaIconElement extends LitElement {    

    constructor() {
        super();
        library.add(faLink, faCode, faCog, faFile,
            faFileCheck, faSpinner, faFileTimes,
            faCheck, faThumbsUp, faThumbsDown, faSync,
            faExternalLink, faCogs, faTrashAlt, faPlay,
            faStop, faList, faDatabase, faTimes,
            faUserCircle, faHomeAlt, faTimesCircle,
            faPlusCircle, faTrash, faEdit,
            faBars, faEllipsisV, faSort, faSortUp, faSortDown,
            faRedo, faCaretDown, faCaretLeft, faCaretRight, faRobot,
            falPlusCircle, faPlus, faSquare, faCheckSquare,
            faMinusSquare, faCircle, faDotCircle, faTag, faSearch,
            faTruck, faCopy, farCheck, faPencil, faQuestionCircle,
            faTimesHexagon, faSquareSolid, faBrowser, faMoon,
            faAlignJustify, faImage, faSuperscript, faCubes, faShapes,
            farDatabase, faClipboardCheck, faCaretUp, faLockOpenAlt,
            faLockAlt, faCube, farFiles, faUpload, faBuilding, faRepeat,
            faRunning, faPersonRunningFast, faCircleStop, faSquareInfo, faPenToSquare,
            faCircleXmark, faCircleExclamation, faCircleCheck, faCircleQuestion, faXmark,
            faUser, farPlay, faPause, faListUl,faChartSimple, faShield
        );
        config.autoReplaceSvg = 'nest';
    }   

    @property({ attribute: 'fa-class' })
    faClass?= "fad fa-link";

    @property({ attribute: 'single-color'})
    singleColor?: string;

    @property({type: Boolean })
    defaultColors? = false;

    @property({ attribute: 'primary-color' })
    primaryColor?= "var(--color-primary)";

    @property({ attribute: 'secondary-color' })
    secondaryColor?= "var(--color-lightblue)";

    @query("#icon") private _iconElement: HTMLElement; 

    static get styles() {
        return [
            unsafeCSS(styles), this.componentStyles
        ];
    }

    myClick(e: Event) {        
        alert(e.type);
    }

    /*firstUpdated() {
        //@ts-ignore
        dom.watch({
            autoReplaceSvgRoot: this.shadowRoot,
            observeMutationsRoot: this.shadowRoot
        });
    }*/

    updated(changedProperties) {
        if (changedProperties.has('faClass')) {
            if (this._iconElement)
                this._iconElement.removeAttribute("data-fa-i2svg");
            dom.i2svg({ node: this.shadowRoot });
        }
    }
   
    render() {

        const styles = this.defaultColors ? {} : {
            "--fa-primary-color": this.singleColor ? this.singleColor : this.primaryColor,
            "--fa-secondary-color": this.singleColor ? this.singleColor : this.secondaryColor,
            "color": this.singleColor ? this.singleColor : undefined
        };
        //current fa-sort seems to be broken
        if (this.faClass.replace("fad ","") === "fa-sort") {
            return html`<svg draggable="false" class="svg-inline--fa fa-sort fa-w-10" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="sort" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><g class="fa-group"><path class="fa-secondary" fill="currentColor" d="M279.05 288.05h-238c-21.4 0-32.07 25.95-17 41l119.1 119 .1.1a23.9 23.9 0 0 0 33.8-.1l119-119c15.1-15.05 4.4-41-17-41zm-238-64h238c21.4 0 32.1-25.9 17-41l-119-119a.94.94 0 0 0-.1-.1 23.9 23.9 0 0 0-33.8.1l-119.1 119c-15.05 15.1-4.4 41 17 41z"></path><path class="fa-primary" fill="currentColor" d=""></path></g></svg>`
        }
        const cls = this.faClass + " icon";
        return html`            
    <i draggable="false" id="icon" class="${cls}" style="${styleMap(styles)}"></i>
        `;
    }

    static componentStyles = css`
    :host {
        display: inline-block;
        pointer-events: none;        
    }
    :host([hidden]) {
        display: none;
    }
    .icon {
        --fa-secondary-opacity: 1.0;
        display: flex;
        justify-content: center;
    }
  `;
}