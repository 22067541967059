export class DisplaySchema {
    table = new DisplaySchemaTable();

    error?: string;

    isLocked?: boolean;
}

export class DisplaySchemaTable {
    name: string;
    tables: DisplaySchemaTable[] = [];
    fields: DisplaySchemaField[] = [];
    emptyRows?: string;
    emptyTable?: string;
    nameError?: string;
    tableError?: string;
    isError?: boolean;
}

export class DisplaySchemaField {
    name: string;

    contentType: string;

    isAllowEmpty = true;

    formatStyle?: string;

    format?: string;

    timezone?: string;

    error?: string;
}
