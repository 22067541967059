import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { UserStatus } from "../../enums/user-status";
import { User } from "../../models/user";
import { AuthService } from "../../services/auth.service";
import { UserApi } from "../../services/user-api";
import { UserState } from "../../services/user.state";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { SelectEditorElement } from "../editors/select-editor.element";

@customElement("se-user-editor")
export class SeUserEditorElement extends LitElement {
    private _authService: AuthService;
    private _isNew = false;
    private _userId?: number;
    private _name: string;
    private _user: User;
    private _userApi: UserApi;
    private _userState: UserState;
    private _toasterService: ToasterService;

    private _accessOptions: { id: number; name: string }[] = [];
    private _authMethodOptions: { id: number; name: string }[] = [];

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#email") private _email: InputEditorElement;
    @query("#password") private _password: InputEditorElement;
    @query("#accessLevel") private _accessLevel: SelectEditorElement;
    @query("#authMethod") private _authMethod: SelectEditorElement;
    @query("#isMfaRequired") private _isMfaRequiredEditor: CheckboxEditorElement;

    @state() private _authMethodId = 1;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userApi = container.resolve(UserApi);
        this._userState = container.resolve(UserState);
        this._toasterService = container.resolve(ToasterService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            document.location = "/login";
            return commands.prevent();
        }
        if (location.params.userId) {
            this._userId = parseInt(location.params.userId.valueOf() as string);
            this._isNew = false;
            const res = await this._userApi.getAsync(this._userId);
            if (res.isOk) {
                if (res.value.status == UserStatus.Deleted && !this._authService.isSE4Admin) {
                    this._toasterService.showError("This user is deleted.");
                    return commands.prevent();
                }
                this._user = res.value;
            } else {
                this._toasterService.showNetworkError(res.err);
                return commands.prevent();
            }
        } else {
            this._isNew = true;
            this._user = new User();
        }

        this._accessOptions = [
            //{ id: 400, name: "Read Only" },
            { id: 300, name: "User" },
            { id: 200, name: "Administrator" },
        ];

        if (this._authService.isSE4Admin) {
            this._accessOptions.push({ id: 100, name: "SE4 Administrator" });
        }

        this._authMethodOptions = [
            { id: 1, name: "Local" },
            /*{ id: 2, name: "Google" },
            { id: 3, name: "Microsoft" },*/
        ];
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            this._user.id = this._userId;
            this._user.email = this._email.liveValue;
            this._user.name = this._nameEditor.liveValue;
            this._user.authenticationService = Number(this._authMethod.liveValue);
            if (this._user.authenticationService === 1) {
                this._user.password = this._password?.liveValue;
            }
            this._user.accessLevel = Number(this._accessLevel.liveValue);
            this._user.ownerOrganizationId = this._authService.user.organizationId;
            if (!this._isNew) {
                this._user.isMfaRequired = this._isMfaRequiredEditor.liveValue;
            }

            const res = this._isNew
                ? await this._userApi.createAsync(this._user)
                : await this._userApi.updateAsync(this._userId, this._user);
            if (res.isOk) {
                await this._userState.refreshUserSpacesAsync();
                this._userState.userSpacesChanged.triggerVoid();
                history.back();
            } else {
                alert(res.err.message);
            }
        }
    }

    cancel() {
        history.back();
    }

    async authMethodChanged() {
        this._authMethodId = Number(this._authMethod.liveValue);
    }

    //fullname , email, temp password, and access level
    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "Create" : "Edit"} User</div>
                <div class="scroll-container" style="display: flex;gap: 10px;flex-direction: column;">
                    <se-input-editor
                        id="name"
                        name="name"
                        type="text"
                        label="Full Name"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._user?.name}
                    ></se-input-editor>
                    <se-input-editor
                        id="email"
                        name="email"
                        type="text"
                        label="Email"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._user?.email}
                    ></se-input-editor>

                    <se-select-editor
                        class="inputEditor"
                        required
                        id="authMethod"
                        name="authMethod"
                        type="text"
                        label="Authentication Method"
                        .value=${String(this._user?.authenticationService || 1)}
                        labelPosition="top"
                        .options=${this._authMethodOptions}
                        width="100%"
                        @valueChanged=${this.authMethodChanged}
                    ></se-select-editor>
                    ${this._authMethodId === 1
                        ? html`
                              ${this._isNew
                                  ? html`<se-input-editor
                                        id="password"
                                        name="password"
                                        input-type="password"
                                        label="Temp Password"
                                        labelPosition="top"
                                        input-type="text"
                                        required
                                        size="30"
                                    ></se-input-editor>`
                                  : html`<se-input-editor
                                        id="password"
                                        name="password"
                                        placeholder="(Unchanged)"
                                        input-type="password"
                                        label="Temp Password"
                                        labelPosition="top"
                                        input-type="text"
                                        size="30"
                                    ></se-input-editor>`}
                          `
                        : html``}

                    <se-select-editor
                        class="inputEditor"
                        required
                        id="accessLevel"
                        name="accessLevel"
                        type="text"
                        label="Group"
                        .value=${String(this._user?.accessLevel || 300)}
                        labelPosition="top"
                        .options=${this._accessOptions}
                        width="100%"
                    ></se-select-editor>

                    ${this._isNew
                        ? html``
                        : html`
                              <div style="padding:10px 0px 10px 0px">
                                  <se-checkbox-editor
                                      id="isMfaRequired"
                                      width="300px"
                                      .value="${this._user?.isMfaRequired ?? false}"
                                      name="isMfaRequired"
                                      label="Multi-factor authentication required"
                                      labelPosition="right"
                                  ></se-checkbox-editor>
                              </div>
                          `}
                </div>

                <div class="savePanel">
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Create" : "Save"} User"
                    ></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
