import { container, inject, singleton } from 'tsyringe';
import { err, NetworkError, ok, Result } from 'se-shared/utils/result';
import { SeEvent } from '../../utils/se-events';
import { UserSpace } from '../models/user-space';
import { UserSpaces } from '../models/user-spaces';
import { AppConfigApi } from './app-config.api';
import { DraftService } from './draft.service';
import { SpaceApi } from './space.api';
import { UserApi } from './user-api';
import { UserState } from './user.state';

@singleton()
export class SpaceService {

    //private _userState: UserState;
    private _spaceApi: SpaceApi;

    get api() { return this._spaceApi };
    
    constructor() {        
        //this._userState = container.resolve(UserState);
        this._spaceApi = container.resolve(SpaceApi);
    }    

    /*async createSpaceAsync(name: string, isDefaultAccess: boolean) {
        const res = await this.createAsync(name, isDefaultAccess);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }
    async updateSpaceAsync(id: number, name: string, isDefaultAccess: boolean) {
        const res = await this.updateAsync(id, name, isDefaultAccess);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }
    async removeSpaceAsync(id: number) {
        const res = await this.removeAsync(id);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }*/

}