import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement, unsafeCSS } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import backgroundMaze from "../../../../assets/backgroundMaze.png";
import instagram from "../../../../assets/instagram.svg";
import linkedin from "../../../../assets/linkedin.svg";
import logo from "../../../../assets/logo-tagline.svg";
import twitter from "../../../../assets/twitter.svg";
import { AppConfigService } from "../../services/app-config.service";
import "../components/primary-button.element";
import "../components/secondary-button.element";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import "../editors/input-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { SePrimaryButton } from "../components/primary-button.element";

@customElement("se-user-registration")
export class UserRegistrationElements extends LitElement {
    private _appConfigService: AppConfigService;
    private _toasterService: ToasterService;

    @query("#editorForm") private _editorForm: HTMLFormElement;
    @query("#cancelButton") private _cancelButton: HTMLButtonElement;
    @query("#saveButton") private _saveButton: SePrimaryButton;

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#companyName") private _companyNameEditor: InputEditorElement;
    @query("#email") private _emailEditor: InputEditorElement;
    @query("#password") private _passwordEditor: InputEditorElement;
    @query("#confirmPassword") private _comparePasswordEditor: InputEditorElement;

    private _eulaAccepted = false;

    @state() private _submitted = false;
    @state() private _hasChanged = false;

    constructor() {
        super();
        this.submitLogin = this.submitLogin.bind(this);
        this.onSave = this.onSave.bind(this);

        this._appConfigService = container.resolve(AppConfigService);
        this._toasterService = container.resolve(ToasterService);
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        //if (!this._appConfigService.isNew) {
        //    return commands.redirect('/login');
        //}
        //else {
        //    return undefined;
        //}
        return undefined;
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("keydown", this.submitLogin);
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
    }
    disconnectedCallback() {
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        super.disconnectedCallback();
    }
    submitLogin(event) {
        if (event.key === "Enter") {
            this.onSave(event);
        }
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }

    updateChangedValues() {}

    show() {
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }

    firstUpdated() {}

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async onSave(ev) {
        ev.preventDefault();
        this._toasterService.clear();

        //make sure that EULA has been accepted
        if (this._eulaAccepted !== true) {
            this._toasterService.showError("Please accept the EULA");
            return;
        }

        if (this.reportValidity()) {
            this._saveButton.loading = true;
            this._hasChanged = false;
            await this._appConfigService
                .registerUserAsync(
                    this._nameEditor.liveValue,
                    this._companyNameEditor.liveValue,
                    this._emailEditor.liveValue,
                    this._passwordEditor.liveValue
                )
                .then(() => this.success())
                .catch((err) => this.error(err))
                .finally(() => this._saveButton.loading = false);
        }
    }

    /**
     * The eula checkbox was clicked
     * @param evt
     */
    private onEulaChanged(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._eulaAccepted = true;
            } else {
                this._eulaAccepted = false;
            }
        }
    }

    private success() {
        this._toasterService.showSuccess("Thank you for registering for an account. Please use your email and password to login.", 0);
        //alert("Account successfully registered. Please use your email and password to login.");
        Router.go("/login");
        Router.go("/");
    }
    private async login(ev) {
        ev.preventDefault();
        Router.go("/login");
    }

    private async showEULA(ev) {
        ev.preventDefault();
        this.openEULADialog();
        //Router.go("/login");
    }

    private openEULADialog() {
        fetch("./eula.txt")
            .then((response) => response.text())
            .then((eulaContent) => {
                // Open a new window
                const eulaWin = window.open("", "EULA");

                // Write the content of the EULA into the new window
                eulaWin.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Sequentum EULA</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                                margin: 20px;
                                overflow-y: scroll;
                                max-height: 90vh;
                            }
                        </style>
                    </head>
                    <body>
                        <pre>${eulaContent}</pre>
                    </body>
                    </html>
                `);

                // Close the document after writing
                eulaWin.document.close();
            })
            .catch((error) => console.error("Error fetching EULA:", error));
    }

    private error(err: Error) {
        this._toasterService.showError(err.message);
        this._hasChanged = true;
    }

    passwordChanged() {
        if (this._passwordEditor.liveValue !== this._comparePasswordEditor.liveValue) {
            this._comparePasswordEditor.setCustomValidity("Passwords don't match.");
        } else {
            this._comparePasswordEditor.setCustomValidity("");
            this._comparePasswordEditor.reportValidity();
        }
    }

    render() {
        return html`
            <div class="header"></div>
            <form id="editorForm" class="editor" @keydown="${this.submitLogin}">
                <div class="scroll-container">
                    <div class="content">
                        
                            <div style="text-align: center;margin-bottom: 20px;margin-top: 20px;">
                                <img src=${logo} class="header-logo" />
                            </div>
                            ${this._submitted ? html`<div class="h2">Thank you for joining Sequentum Cloud. You should receive a confirmation email, and a representative will contact you shortly with more details.</div>` : html`
                            <div class="h2">Join Sequentum Cloud</div>
                            <se-input-editor
                                size="normal"
                                enableAutocomplete
                                class="inputEditor"
                                id="name"
                                name="name"
                                type="text"
                                label="Name"
                                placeholder="John Doe"
                                labelPosition="top"
                                required
                                size="30"
                            ></se-input-editor>
                            <se-input-editor
                                size="normal"
                                class="inputEditor"
                                id="email"
                                name="email"
                                type="text"
                                label="Email"
                                placeholder="example@company.com"
                                labelPosition="top"
                                input-type="email"
                                required
                                size="30"
                            ></se-input-editor>
                            <se-input-editor
                                size="normal"
                                class="inputEditor"
                                id="companyName"
                                name="companyName"
                                type="text"
                                label="Organization"
                                placeholder="Company Name"
                                labelPosition="top"
                                required
                                size="30"
                            ></se-input-editor>

                            <se-input-editor
                                size="normal"
                                enableAutocomplete
                                class="inputEditor"
                                @editorChanged=${this.passwordChanged}
                                id="password"
                                name="password"
                                type="text"
                                label="Password"
                                labelPosition="top"
                                input-type="password"
                                required
                                size="30"
                            ></se-input-editor>
                            <se-input-editor
                                size="normal"
                                enableAutocomplete
                                class="inputEditor"
                                @editorChanged=${this.passwordChanged}
                                id="confirmPassword"
                                name="confirmPassword"
                                type="text"
                                label="Confirm password"
                                labelPosition="top"
                                input-type="password"
                                required
                                size="30"
                            ></se-input-editor>

                            <div class="inputEditor" style="flex:1">
                                <div class="linkLabel">
                                    <se-checkbox-editor
                                        id="eula"
                                        name="eula"
                                        .value=${this._eulaAccepted}
                                        @valueChanged=${this.onEulaChanged}
                                        tristate="auto"
                                    ></se-checkbox-editor>

                                    <a href="EULA" class="link" @click="${this.showEULA}" style="margin-left: 10px">I agree to EULA </a>
                                </div>
                            </div>

                            <div class="loginButton inputEditor">
                                <se-primary-button
                                    size="normal"
                                    position="first"
                                    id="saveButton"
                                    class="saveButton"
                                    @click="${this.onSave}"
                                    ?disabled="${!this._hasChanged}"
                                    text="Join"
                                ></se-primary-button>
                            </div>
                        
                        <div class="loginLink">
                            <a href="Forgot Password" class="link" @click="${this.login}">Go to Login</a>
                        </div>
                        `}
                    </div>
                </div>
            </form>
            <div class="footer">
                <div>
                    © 2024 Sequentum • <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a> •
                    <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a>
                </div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter} /></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin} /></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram} /></a>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            font: Inter;
            background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
            background-size: cover;
        }
        .header {
            box-sizing: border-box;
            background-color: var(--color-primary);
            color: white;
            padding-left: 15px;
            padding-top: 15px;
            display: flex;
            gap: 15px;
            font: var(--font-h3);
        }
        .h2 {
            font: var(--font-h2);
            color: var(--color-primary);
            font-weight: 600;
            padding: 0px 0px 10px;
        }
        .h3 {
            font: var(--font-h3);
            color: var(--color-primary);
            font-weight: 600;
        }
        .header-logo {
            width: 165px;
            height: fit-content;
            margin: 27px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0px;
            padding-top: 0px;
            border-radius: 5px;
        }
        .scroll-container {
            min-height: 0px;
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 40px 90px;
        }
        .link {
            color: var(--color-purple-dark);
            text-decoration: none;
        }
        .loginLink {
            display: flex;
            justify-content: center;
            padding: 10px;
            font: var(--font-button);
        }
        .loginButton {
            margin-top: 10px;
        }
        .content {
            width: 300px;
            gap: 10px;
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 10px;
        }
        .linkLabel {
            display: flex;
            flex: 2;
            font: var(--font-small);
            align-items: right;
            text-decoration: none;
            padding-top: 15px;
            flex-direction: row;
            align-items: center;
        }
        .footer {
            color: rgba(83, 86, 122, 1);
            font: var(--font-small);
            padding: 0px 20px 10px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .footer a {
            color: rgba(83, 86, 122, 1);
            text-decoration: none;
        }
        .footer a:hover {
            color: var(--color-secondary);
            text-decoration: none;
        }
    `;
}
