import { LitElement, html, css } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';

@customElement('se-nav')
export class SeNav extends LitElement {
    @property({ type: String }) baseUrl = '';

    @state() private _selected = 0;
    @state() private _navItems = [];

    static styles = css`
    :host {
      display: flex;
      box-sizing: border-box;
      font: var(--font);
      height: 100%;
    }
    h2 {
      font: var(--font-h2);
      font-weight: 600;
      margin: 0px 0px 10px;
    }
    .nav-list {
      display: flex;
      flex-direction: column;
      background-color: var(--color-blue-25);
      border-right: solid 1px var(--color-gray-2);
      padding: 15px 10px;
    }
    .nav-item {
      cursor: pointer;
      padding: 5px 10px;
      position: relative;
      color: var(--color-navy-3);
      text-wrap: nowrap;
    }
    .active-nav-item {
      color: var(--color-purple);
    }
    .active-nav-item::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      border-radius: 9px;
      background-color: var(--color-purple);
    }
    .nav-item:hover {
      background-color: rgb(49 54 93 / 9%);
      border-radius: 8px;
    }
    .container-content {
      overflow-y: auto;
      overflow-x: hidden;
      flex-grow: 1;
      padding: 15px 15px 55px;
    }
  `;

    private navSwitch(index: number) {
        this._selected = index;
        const slotName = this._navItems[index].slotName;
        const newUrl = `${this.baseUrl}/${slotName}`;
        history.pushState(null, '', newUrl);
        
        this.dispatchEvent(new CustomEvent('nav-changed', {
            detail: { slotName },
            bubbles: true,
            composed: true
        }));
        
        this.requestUpdate();
    }

    get currentSlot(): string {
        return this._navItems[this._selected]?.slotName || '';
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('popstate', this._onPopState);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('popstate', this._onPopState);
    }

    firstUpdated() {
        this._updateNavItems();
        this._updateSelectedFromUrl();
    }

    private _onPopState = () => {
        this._updateSelectedFromUrl();
    };

    private _updateNavItems() {
        // Get the slotted nav content
        const slots = Array.from(this.children).filter((node) => node.hasAttribute('label'));

        // Map the slots to extract labels and custom slot names
        this._navItems = slots.map((node: HTMLElement, index) => ({
            label: node.getAttribute('label'),
            slotName: node.getAttribute('slot') || `nav-${index}`,
        }));
    }

    private _updateSelectedFromUrl() {
        const path = window.location.pathname;
        const baseUrl = this.baseUrl.replace(/\/$/, ''); // Remove trailing slash if any
        const slotName = path.startsWith(`${baseUrl}/`) ? path.slice(`${baseUrl}/`.length) : '';

        if (!slotName) {
            // No slot name in URL, default to first nav item
            this._selected = 0;
            return;
        }

        const index = this._navItems.findIndex((item) => item.slotName === slotName);
        if (index !== -1) {
            this._selected = index;
        } else {
            // Slot name not found, default to first
            this._selected = 0;
        }
        this.requestUpdate();
    }

    render() {
        return html`
      <div class="nav-list">
        ${this._navItems.map(
            (navItem, index) => html`
            <div
              @click=${() => this.navSwitch(index)}
              class="nav-item ${this._selected === index ? 'active-nav-item' : ''}"
            >
              ${navItem.label}
            </div>
          `
        )}
      </div>
      <div class="container-content">
        ${this._navItems.length > 0
                ? html`
              <div>
                <h2>${this._navItems[this._selected].label}</h2>
                <slot name="${this._navItems[this._selected].slotName}"></slot>
              </div>
            `
                : ''}
      </div>
    `;
    }
}


// Usage example in HTML
// <se-nav>
//   <div slot="URLslug1" label="Nav 1">Content for Nav 1</div>
//   <div slot="URLslug2" label="Nav 2">Content for Nav 2</div>
// </se-nav>