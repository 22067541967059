import { ConfigType } from "../enums/config-type";
import { LabelFilter } from "../enums/label-filter";
import { RunStatus } from "../enums/run-status";

export class Label {
        
    constructor() {
    }

    id?: number;
    name: string;
    spaceId?: number;
    userId?: number;
    organizationId?: number;
    labelFilter?: LabelFilter;
    configType?: ConfigType;
    status?: RunStatus;        
}