import { container, inject, singleton } from 'tsyringe';
import { err, NetworkError, ok, Result } from 'se-shared/utils/result';
import { SeEvent } from '../../utils/se-events';
import { UserSpace } from '../models/user-space';
import { UserSpaces } from '../models/user-spaces';
import { AppConfigApi } from './app-config.api';
import { ProxyApi } from './proxy.api';
import { UserApi } from './user-api';
import { UserState } from './user.state';

@singleton()
export class ProxyService {

    private _proxyApi: ProxyApi;

    get api() { return this._proxyApi };
    
    constructor() {        
        this._proxyApi = container.resolve(ProxyApi);
    }    


}