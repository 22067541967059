import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { LogLevel } from "../../enums/log-level";
import { ServerGroup } from "../../models/server-group";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ProxyService } from "../../services/proxy.service";
import { RunService } from "../../services/run.service";
import { ServerGroupService } from "../../services/server-group.service";
import { UserState } from "../../services/user.state";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { SelectEditorElement } from "../editors/select-editor.element";

@customElement("se-start-many-runs")
export class SeStartManyRunsElement extends LitElement {
    private _modalService: ModalDialogService;
    private _proxyService: ProxyService;
    private _authService: AuthService;
    private _runService: RunService;
    private _configService: ConfigService;
    private _toaterService: ToasterService;
    private _userState: UserState;
    private _serverGroupService: ServerGroupService;
    private _serverGroups: ServerGroup[];
    private _selectedProxyPoolId = -1;

    private _selectedIds: number[] = [];
    private _count: number;
    private _spaceId: number;
    private _labelId: number;
    private _templateId?= 0;
    private _returnUrl: string;
    private _hasAllProxies = false;
    @state() private _isProxyRequired = true;

    @state() private _proxyPools: any[] = [];

    @state() private _runEveryPeriodValue = "1";

    @query("#server") private _serverEditor: SelectEditorElement;
    @query("#proxyPools") private _proxyPoolsSelect: SelectEditorElement;
    @query("#exclusive") private _excludiveEditor: CheckboxEditorElement;
    @query("#waitOnFailure") private _waitOnFailureEditor: CheckboxEditorElement;
    @query("#logLevel") private _logLevelEditor: SelectEditorElement;

    private _logLevels = Object.entries(LogLevel).map(([value]) => ({ id: value, name: value }));

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userState = container.resolve(UserState);
        this._configService = container.resolve(ConfigService);
        this._toaterService = container.resolve(ToasterService);
        this._runService = container.resolve(RunService);
        this._proxyService = container.resolve(ProxyService);
        this._modalService = container.resolve(ModalDialogService);
        this._serverGroupService = container.resolve(ServerGroupService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isUser) {
            return commands.redirect("/login");
        }

        if (location.params.spaceId) {
            this._spaceId = parseInt(location.params.spaceId.valueOf() as string);
        }
        if (location.params.labelId) {
            this._labelId = parseInt(location.params.labelId.valueOf() as string);
        }
        if (location.params.templateId) {
            this._templateId = parseInt(location.params.templateId.valueOf() as string);
        }

        this._returnUrl = `space/${this._spaceId}/label/${this._labelId}` + (this._templateId ? `/template/${this._templateId}` : ``);

        if (this._userState.selectedIds) {
            this._selectedIds = [...this._userState.selectedIds];
            this._count = this._selectedIds.length;
        } else {
            if (router.location.pathname) {
                this._toaterService.showUnexpectedError("No selected agents.");
                commands.prevent();
                return;
            } else {
                Router.go(this._returnUrl);
                return;
            }
        }

        this._serverGroups = [
            { id: 0, name: "Shared", description: "" },
            { id: -this._authService.user.organizationId, name: "Default", description: "" }
        ];

        if (this._authService.isOrgAdmin) {
            const serverGroupResult = await this._serverGroupService.api.getAllAsync();
            if (serverGroupResult.isOk) {
                this._serverGroups = serverGroupResult.value.serverGroups;
                this._serverGroups.splice(0, 0, { id: 0, name: "Shared", description: "" });
                this._serverGroups.splice(0, 0, { id: -this._authService.user.organizationId, name: "Default", description: "" });
            } else {
                this._toaterService.showNetworkError(serverGroupResult.err);
                commands.prevent();
                return;
            }
        }

        const hasAllProxies = await this._configService.api.hasAllProxies(this._selectedIds);
        if (hasAllProxies.isOk) {
            this._hasAllProxies = hasAllProxies.value.hasProxies;
            this._isProxyRequired = this._authService.orgSettings.isProxyRequired && !this._hasAllProxies;
        }
        else {
            this._toaterService.showNetworkError(hasAllProxies.err);
            commands.prevent();
            return;
        }

        const proxyPoolsResults = await this._proxyService.api.getAllProxyPoolsWithSharedAsync(-1, -1, "null", 1);
        if (proxyPoolsResults.isOk) {
            this._proxyPools = proxyPoolsResults.value.proxyPools;

            //sort proxy pools by name asc
            this._proxyPools.sort((a, b) => (a.name > b.name ? 1 : -1));

            if (this._proxyPools.length === 1) this._selectedProxyPoolId = this._proxyPools[0]?.id;
        } else {
            this._toaterService.showNetworkError(proxyPoolsResults.err);
            commands.prevent();
            return;
        }
    }

    async onAfterEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) { }

    private addNewProxyPool() {
        Router.go("/edit/proxy-pool");
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    async RunAsync(ev) {
        ev.preventDefault();

        if (!this._isProxyRequired && (!this._selectedProxyPoolId || this._selectedProxyPoolId == -1) && !this._hasAllProxies) {
            const dialogRes = await this._modalService.openConfirmDialogAsync({ saveCaption: "Run Now", title: "Run Without Proxy?", body: "Are you sure you want to run these agents without setting a proxy? Some agents have no default proxies, so they will run without proxies." })
            if (!dialogRes.isSave) {
                return;
            }
        }

        const groupId = parseInt(this._serverEditor.liveValue);
        const run = {
            configIds: this._selectedIds,
            labelId: this._labelId,
            count: this._count,
            proxyPoolId: this._selectedProxyPoolId,
            serverGroupId: groupId > 0 ? groupId : undefined,
            serverOrganizationId: groupId < 0 ? -groupId : undefined,
            isExclusive: this._excludiveEditor.liveValue,
            isWaitOnFailure: this._waitOnFailureEditor.liveValue,
            logLevel: this._logLevelEditor.liveValue as LogLevel,
        };
        const result = await this._runService.api.createManyAsync(run);
        if (!result.isOk) {
            this._toaterService.showNetworkError(result.err);
        } else {
            //history.back();
            Router.go(this._returnUrl);
        }
    }

    cancel() {
        //history.back();
        Router.go(this._returnUrl);
    }
    selectProxyPoolId() {
        this._selectedProxyPoolId = Number(this._proxyPoolsSelect.liveValue);

        //force ui update
        this.requestUpdate();
    }

    render() {
        // Add none option to proxy pools        
        const proxyPools = this._proxyPools.map((p) => ({ id: p.id, name: p.name }));
        const noneOption = [{ id: -1, name: "None" }];
        const proxyPoolOptions = this._authService.isSE4Admin ? this._isProxyRequired ? proxyPools : noneOption.concat(proxyPools) : proxyPools;

        //get the proxy pool from the proxyPoolOptions based on the id matching the _selectedProxyPoolId
        const selectedProxyPool = this._proxyPools.find(pool => pool.id === this._selectedProxyPoolId);
        const proxyCost = selectedProxyPool != null && selectedProxyPool.price != null ? selectedProxyPool.price.toFixed(2) : 0;

        return html`
            <form id="editorForm" class="editor">
                <div class="h3">Start ${this._count} Agents</div>

                <div style="display: flex;gap:15px">
                    <div class="scroll-container">
                        <div style="display: flex; gap: 10px;flex-direction: column;">
                            <div style="display: flex; gap: 10px;align-items: end;">
                                <se-select-editor
                                    input-width="180px"
                                    id="server"
                                    name="server"
                                    label="Server group"
                                    .options=${this._serverGroups}
                                ></se-select-editor>
                                <se-select-editor
                                    id="logLevel"
                                    name="logLevel"
                                    label="Log level"
                                    .value="${LogLevel.Info}"
                                    .options=${this._logLevels}
                                ></se-select-editor>
                            </div>
                            <div style="display: flex; gap: 10px;align-items: end">
                                <se-select-editor
                                    id="proxyPools"
                                    input-width="180px"
                                    labelAbsolute="true"
                                    name="Proxy Pools"
                                    label="Proxy"
                                    @valueChanged=${this.selectProxyPoolId}
                                    .options=${proxyPoolOptions}
                                    ?required = ${this._isProxyRequired}
                                ></se-select-editor>
                                ${this._authService.isOrgAdmin
                ? html`
                                <se-primary-button
                                    position="first"
                                    .customStyle="${{ width: "max-content" }}"
                                    @click="${this.addNewProxyPool}"
                                    text="Add New Proxy Pool"
                                ></se-primary-button>`: ``}
                            </div>
                            <div class="footnote">Cost: \$${proxyCost} per GB</div>
                            <se-checkbox-editor id="exclusive" ?value=${true} label="Exclusive" labelPosition="right"></se-checkbox-editor>
                            <se-checkbox-editor
                                id="waitOnFailure"
                                ?value=${false}
                                label="Wait on failure"
                                labelPosition="right"
                            ></se-checkbox-editor>
                        </div>
                    </div>
                </div>

                <div class="savePanel">
                    <se-primary-button @click="${this.RunAsync}" text="Run ${this._count} Agents"></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
        .footnote {
            font: var(--font-smaller);
            color: #444;
        }
    `;
}