import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { live } from "lit/directives/live.js";
import "element-internals-polyfill";
import { BaseEditor } from "./base-editor";
import { styleMap } from "lit/directives/style-map.js";

@customElement("se-input-editor")
export class InputEditorElement extends LitElement implements BaseEditor {
    @property() value: string;
    get liveValue() {
        return this._inputElement?.value;
    }

    @property() label?: string;
    @property() labelPosition?: "left" | "top" | "right" | "bottom" = "top";

    @property({ attribute: "input-width" }) inputWidth?: string;
    @property({ type: Number }) step?: number;
    @property({ type: Number }) size?: number;
    @property({ type: Number }) minlength?: number;
    @property({ type: Number }) maxlength?: number;
    @property() min?: string;
    @property() max?: string;
    @property() width?: string;
    @property() pattern?: string;
    @property() placeholder?: string;
    @property({ type: Boolean, attribute: "use-auto-complete" }) useAutoComplete = false;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property() name: string;
    @property({ attribute: "size" }) editorSize: "small" | "normal";
    @property() gap = "0px";
    @property({ attribute: "input-type" }) type:
        | "button"
        | "checkbox"
        | "color"
        | "date"
        | "datetime-local"
        | "email"
        | "file"
        | "hidden"
        | "image"
        | "month"
        | "number"
        | "password"
        | "radio"
        | "range"
        | "reset"
        | "search"
        | "submit"
        | "tel"
        | "text"
        | "time"
        | "url"
        | "week" = "text";

    @query("#input") private _inputElement: HTMLInputElement;

    constructor() {
        super();
    }

    valueChanged() {
        this.dispatchEvent(
            new CustomEvent("valueChanged", {
                bubbles: true,
                composed: true,
                detail: { editor: this, value: this.liveValue, oldValue: this.value },
            })
        );
    }
    updateValue(event) {
        if (this._inputElement.checkValidity()) this._inputElement.classList.remove("invalid");
        else this._inputElement.classList.add("invalid");
        this.dispatchEvent(
            new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this, value: this.liveValue } })
        );
    }
    reportValidity(): boolean {
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        } else {
            this._inputElement.classList.add("invalid");
            return false;
        }
    }

    setCustomValidity(message: string) {
        this._inputElement.setCustomValidity(message);
    }

    firstUpdated() {}

    cancel() {
        this._inputElement.classList.remove("invalid");
        this.requestUpdate();
    }

    render() {
        const flexDirection =
            (this.labelPosition === "left" && "row-reverse") ||
            (this.labelPosition === "top" && "column-reverse") ||
            (this.labelPosition === "right" && "column") ||
            (this.labelPosition === "bottom" && "row");
        const align = this.labelPosition === "left" || this.labelPosition === "right" ? "center" : "start";
        const style = { display: "flex", flexDirection: flexDirection, alignItems: align, gap: this.gap };
        const editorStyle =
            this.editorSize === "small"
                ? { font: "var(--font-ui)", width: this.inputWidth ?? this.width }
                : { font: "var(--font-input)", width: this.inputWidth ?? this.width };
        if (this.editorSize === "normal") {
            return html`
                <div style="${styleMap(style)}">
                    <input
                        style="${styleMap(editorStyle)}"
                        id="input"
                        class="input-normal"
                        name="${this.name}"
                        type="${this.type}"
                        .value="${live(this.value ?? "")}"
                        @input="${this.updateValue}"
                        @change="${this.valueChanged}"
                        min="${ifDefined(this.min)}"
                        max="${ifDefined(this.max)}"
                        maxlength="${ifDefined(this.maxlength)}"
                        minlength="${ifDefined(this.minlength)}"
                        step="${ifDefined(this.step)}"
                        size="${ifDefined(this.size)}"
                        pattern="${ifDefined(this.pattern)}"
                        placeholder="${ifDefined(this.placeholder)}"
                        ?required="${this.required}"
                        ?disabled="${this.disabled}"
                        ?readonly="${this.readonly}"
                        autocomplete=${this.useAutoComplete ? "on" : "off"}
                    />
                    <label for="input">${this.label}</label>
                </div>
            `;
        } else {
            return html`
                <div style="${styleMap(style)}">
                    <input
                        style="${styleMap(editorStyle)}"
                        id="input"
                        name="${this.name}"
                        type="${this.type}"
                        .value="${live(this.value ?? "")}"
                        @input="${this.updateValue}"
                        @change="${this.valueChanged}"
                        min="${ifDefined(this.min)}"
                        max="${ifDefined(this.max)}"
                        maxlength="${ifDefined(this.maxlength)}"
                        minlength="${ifDefined(this.minlength)}"
                        step="${ifDefined(this.step)}"
                        size="${ifDefined(this.size)}"
                        pattern="${ifDefined(this.pattern)}"
                        placeholder="${ifDefined(this.placeholder)}"
                        ?required="${this.required}"
                        ?disabled="${this.disabled}"
                        ?readonly="${this.readonly}"
                        autocomplete=${this.useAutoComplete ? "on" : "off"}
                    />
                    <label for="input">${this.label}</label>
                </div>
            `;
        }
    }

    static styles = css`
        :host {
            display: block;
            width: 100%;
        }
        :host * {
            box-sizing: border-box;
        }
        input {
            font: var(--font-input);
            width: 100%;
        }
        .input-normal {
            font: var(--font-input);
            border-radius: 4px;
            border: 1px solid var(--color-gray-2);
            padding: 9px 13px;
            background: white;
            width: 100%;
        }
        label {
            font: var(--font-input-label);
            margin-bottom: 4px;
        }
        input:disabled + label {
            color: gray;
        }
        input.invalid {
            outline: 2px solid pink;
        }
    `;
}
