import { Config } from "@fortawesome/fontawesome-svg-core";
import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import cronstrue from "cronstrue";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { LogLevel } from "../../enums/log-level";
import { LogMode } from "../../enums/log-mode";
import { ParallelExport } from "../../enums/parallel-export";
import { ScheduleType } from "../../enums/schedule-type";
import { ConfigRunPageModel } from "../../models/config-run-model";
import { ScheduleRunWithProxyViewModel } from "../../models/schedule-run-model";
import { ServerGroup } from "../../models/server-group";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ProxyService } from "../../services/proxy.service";
import { RunService } from "../../services/run.service";
import { UserState } from "../../services/user.state";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { DateEditorElement } from "../editors/date-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { NumberEditorElement } from "../editors/number-editor.element";
import { RadioEditorElement } from "../editors/radio-editor.element";
import { SelectEditorElement } from "../editors/select-editor.element";
import { ParameterEditorElement } from "./parameter-editor.element";

@customElement("se-start-run")
export class SeStartRunElement extends LitElement {
    private _modalService: ModalDialogService;
    private _proxyService: ProxyService;
    private _authService: AuthService;
    private _runService: RunService;
    private _configService: ConfigService;
    private _toaterService: ToasterService;
    private _config: Config;
    private _userState: UserState;
    private _runModel: ConfigRunPageModel;
    private _serverGroups: ServerGroup[];
    //private _parameters: { [name: string]: string };
    private _taskId: number;
    private _configId: number;
    private _isNew = false;
    private _task?: ScheduleRunWithProxyViewModel = null;

    @state() private _isParallelism = false;
    private _selectedParallelMaxConcurrency?: number;
    private _selectedParallelExport = ParallelExport.Combined;
    private _selectedParallelism = 1;
    private _selectedLogLevel = LogLevel.Info;
    private _selectedLogMode = LogMode.Text;
    private _selectedProxyPoolId = -1;
    private _selectedServerGroupId = 0;

    @state() private _isProxyRequired = true;
    @state() private _showscheduleType = false;
    @state() private _runOnceOption = false;
    @state() private _runEveryOption = false;
    @state() private _CRONString = "";
    @state() private _CRONOption = false;
    @state() private _proxyPools: any[] = [];
    @state() private _dateTime: Date;
    @state() private _runEveryPeriodValue = "1";

    @state() private _isLoading = true;

    @query("#yes") private _radioEditor: RadioEditorElement;
    @query("#yes") private _mapEditor: DateEditorElement;
    @query("#server") private _serverEditor: SelectEditorElement;
    @query("#scheduleType") private _scheduleType: SelectEditorElement;
    @query("#task") private _taskEditor: CheckboxEditorElement;
    @query("#scheduleOnOff") private _scheduleOnOff: CheckboxEditorElement;
    @query("#proxyPools") private _proxyPoolsSelect: SelectEditorElement;
    @query("#taskOptions") private _taskOptionsEditor: HTMLDivElement;
    @query("#taskName") private _taskNameEditor: InputEditorElement;
    @query("#parallelSets") private _parallelSetsEditor: NumberEditorElement;
    @query("#concurrency") private _concurrencyEditor: NumberEditorElement;
    @query("#parallelExport") private _parallelExportEditor: SelectEditorElement;
    @query("#isParallelism") private _isParallelismEditor: CheckboxEditorElement;
    @query("#exclusive") private _excludiveEditor: CheckboxEditorElement;
    @query("#waitOnFailure") private _waitOnFailureEditor: CheckboxEditorElement;
    @query("#inputParameters") private _inputParametersEditor: ParameterEditorElement;
    @query("#logLevel") private _logLevelEditor: SelectEditorElement;
    @query("#logMode") private _logModeEditor: SelectEditorElement;

    @query("#StartDate") private _StartDate: DateEditorElement;
    @query("#StartHour") private _StartHour: NumberEditorElement;
    @query("#StartMinutes") private _StartMinutes: NumberEditorElement;
    @query("#StartPeriod") private _StartPeriod: SelectEditorElement;

    @query("#runEveryDate") private _runEveryDate: DateEditorElement;
    @query("#runEveryCount") private _runEveryCount: NumberEditorElement;
    @query("#runEveryPeriod") private _runEveryPeriod: SelectEditorElement;

    @query("#CRONMinute") private _CRONMinute: InputEditorElement;
    @query("#CRONHour") private _CRONHour: InputEditorElement;
    @query("#CRONDay") private _CRONDay: InputEditorElement;
    @query("#CRONMonth") private _CRONMonth: InputEditorElement;
    @query("#CRONWeekDay") private _CRONWeekDay: InputEditorElement;

    private _logLevels = Object.entries(LogLevel).map(([value]) => ({ id: value, name: value }));
    private _logModes = Object.entries(LogMode).map(([key, value]) => ({ id: key, name: value }));
    private _parallelExportOptions = Object.entries(ParallelExport).map(([key, value]) => ({ id: key, name: value }));

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userState = container.resolve(UserState);
        this._configService = container.resolve(ConfigService);
        this._toaterService = container.resolve(ToasterService);
        this._runService = container.resolve(RunService);
        this._proxyService = container.resolve(ProxyService);
        this._modalService = container.resolve(ModalDialogService);
    }

    private __scheduleType?;
    private __runEveryCount?;
    private __runEveryPeriod?;
    private __localSchedule?;
    private __startTime?: Date;
    private __taskName?;
    private __today? = this.addMinutes(new Date(), 5);
    private __timePMAM?;

    private addMinutes(date: Date, minutes: number) {
        date.setMinutes(date.getMinutes() + minutes);
        return date;
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isUser) {
            return commands.redirect("/login");
        }

        if (location.params.taskId) {
            this._taskId = parseInt(location.params.taskId.valueOf() as string);
            this._isNew = false;
        } else if (location.params.configId) {
            this._isNew = true;
            this._configId = parseInt(location.params.configId.valueOf() as string);
        }
        commands.prevent();
    }
    private addNewProxyPool() {
        Router.go("/edit/proxy-pool");
    }
    connectedCallback() {
        super.connectedCallback();

        this.initializeAsync();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private async initializeAsync() {
        try {
            this.__timePMAM = this.gettimePMAM(this.__today);

            if (this._taskId) {
                const res = await this._runService.api.getTaskAsync(this._taskId);
                if (res.isOk) {
                    this._task = res.value;
                    this._configId = this._task.configId;

                    if (!this._isNew) {
                        this.__scheduleType = this._task.scheduleType;
                        this.__runEveryCount = this._task.runEveryCount;
                        this.__runEveryPeriod = this._task.runEveryPeriod;
                        this.__localSchedule = this._task.localSchedule?.split(" ");
                        this.__startTime =
                            this._task.scheduleType === ScheduleType.none ? this.addMinutes(new Date(), 5) : new Date(this._task.startTime);
                        this.__taskName = this._task.taskName;
                        this.__today = this.__startTime;
                        this.__timePMAM = String(this.__today.getHours() >= 12 ? (this.__timePMAM = "12") : (this.__timePMAM = "0"));
                        setTimeout(() => {
                            this.scheduleChangeEdit(this.__scheduleType);
                        }, 0);

                        this._isProxyRequired = this._authService.orgSettings.isProxyRequired && !this._task.configProxyPoolId;
                    }
                } else {
                    this._toaterService.showNetworkError(res.err);
                }
            }
            const result = await this._configService.api.getRunPageModelAsync(this._configId);
            if (result.isOk) {
                this._runModel = result.value;
                this._serverGroups = this._runModel.serverGroups.serverGroups;
                this._serverGroups.splice(0, 0, { id: 0, name: "Shared", description: "" });
                this._serverGroups.splice(0, 0, { id: -this._authService.user.organizationId, name: "Default", description: "" });

                //Compare agent input params with dynamic params that are saved in task table.
                if (this._task?.parameters) {
                    const json = this._task.parameters; // JSON.parse(this._task.parameters);

                    for (const key in this._runModel.inputParameters) {
                        if (json.hasOwnProperty(key)) {
                            this._runModel.inputParameters[key] = json[key];
                        }
                    }
                } else if (this._runModel.lastRunSettings?.parameters) {
                    const json = JSON.parse(this._runModel.lastRunSettings.parameters);

                    for (const key in this._runModel.inputParameters) {
                        if (json.hasOwnProperty(key)) {
                            this._runModel.inputParameters[key] = json[key];
                        }
                    }
                }
                //this._parameters = { ...this._runModel.inputParameters };
                this._isProxyRequired = this._authService.orgSettings.isProxyRequired;
            } else {
                this._toaterService.showNetworkError(result.err);
            }

            // get the list of proxy pools
            const proxyPoolsResults = await this._proxyService.api.getAllProxyPoolsWithSharedAsync(-1, -1, "null", 1);
            if (proxyPoolsResults.isOk) {
                this._proxyPools = proxyPoolsResults.value.proxyPools;

                //sort proxy pools by name asc
                this._proxyPools.sort((a, b) => (a.name > b.name ? 1 : -1));

                if (this._task?.proxyPoolId && this._proxyPools.some((p) => p.id === this._task.proxyPoolId)) {
                    //default to the proxy pool from the saved task
                    this._selectedProxyPoolId = this._task.proxyPoolId;
                } else if (this._runModel?.proxyPoolId && this._proxyPools.some((p) => p.id === this._runModel.proxyPoolId)) {
                    //default to the proxy pool from the config
                    this._selectedProxyPoolId = this._runModel.proxyPoolId;
                } else if (
                    this._runModel?.lastRunSettings?.proxyPoolId &&
                    this._proxyPools.some((p) => p.id === this._runModel.lastRunSettings?.proxyPoolId)
                ) {
                    // default to the last used proxy ppol
                    this._selectedProxyPoolId = this._runModel.lastRunSettings?.proxyPoolId;
                } else if (this._isProxyRequired && this._proxyPools.length > 0) {
                    //we must have a proxy pool so there is not a None option. Default the
                    //selected id to the id of the ifrst item in the list
                    this._selectedProxyPoolId = this._proxyPools[0].id;
                } else {
                    //default to none
                    this._selectedProxyPoolId = -1;
                }
            } else {
                this._toaterService.showUnexpectedError(proxyPoolsResults.err.message);
                return;
            }

            if (this._task) {
                if (this._task.logLevel) this._selectedLogLevel = this._task.logLevel;
                if (this._task.logMode) this._selectedLogMode = this._task.logMode;
                if (this._task.parallelism) this._selectedParallelism = this._task.parallelism;
                if (this._task.parallelMaxConcurrency) this._selectedParallelMaxConcurrency = this._task.parallelMaxConcurrency;
                if (this._task.parallelExport) this._selectedParallelExport = this._task.parallelExport;
                this._selectedServerGroupId = this._task.serverGroupId;
            } else {
                if (this._runModel.lastRunSettings?.logLevel) this._selectedLogLevel = this._runModel.lastRunSettings?.logLevel;
                if (this._runModel.lastRunSettings?.logMode) this._selectedLogMode = this._runModel.lastRunSettings?.logMode;
                if (this._runModel.lastRunSettings?.parallelism) this._selectedParallelism = this._runModel.lastRunSettings?.parallelism;
                if (this._runModel.lastRunSettings?.parallelMaxConcurrency)
                    this._selectedParallelMaxConcurrency = this._runModel.lastRunSettings?.parallelMaxConcurrency;
                if (this._runModel.lastRunSettings?.parallelExport)
                    this._selectedParallelExport = this._runModel.lastRunSettings?.parallelExport;
                this._selectedServerGroupId = -this._authService.user.organizationId;
            }
            this._isParallelism = this._selectedParallelism > 1;
        } finally {
            this._isLoading = false;
        }
    }

    scheduleUpdated() {
        if (this._taskEditor.liveValue) {
            this._showscheduleType = true;
        } else {
            this._showscheduleType = false;
        }
    }

    reportValidity(): boolean {
        if (this._showscheduleType) {
            if (!this._taskNameEditor.reportValidity()) return false;
        }
        if (this._inputParametersEditor) {
            if (!this._inputParametersEditor.reportValidity()) return false;
        }
        if (this._isParallelism) {
            if (!this._parallelSetsEditor.reportValidity()) return false;
            if (!this._concurrencyEditor.reportValidity()) return false;
        }

        return true;
    }

    async getScheduleDate(): Promise<any> {
        let result;
        let localschedule;
        let runEveryData;
        if (this._showscheduleType == false) {
            const modalResult = await this._modalService.openConfirmDialogAsync({
                title: "No Schedule",
                body: `Are you sure you want to save the task without scheduling?`,
                saveCaption: "Save",
            });
            if (modalResult.isSave) {
                return { cron: "", startDateTime: null };
            }
            return;
        } else {
            const dateTime = this._StartDate.liveValue;

            if (dateTime == null) return this._toaterService.showUnexpectedError("Please pick a date and fill the necessary information.");
            if (Number(this._StartMinutes.liveValue) > 60) return this._toaterService.showUnexpectedError("Minutes cannot go above 60.");
            if (Number(this._StartHour.liveValue) > 12) return this._toaterService.showUnexpectedError("Hours cannot go above 12.");
            let hours =
                Number(this._StartHour.liveValue) == 12
                    ? Number(Number(this._StartHour.liveValue) + Number(this._StartPeriod.liveValue)) - 12
                    : Number(Number(this._StartHour.liveValue) + Number(this._StartPeriod.liveValue));
            hours = hours == 24 ? 0 : hours;
            dateTime.setHours(hours, this._StartMinutes.liveValue);
            const currentDate = new Date();
            if (currentDate.getTime() > dateTime.getTime()) {
                return this._toaterService.showUnexpectedError("Please pick a date in the future.");
            }

            const UTCDate = new Date(dateTime.getTime() + dateTime.getTimezoneOffset() * 60 * 1000);
            if (this._scheduleType.liveValue == "1") {
                result = this.toCron(UTCDate.getMinutes(), UTCDate.getHours(), UTCDate.getDate(), UTCDate.getMonth() + 1, UTCDate.getDay());
                localschedule = this.toCron(
                    dateTime.getMinutes(),
                    dateTime.getHours(),
                    dateTime.getDate(),
                    dateTime.getMonth() + 1,
                    dateTime.getDay()
                );
            } else if (this._scheduleType.liveValue == "2") {
                runEveryData = { runEveryCount: this._runEveryCount.liveValue, runEveryPeriod: this._runEveryPeriod.liveValue };

                const obj = {
                    "1": [`*/${this._runEveryCount.liveValue}`, "*", "*", "*", "*"],
                    "2": [`${UTCDate.getMinutes()}`, `*/${this._runEveryCount.liveValue}`, "*", "*", "*"],
                    "3": [`${UTCDate.getMinutes()}`, `${UTCDate.getHours()}`, `*/${this._runEveryCount.liveValue}`, "*", "*"],
                    "4": [`${UTCDate.getMinutes()}`, `${UTCDate.getHours()}`, `*/${7 * this._runEveryCount.liveValue}`, "*", "*"],
                    "5": [`${UTCDate.getMinutes()}`, `${UTCDate.getHours()}`, "*", `*/${this._runEveryCount.liveValue}`, "*"],
                };
                const localObj = {
                    "1": [`*/${this._runEveryCount.liveValue}`, "*", "*", "*", "*"],
                    "2": [`${dateTime.getMinutes()}`, `*/${this._runEveryCount.liveValue}`, "*", "*", "*"],
                    "3": [`${dateTime.getMinutes()}`, `${dateTime.getHours()}`, `*/${this._runEveryCount.liveValue}`, "*", "*"],
                    "4": [`${dateTime.getMinutes()}`, `${dateTime.getHours()}`, `*/${7 * this._runEveryCount.liveValue}`, "*", "*"],
                    "5": [`${dateTime.getMinutes()}`, `${dateTime.getHours()}`, "*", `*/${this._runEveryCount.liveValue}`, "*"],
                };
                result = this.toCron(
                    obj[this._runEveryPeriod.liveValue][0],
                    obj[this._runEveryPeriod.liveValue][1],
                    obj[this._runEveryPeriod.liveValue][2],
                    obj[this._runEveryPeriod.liveValue][3],
                    obj[this._runEveryPeriod.liveValue][4]
                );
                localschedule = this.toCron(
                    localObj[this._runEveryPeriod.liveValue][0],
                    localObj[this._runEveryPeriod.liveValue][1],
                    localObj[this._runEveryPeriod.liveValue][2],
                    localObj[this._runEveryPeriod.liveValue][3],
                    localObj[this._runEveryPeriod.liveValue][4]
                );
            } else if (this._scheduleType.liveValue == "3") {
                //this part will take the date from the CRON fields and turn it into UTC
                //01/01/2022 00:02 UTC+1 ===> 12/31/2021 23:02 UTC
                //and turn it back into cron that will be saved in the database

                const CRONDate = new Date();

                const minutes = /^\d+$/.test(this._CRONMinute.liveValue) ? parseInt(this._CRONMinute.liveValue) : NaN;
                if (!isNaN(minutes)) {
                    CRONDate.setMinutes(minutes);
                }

                const hours = /^\d+$/.test(this._CRONHour.liveValue) ? parseInt(this._CRONHour.liveValue) : NaN;
                if (!isNaN(hours)) {
                    CRONDate.setHours(hours);
                }

                const dayOfMonth = /^\d+$/.test(this._CRONDay.liveValue) ? parseInt(this._CRONDay.liveValue) : NaN;
                if (!isNaN(dayOfMonth)) {
                    CRONDate.setDate(dayOfMonth);
                }

                const month = /^\d+$/.test(this._CRONMonth.liveValue) ? parseInt(this._CRONMonth.liveValue) : NaN;
                if (!isNaN(month)) {
                    CRONDate.setMonth(month - 1);
                }

                const CRONUTCDate = new Date(CRONDate.getTime() + CRONDate.getTimezoneOffset() * 60 * 1000);
                /////
                let dayOfWeek = /^\d+$/.test(this._CRONWeekDay.liveValue) ? parseInt(this._CRONWeekDay.liveValue) : NaN;
                let strDayOfWeek = this._CRONWeekDay.liveValue;
                if (isNaN(dayOfWeek) && this._CRONWeekDay.liveValue.includes(",")) {
                    strDayOfWeek = this._CRONWeekDay.liveValue
                        .split(",")
                        .map((num) => {
                            if (CRONUTCDate.getDay() > CRONDate.getDay()) {
                                return parseInt(num.trim()) + 1;
                            } else if (CRONUTCDate.getDay() < CRONDate.getDay()) {
                                return parseInt(num.trim()) - 1;
                            }
                        })
                        .join(",");
                } else {
                    if (CRONUTCDate.getDay() > CRONDate.getDay()) {
                        dayOfWeek++;
                    } else if (CRONUTCDate.getDay() < CRONDate.getDay()) {
                        dayOfWeek--;
                    }
                }

                result = this.toCron(
                    !isNaN(minutes) ? CRONUTCDate.getMinutes() : this._CRONMinute.liveValue,
                    !isNaN(hours) ? CRONUTCDate.getHours() : this._CRONHour.liveValue,
                    !isNaN(dayOfMonth) ? CRONUTCDate.getDate() : this._CRONDay.liveValue,
                    !isNaN(month) ? CRONUTCDate.getMonth() + 1 : this._CRONMonth.liveValue,
                    !isNaN(dayOfWeek) ? dayOfWeek : strDayOfWeek
                );
                localschedule = this.toCron(
                    this._CRONMinute.liveValue,
                    this._CRONHour.liveValue,
                    this._CRONDay.liveValue,
                    this._CRONMonth.liveValue,
                    this._CRONWeekDay.liveValue
                );

                console.log(cronstrue.toString(result));
            }
            return { cron: result, startDateTime: dateTime, localSchedule: localschedule, runEveryData };
        }
    }

    toCron(mins, hrs, dayOfMonth, month, dayOfWeek) {
        return `${mins} ${hrs} ${dayOfMonth} ${month} ${dayOfWeek}`;
    }

    async saveTaskAsync() {
        if (this.reportValidity()) {
            const dateRes = await this.getScheduleDate();
            if (dateRes == undefined) return;

            if (
                !this._isProxyRequired &&
                (!this._selectedProxyPoolId || this._selectedProxyPoolId === -1) &&
                ((!this._isNew && !this._task.configProxyPoolId) || (this._isNew && !this._runModel.proxyPoolId))
            ) {
                const dialogRes = await this._modalService.openConfirmDialogAsync({
                    title: "Save Task Without Proxy?",
                    body: "Are you sure you want to save this task without setting a proxy? The agent has no default proxies, so it will run without proxies.",
                });
                if (!dialogRes.isSave) {
                    return;
                }
            }

            this._inputParametersEditor?.save();

            const groupId = parseInt(this._serverEditor.liveValue);
            const runEveryPeriod = dateRes?.runEveryData ? dateRes.runEveryData.runEveryPeriod : null;
            const runEveryCount = dateRes?.runEveryData ? dateRes.runEveryData.runEveryCount : null;
            const serverGroupId = groupId > 0 ? groupId : undefined;
            const serverOrganizationId = groupId < 0 ? -groupId : undefined;

            const task = {
                runEveryPeriod: runEveryPeriod,
                runEveryCount: runEveryCount,
                schedule: dateRes.cron,
                scheduleType: this._scheduleOnOff.liveValue === false ? 0 : Number(this._scheduleType.liveValue),
                localSchedule: dateRes.localSchedule,
                startTime: dateRes.startDateTime,
                configId: this._runModel.id,
                proxyPoolId: this._runModel.proxyPoolId !== this._selectedProxyPoolId ? this._selectedProxyPoolId : -1,
                parallelism: this._isParallelism ? this._parallelSetsEditor.liveValue ?? 2 : 1,
                parallelExport: this._isParallelism ? (this._parallelExportEditor.liveValue as ParallelExport) : undefined,
                parallelMaxConcurrency: this._isParallelism ? this._concurrencyEditor.liveValue : undefined,
                serverGroupId: serverGroupId,
                serverOrganizationId: serverOrganizationId,
                parameters: JSON.stringify(this._inputParametersEditor?.properties ?? {}),
                isSaveAsTask: true,
                taskName: this._taskNameEditor.liveValue,
                isExclusive: this._excludiveEditor.liveValue,
                isWaitOnFailure: this._waitOnFailureEditor.liveValue,
                logLevel: this._logLevelEditor.liveValue as LogLevel,
                logMode: this._logModeEditor.liveValue as LogMode,
            };

            const result = this._isNew
                ? await this._runService.api.scheduleAsync(task)
                : await this._runService.api.updateTaskAsync(this._taskId, task);
            if (!result.isOk) {
                this._toaterService.showNetworkError(result.err);
            } else {
                //history.back();
                Router.go(`/space/${this._userState.selectedSpaceId}/config/${this._runModel.id}/details/tasks`);
            }
        }
    }

    /*async updateInputParams() {
        //convert array input params liveValues to object with ket values.
        const convertedObject = {};

        if (this._inputParametersEditor) {
            for (const item of this._inputParametersEditor.liveValue) {
                convertedObject[item.name] = item.value;
            }
            //compare and updated this._parameters data with updated input params live values.
            for (const key in this._parameters) {
                if (convertedObject.hasOwnProperty(key)) {
                    this._parameters[key] = convertedObject[key];
                }
            }
        }
    }*/

    async RunAsync() {
        if (this.reportValidity()) {
            if (!this._isProxyRequired && (!this._selectedProxyPoolId || this._selectedProxyPoolId === -1) && !this._runModel.proxyPoolId) {
                const dialogRes = await this._modalService.openConfirmDialogAsync({
                    saveCaption: "Run Now",
                    title: "Run Without Proxy?",
                    body: "Are you sure you want to run this agent without setting a proxy? The agent has no default proxies, so it will run without proxies.",
                });
                if (!dialogRes.isSave) {
                    return;
                }
            }

            this._inputParametersEditor?.save();
            const groupId = parseInt(this._serverEditor.liveValue);
            const run = {
                configId: this._runModel.id,
                proxyPoolId: this._runModel.proxyPoolId !== this._selectedProxyPoolId ? this._selectedProxyPoolId : -1,
                parallelism: this._isParallelism ? this._parallelSetsEditor.liveValue ?? 2 : 1,
                ParallelExport: this._isParallelism ? (this._parallelExportEditor.liveValue as ParallelExport) : undefined,
                ParallelMaxConcurrency: this._isParallelism ? this._concurrencyEditor.liveValue : undefined,
                serverGroupId: groupId > 0 ? groupId : undefined,
                serverOrganizationId: groupId < 0 ? -groupId : undefined,
                parameters: JSON.stringify(this._inputParametersEditor?.properties ?? {}),
                isSaveAsTask: false,
                taskName: this._taskNameEditor.liveValue,
                isExclusive: this._excludiveEditor.liveValue,
                isWaitOnFailure: this._waitOnFailureEditor.liveValue,
                logLevel: this._logLevelEditor.liveValue as LogLevel,
                logMode: this._logModeEditor.liveValue as LogMode,
            };
            const result = await this._runService.api.createAsync(run);
            if (!result.isOk) {
                this._toaterService.showNetworkError(result.err);
            } else {
                //history.back();
                Router.go(`/space/${this._userState.selectedSpaceId}/config/${this._runModel.id}/details/runs`);
            }
        }
    }

    cancel() {
        history.back();
    }
    selectProxyPoolId() {
        this._selectedProxyPoolId = Number(this._proxyPoolsSelect.liveValue);

        //force ui update
        this.requestUpdate();
    }
    updateRunEveryPeriod() {
        this._runEveryPeriodValue = this._runEveryPeriod.liveValue;
    }
    cronUpdated() {
        try {
            this._CRONString = cronstrue.toString(
                this.toCron(
                    this._CRONMinute.liveValue,
                    this._CRONHour.liveValue,
                    this._CRONDay.liveValue,
                    this._CRONMonth.liveValue,
                    this._CRONWeekDay.liveValue
                )
            );
        } catch {
            this._CRONString = "Invalid CRON String";
        }
    }

    scheduleChange() {
        if (this._scheduleOnOff.liveValue == false) {
            this._showscheduleType = false;
            this._runOnceOption = false;
            this._runEveryOption = false;
            this._CRONOption = false;
        } else {
            if (this._scheduleType.liveValue === "1") {
                this._showscheduleType = true;
                this._runOnceOption = true;
                this._runEveryOption = false;
                this._CRONOption = false;
            } else if (this._scheduleType.liveValue === "2") {
                this._showscheduleType = true;
                this._runOnceOption = false;
                this._runEveryOption = true;
                this._CRONOption = false;
            } else if (this._scheduleType.liveValue === "3") {
                this._showscheduleType = true;
                this._runOnceOption = false;
                this._runEveryOption = false;
                this._CRONOption = true;
            }
        }
    }

    scheduleChangeEdit(scheduleType) {
        if (scheduleType == 0) {
            this._showscheduleType = false;
            this._runOnceOption = false;
            this._runEveryOption = false;
            this._CRONOption = false;
        } else {
            if (scheduleType == 1) {
                this._showscheduleType = true;
                this._runOnceOption = true;
                this._runEveryOption = false;
                this._CRONOption = false;
            } else if (scheduleType == 2) {
                this._showscheduleType = true;
                this._runOnceOption = false;
                this._runEveryOption = true;
                this._CRONOption = false;
            } else if (scheduleType == 3) {
                this._showscheduleType = true;
                this._runOnceOption = false;
                this._runEveryOption = false;
                this._CRONOption = true;
            }
        }
    }
    get12HoursFormat(today) {
        return String(today.getHours() > 12 ? today.getHours() - 12 : today.getHours()).padStart(2, "0");
    }
    getMinsFormat(today) {
        return String(today.getMinutes()).padStart(2, "0");
    }
    gettimePMAM(today) {
        return String(today.getHours() >= 12 ? "12" : "0");
    }

    private parallelismChanged() {
        this._isParallelism = this._isParallelismEditor.liveValue;
        if (this._isParallelism && (this._parallelSetsEditor.value ?? 1) < 2) {
            this._parallelSetsEditor.value = 2;
        }
    }

    render() {
        const runOnceOptionsStyle = { display: this._runOnceOption ? "flex" : "none", gap: "10px", "flex-direction": "column" };
        const runEveryOptionsStyle = { display: this._runEveryOption ? "flex" : "none", gap: "10px", "flex-direction": "column" };
        const CRONOptionsStyle = { display: this._CRONOption ? "flex" : "none", gap: "10px", "flex-direction": "column" };

        const scheduleOptionsStyle = { display: this._showscheduleType ? "flex" : "none", gap: "10px" };
        const startDateOptionsStyle = {
            display: this._showscheduleType ? "flex" : "none",
            gap: "10px",
            "flex-direction": "column",
            width: "290px",
        };

        // Add none option to proxy pools
        const proxyPools = this._proxyPools.map((p) => ({ id: p.id, name: p.name }));
        const noneOption = [{ id: -1, name: "None" }];
        const proxyPoolOptions = this._authService.isSE4Admin ? this._isProxyRequired ? proxyPools : noneOption.concat(proxyPools) : proxyPools;
        const taskName =
            this._task?.taskName ??
            this._runModel?.name +
                " - " +
                new Date()
                    .toLocaleString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "numeric", minute: "numeric" })
                    .replace(",", "");


        //get the proxy pool from the proxyPoolOptions based on the id matching the _selectedProxyPoolId
        const selectedProxyPool = this._proxyPools.find(pool => pool.id === this._selectedProxyPoolId);
        const proxyCost = selectedProxyPool != null && selectedProxyPool.price != null ? selectedProxyPool.price.toFixed(2) : 0;

        return html`
            <form id="editorForm" class="editor">
                ${this._isLoading
                    ? html`<div class="loading-container">
                          <div style="margin: auto"
                              ><fa-icon style="font-size:0.9em" fa-class="far fa-spinner fa-spin"></fa-icon>&nbsp;&nbsp;Loading...</span
                          >
                      </div>`
                    : html` <div class="h3">Setup ${this._runModel.name}</div>

                          <div style="display: flex;gap:15px;min-height:0">
                              <div class="scroll-container">
                                  <div style="display: flex; gap: 13px;flex-direction: column;">
                                      <div style="display: flex; gap: 10px;align-items: end;">
                                          <se-select-editor
                                              id="proxyPools"
                                              input-width="180px"
                                              labelAbsolute="true"
                                              name="Proxy Pools"
                                              label="Proxy"
                                              @valueChanged=${this.selectProxyPoolId}
                                              .options=${proxyPoolOptions}
                                              .value=${this._selectedProxyPoolId.toString()}
                                              ?required=${this._isProxyRequired}
                                          ></se-select-editor>
                                          ${this._authService.isOrgAdmin
                                        ? html`
                                          <se-primary-button
                                              .customStyle="${{ width: "max-content" }}"
                                              position="first"
                                              @click="${this.addNewProxyPool}"
                                              text="Add New Proxy Pool"
                                              width="70px"
                                          ></se-primary-button>`:``}

                                      </div>
                                      <div class="footnote">Cost: \$${proxyCost} per GB</div>
                                      <div style="display: flex; gap: 10px;align-items: end;">
                                          <se-select-editor
                                              id="logMode"
                                              name="logMode"
                                              label="Log mode"
                                              .value="${this._selectedLogMode}"
                                              .options=${this._logModes}
                                          ></se-select-editor>
                                          <se-select-editor
                                              id="logLevel"
                                              name="logLevel"
                                              label="Log level"
                                              .value="${this._selectedLogLevel}"
                                              .options=${this._logLevels}
                                          ></se-select-editor>
                                          <se-select-editor
                                              input-width="107px"
                                              id="server"
                                              name="server"
                                              label="Server group"
                                              .value="${this._selectedServerGroupId}"
                                              .options=${this._serverGroups}
                                          ></se-select-editor>
                                      </div>
                                      <se-checkbox-editor
                                          id="isParallelism"
                                          ?value=${this._isParallelism}
                                          label="Split up inputs and process in parallel"
                                          labelPosition="right"
                                          @valueChanged=${this.parallelismChanged}
                                      ></se-checkbox-editor>
                                      <div
                                          style=${styleMap({
                                              display: this._isParallelism ? "flex" : "None",
                                              gap: "10px",
                                              "align-items": "start",
                                          })}
                                      >
                                          <se-number-editor
                                              id="parallelSets"
                                              .value="${this._selectedParallelism}"
                                              min="2"
                                              label="Total runs"
                                              labelPosition="top"
                                              input-type="number"
                                              size="5"
                                              input-width="100px"
                                              placeholder="2"
                                          ></se-number-editor>
                                          <se-number-editor
                                              id="concurrency"
                                              .value="${this._selectedParallelMaxConcurrency}"
                                              min="1"
                                              label="Concurrency"
                                              labelPosition="top"
                                              input-type="number"
                                              size="5"
                                              input-width="100px"
                                              placeholder="Unlimited"
                                          ></se-number-editor>
                                          <se-select-editor
                                              id="parallelExport"
                                              label="Export"
                                              .value="${this._selectedParallelExport}"
                                              .options=${this._parallelExportOptions}
                                          ></se-select-editor>
                                      </div>
                                      <se-checkbox-editor
                                          id="exclusive"
                                          ?value=${true}
                                          label="Exclusive"
                                          labelPosition="right"
                                      ></se-checkbox-editor>
                                      <se-checkbox-editor
                                          id="waitOnFailure"
                                          ?value=${false}
                                          label="Wait on failure"
                                          labelPosition="right"
                                      ></se-checkbox-editor>
                                  </div>
                                  ${when(
                                      Object.keys(this._runModel.inputParameters).length > 0,
                                      () =>
                                          html`<se-parameter-editor
                                              id="inputParameters"
                                              .properties=${this._runModel.inputParameters}
                                              label="Input parameters"
                                          ></se-parameter-editor>`
                                  )}
                              </div>
                              <div style="display: flex;flex-direction: column;row-gap: 10px;min-width:350px">
                                  <div class="scroll-container">
                                      <se-input-editor
                                          id="taskName"
                                          type="text"
                                          label="Task name"
                                          labelPosition="top"
                                          input-type="text"
                                          required
                                          size="30"
                                          .value=${!this._isNew ? this.__taskName : taskName}
                                      ></se-input-editor>

                                      <se-checkbox-editor
                                          id="scheduleOnOff"
                                          width="290px"
                                          .value="${!this._isNew ? (this.__scheduleType === 0 ? false : true) : false}"
                                          name="Schedule"
                                          label="Schedule"
                                          labelPosition="right"
                                          @valueChanged=${this.scheduleChange}
                                      ></se-checkbox-editor>

                                      <div style=${styleMap(startDateOptionsStyle)}>
                                          <div style="display: flex; gap: 10px; align-items:end">
                                              <se-date-editor
                                                  id="StartDate"
                                                  .min=${new Date().toLocaleDateString()}
                                                  .value=${this.__today}
                                                  min="1"
                                                  label="Start Date & Time"
                                                  labelPosition="top"
                                              ></se-date-editor>
                                              <div style="display: flex; gap: 2px; align-items:end">
                                                  <se-input-editor
                                                      .value=${this.get12HoursFormat(this.__today)}
                                                      id="StartHour"
                                                      value="01"
                                                      min="0"
                                                      max="12"
                                                      label=""
                                                      labelPosition="top"
                                                      size="2"
                                                      style="width:fit-content"
                                                  ></se-input-editor
                                                  ><span style="align-self:center">:</span>
                                                  <se-input-editor
                                                      .value=${this.getMinsFormat(this.__today)}
                                                      id="StartMinutes"
                                                      value="00"
                                                      min="0"
                                                      max="60"
                                                      label=""
                                                      labelPosition="top"
                                                      size="2"
                                                      style="width:fit-content"
                                                  ></se-input-editor>
                                                  <se-select-editor
                                                      .value=${this.__timePMAM}
                                                      id="StartPeriod"
                                                      style="width:fit-content;margin-left:3px"
                                                      label=""
                                                      labelPosition="top"
                                                      .options=${[
                                                          { id: 12, name: "PM" },
                                                          { id: 0, name: "AM" },
                                                      ]}
                                                  >
                                                  </se-select-editor>
                                              </div>
                                          </div>
                                      </div>

                                      <div style=${styleMap(scheduleOptionsStyle)}>
                                          <se-select-editor
                                              .value="${!this._isNew ? String(this.__scheduleType) : "1"}"
                                              id="scheduleType"
                                              width="290px"
                                              name="Schedule"
                                              label="Schedule Type"
                                              labelPosition="top"
                                              @valueChanged=${this.scheduleChange}
                                              .options=${[
                                                  { id: 1, name: "Run Once" },
                                                  { id: 2, name: "Run Every" },
                                                  { id: 3, name: "CRON" },
                                              ]}
                                          >
                                          </se-select-editor>
                                      </div>
                                      <div style=${styleMap(scheduleOptionsStyle)}>
                                          <div id="taskOptions" style=${styleMap(runOnceOptionsStyle)}></div>

                                          <div id="taskOptions" style=${styleMap(runEveryOptionsStyle)}>
                                              <div style="display: flex; gap: 10px;flex-direction: column;">
                                                  <span style="display: flex; gap: 10px;align-items:end">
                                                      <se-number-editor
                                                          id="runEveryCount"
                                                          .value="${!this._isNew ? this.__runEveryCount : 1}"
                                                          min="1"
                                                          label=""
                                                          labelPosition="top"
                                                          input-type="number"
                                                          size="2"
                                                          input-width="60px"
                                                      ></se-number-editor>
                                                      <se-select-editor
                                                          id="runEveryPeriod"
                                                          .value="${!this._isNew ? String(this.__runEveryPeriod) : "1"}"
                                                          width="90px"
                                                          label=""
                                                          labelPosition="top"
                                                          @valueChanged=${this.updateRunEveryPeriod}
                                                          .options=${[
                                                              { id: 1, name: "minutes" },
                                                              { id: 2, name: "hours" },
                                                              { id: 3, name: "days" },
                                                              { id: 4, name: "weeks" },
                                                              { id: 5, name: "months" },
                                                          ]}
                                                      >
                                                      </se-select-editor>
                                                  </span>
                                              </div>
                                          </div>

                                          <div id="taskOptions" style=${styleMap(CRONOptionsStyle)}>
                                              <div style="display: flex; gap: 10px;">
                                                  <se-input-editor
                                                      @valueChanged=${this.cronUpdated}
                                                      .value="${!this._isNew && this._task.scheduleType == 3 ? this.__localSchedule[0] : 1}"
                                                      id="CRONMinute"
                                                      value="1"
                                                      label="Minute"
                                                      labelPosition="top"
                                                      input-type="text"
                                                      size="5"
                                                      input-width="75px"
                                                  ></se-input-editor>
                                                  <se-input-editor
                                                      @valueChanged=${this.cronUpdated}
                                                      .value="${!this._isNew && this._task.scheduleType == 3 ? this.__localSchedule[1] : 1}"
                                                      id="CRONHour"
                                                      value="1"
                                                      label="Hour"
                                                      labelPosition="top"
                                                      input-type="text"
                                                      size="5"
                                                      input-width="75px"
                                                  ></se-input-editor>
                                                  <se-input-editor
                                                      @valueChanged=${this.cronUpdated}
                                                      .value="${!this._isNew && this._task.scheduleType == 3 ? this.__localSchedule[2] : 1}"
                                                      id="CRONDay"
                                                      value="1"
                                                      label="Month&nbsp;Day"
                                                      labelPosition="top"
                                                      input-type="text"
                                                      size="5"
                                                      input-width="75px"
                                                  ></se-input-editor>
                                                  <se-input-editor
                                                      @valueChanged=${this.cronUpdated}
                                                      .value="${!this._isNew && this._task.scheduleType == 3 ? this.__localSchedule[3] : 1}"
                                                      id="CRONMonth"
                                                      value="1"
                                                      label="Month"
                                                      labelPosition="top"
                                                      input-type="text"
                                                      size="5"
                                                      input-width="75px"
                                                  ></se-input-editor>
                                                  <se-input-editor
                                                      @valueChanged=${this.cronUpdated}
                                                      .value="${!this._isNew && this._task.scheduleType == 3 ? this.__localSchedule[4] : 1}"
                                                      id="CRONWeekDay"
                                                      value="1"
                                                      label="Week&nbsp;Day"
                                                      labelPosition="top"
                                                      input-type="text"
                                                      size="5"
                                                      input-width="75px"
                                                  ></se-input-editor>
                                              </div>
                                              <div class="h3">${this._CRONString}</div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="savePanel">
                              <se-primary-button .action="${() => this.saveTaskAsync()}" text="Save Task"></se-primary-button>
                              <se-secondary-button .action="${() => this.RunAsync()}" text="Run Now"></se-secondary-button>
                              <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                          </div>`}
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .loading-container {
            min-width: 400px;
            min-height: 100px;
            background-color: white;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            align-items: center;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
        .footnote {
            font: var(--font-smaller);
            color: #444;
        }
    `;
}
