import { container, inject,singleton } from 'tsyringe';
import { ServersAdminViewModel } from '../models/servers-admin-view-model';
import { ServersOrgViewModel } from '../models/servers-org-view-model';
import { ServerViewModel } from '../models/server-view-model';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { ServerType } from '../enums/server-type';
import { AgentEditorModel } from '../models/agent-editor-model';


@singleton()
export class ServerApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<ServerViewModel>(`/api/server/${id}`, this._authService.token);
    }
    async getAllOrganizationServersAsync(pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1, ServerType: ServerType = -1) {
        return this.getObjectAsync<ServersOrgViewModel>(`/api/server/organization?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&type=${ServerType}`, this._authService.token);
    }
    async getAllAdminServersAsync(pageIndex = 1, recordsPerPage = 100, sortColumn: string = '', sortOrder: number = -1, ServerType: ServerType = -1) {
        return this.getObjectAsync<ServersAdminViewModel>(`/api/server?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&type=${ServerType}`, this._authService.token);
    }
    async createAsync(server: ServerViewModel) {
        return this.postObjectReturnObjectAsync<{id: number}>(`/api/server`, server, this._authService.token);
    }

    async adminUpdateAsync(serverId: number, server: ServerViewModel) {
        return this.putObjectReturnObjectAsync<{ id: number }>(`/api/server/${serverId}/admin`, server, this._authService.token);
    }
    async updateAsync(serverId: number, server: ServerViewModel) {
        return this.putObjectAsync(`/api/server/${serverId}`, server, this._authService.token);
    }

    async deleteAllAsync(serverIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/server/all`, { ids: serverIds }, this._authService.token);
    }

    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/server/${id}`, this._authService.token);
    }
    async connectAsync(id: number) {
        return this.postObjectAsync(`/api/server/${id}/connect`, undefined, this._authService.token);
    }

    async getAgentEditorDetailsAsync() {
        return this.getObjectAsync<AgentEditorModel>(`/api/server/editor/launch/details`, this._authService.token);
    }
}