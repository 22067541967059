export class ConfigEmailAlertModel {
    configId: number;
    isEnabled: boolean;
    toAddresses: string;
    ccAddresses?: string;
    bccAddresses?: string;
    isAlertOnSchedulesOnly: boolean;
    alertWhenRuntimeExceedsMinutes?: number;
    alertWhenErrorsExceeds?: number;
    isAlertOnError: boolean;
    isAlertOnFirstErrorAfterSuccess: boolean;
    isAlertOnSuccess: boolean;
    isAlertOnFirstSuccessAfterError: boolean;
    isAlertOnStart: boolean;

    constructor() {
        this.configId = 0;
        this.isEnabled = false;
        this.toAddresses = "";
        this.ccAddresses = undefined;
        this.bccAddresses = undefined;
        this.isAlertOnSchedulesOnly = false;
        this.alertWhenRuntimeExceedsMinutes = undefined;
        this.alertWhenErrorsExceeds = undefined;
        this.isAlertOnError = false;
        this.isAlertOnFirstErrorAfterSuccess = false;
        this.isAlertOnSuccess = false;
        this.isAlertOnFirstSuccessAfterError = false;
        this.isAlertOnStart = false;
    }
}
