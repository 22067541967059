import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { BaseEditor } from "../editors/base-editor";
import { AuthService } from "../../services/auth.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { FileUploadApi } from "../../services/file-upload.api";
import { ConfigService } from "../../services/config.service";
import { UserState } from "../../services/user.state";
import "../components/file-upload-button.element";

@customElement("se-shared-file-create")
export class SeSharedFileCreateElement extends LitElement {
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _fileUploadApi: FileUploadApi;
    private _modalService: ModalDialogService;
    private _configService: ConfigService;
    private _userState: UserState;

    private _isNew = false;
    private _configId: number;

    @state() private _uploadPercent: number;
    @state() private _fileName: string;
    @state() private _fileSize: string;
    @query(".progress-area") private _progressArea: HTMLDivElement;
    @query(".uploaded-area") private _uploadedArea: HTMLDivElement;
    @query("#progressBarFill") private _progressBarFill: HTMLDivElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        //this._userApi = container.resolve(UserApi);
        this._userState = container.resolve(UserState);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
        this._fileUploadApi = container.resolve(FileUploadApi);
        this._configService = container.resolve(ConfigService);
        this.updateProgress = this.updateProgress.bind(this);
        this.uploadStart = this.uploadStart.bind(this);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isUser) {
            return commands.redirect("/login");
        }
        if (location.params.configId) {
            this._configId = parseInt(location.params.configId.valueOf() as string);
            this._isNew = false;
        } else {
            this._isNew = true;
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync(ev) {
        ev.preventDefault();
        if (this.reportValidity()) {
        }
    }
    private onDragOver(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
    }
    private onDragLeave(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
    }
    private async onDragDrop(e: DragEvent) {
        console.log("dragdrop");
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer.files.length === 0) return;
        if (e.dataTransfer.files.length > 1) {
            this._toasterService.showError(
                "You can only upload one file at a time. Bundle files in a zip file and upload the zip file if you need access to multiple files.",
                "Error uploading files"
            );
            return;
        }
        if (e.dataTransfer.items[0]?.webkitGetAsEntry && e.dataTransfer.items[0].webkitGetAsEntry().isDirectory) {
            this._toasterService.showError(
                "You can only upload one file at a time. Bundle files and directories in a zip file and upload the zip file if you need access to multiple files and directories.",
                "Error uploading directory"
            );
            return;
        }

        this.uploadFile(e.dataTransfer.files[0]);
    }

    private uploadStart(e) {
        console.log("e uploadStart:", e);
    }

    private updateProgress(e) {
        console.log("e1:", e);
        this._uploadPercent = e.lengthComputable ? (e.loaded / e.total) * 100 : 0;
        if (this._uploadPercent === 100) {
            this._uploadPercent = this._uploadPercent * 0.9;
        }
        console.log("percent=" + this._uploadPercent + "%");
        this._progressBarFill.style.width = this._uploadPercent.toFixed(2) + "%";
    }

    private async uploadFile(file: File) {
        let size = Math.round(file.size / 1024);
        if (size < 1) size = 1;
        if (size > 10240) {
            this._toasterService.showError(
                `The file is too big (${size / 1024}MB). Files must have a size less than 10MB.", "Error uploading file`
            );
            return;
        }
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Upload File",
            body: `Are you sure you want to upload the file ${file.name} (${size}KB).`,
            saveCaption: "Upload",
        });
        if (result.isSave) {
            this._fileName = file.name;
            this._fileSize = `${size}Kb`;

            this._progressBarFill.style.width = "0%";
            this._progressArea.style.display = "block";
            this._uploadedArea.style.display = "none";

            const uploadResult = await this._fileUploadApi.uploadSharedFileAsync(
                file,
                this._userState.selectedSpaceId,
                this._userState.selectedLabelId,
                this.uploadStart,
                this.updateProgress
            );
            if (uploadResult.isErr) {
                this._toasterService.showNetworkError(uploadResult.err);
            } else {
                this._progressArea.style.display = "none";
                this._uploadedArea.style.display = "block";
                this._progressBarFill.style.width = "100%";
                this._toasterService.showSuccess(`${file.name} uploaded successfully.`);
                /*this.refresh();*/
            }
        }
    }

    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "Create" : "Edit"} Shared File</div>
                <div class="scroll-container" style="display: flex;gap: 10px;flex-direction: column;">
                    <se-file-upload-button
                        @fileChanged="${(ev: CustomEvent) => this.uploadFile(ev.detail.file)}"
                        html-title="Upload new file"
                        text="Upload File"
                        icon="far fa-upload"
                    ></se-file-upload-button>
                    <div class="upload-area" @dragover=${this.onDragOver} @dragleave=${this.onDragLeave} @drop=${this.onDragDrop}>
                        <span>Drag and drop your files here</span>
                    </div>
                    <section class="progress-area">
                        <li class="row">
                            <fa-icon class="icon" fa-class="far fa-file"></fa-icon>
                            <div class="content">
                                <div class="details">
                                    <span class="name">${this._fileName} &centerdot; Uploading...</span>
                                    <span class="percent"></span>
                                </div>
                                <div class="progress-bar" id="progressBar">
                                    <div class="progress-bar-fill" id="progressBarFill" style="width: 0%"></div>
                                </div>
                            </div>
                        </li>
                    </section>
                    <section class="uploaded-area">
                        <li class="row">
                            <fa-icon class="icon" fa-class="far fa-file"></fa-icon>
                            <div class="content">
                                <div class="details">
                                    <span class="name">${this._fileName} &centerdot; Uploaded</span>
                                    <span class="size">${this._fileSize}</span>
                                </div>
                            </div>
                            <fa-icon class="icon" fa-class="far fa-check"></fa-icon>
                        </li>
                    </section>
                </div>
                <div class="savePanel">
                    <se-secondary-button @click="${this.cancel}" text="Close"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
        }
        .upload-area {
            display: flex;
            width: 500px;
            height: 500px;
            max-height: 500px;
            border: 1px dashed blue;
            text-align: center;
            vertical-align: middle;
            align-items: center;
        }
        .upload-area span {
            display: block;
            width: 100%;
            vertical-align: middle;
            line-height: normal;
            font: var(--font-small);
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }

        .progress-area,
        .uploaded-area {
            display: none;
        }
        .progress-area .row .content {
            width: 100%;
            margin-left: 15px;
            font: var(--font-small);
        }
        .uploaded-area .row .content {
            width: 100%;
            margin-left: 15px;
            display: flex;
            font: var(--font-small);
        }
        .progress-area .details {
            display: flex;
            align-items: center;
            margin-bottom: 7px;
            justify-content: space-between;
        }
        .uploaded-area .row .details {
            display: flex;
            margin-left: 15px;
            flex-direction: column;
        }

        .progress-area .content .progress-bar {
            height: 6px;
            width: 100%;
            margin-bottom: 4px;
            background: #fff;
            border-radius: 30px;
        }
        .content .progress-bar .progress-bar-fill {
            height: 100%;
            width: 0%;
            background: #6990f2;
            border-radius: inherit;
            transition: width 0.25s;
        }

        section .row {
            margin-bottom: 10px;
            background: var(--color-light);
            list-style: none;
            padding: 15px 20px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        section .row .icon {
            font-size: 2em;
        }
    `;
}
