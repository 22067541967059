import { AccessLevel } from "../enums/access-level";

export class UserGroup {
        
    constructor() {
    }

    public id: string;
    public name: string;
    public description: string;
    public organizationId?: string;
    public organizationName: string;
    public isInGroup: number;
    public selected: boolean;
    public totalRecordCount: number;
}