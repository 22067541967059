import { container, singleton } from "tsyringe";
import { AppSettings } from "../models/app-settings";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";

@singleton()
export class AppSettingsApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();

        this._authService = container.resolve(AuthService);
    }
    async updateAppSettingsAsync(settings: AppSettings) {
        return this.putObjectAsync(`/api/app-settings`, settings, this._authService.token);
    }
    async getAppSettingsAsync() {
        return this.getObjectAsync<AppSettings>(`/api/app-settings`, this._authService.token);
    }
}
