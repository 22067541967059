import { LitElement, html, css, TemplateResult } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { dispatchCustomEvent } from "../../../utils/utils";
import { htmlTitle } from "se-shared/directives/html-title.directive";

@customElement("se-file-upload-button")
export class SeFileUploadButton extends LitElement {
    @property() icon: string;
    @property() iconColor: string;
    @property() text: string;
    @property({ attribute: "html-title" }) htmlTitle: string;
    @property({ type: Boolean }) redHover = false;
    @property({ type: Boolean }) disabled = false;
    @property({ type: Boolean }) active = false;
    @property({ type: Object }) customStyle: object;
    @property({ attribute: "size" }) size: "small" | "nomal";

    @property({ type: Object }) lockHtml: TemplateResult;

    @query("#file") private _fileElement: HTMLInputElement;

    onClick() {
        const event = new Event("buttonclick", { bubbles: true, composed: true });
        this.dispatchEvent(event);
    }

    private getContent() {
        if (this.icon && this.text) {
            return html`<fa-icon style="font-size:0.9em" fa-class="${this.icon}" single-color="${this.iconColor}" scale="0.9"></fa-icon
                ><span>${this.text ? this.text : ""} </span>`;
        } else if (this.icon) {
            return html`<fa-icon style="font-size:0.9em" fa-class="${this.icon}" single-color="${this.iconColor}" scale="0.9"></fa-icon>`;
        } else {
            return html` ${this.lockHtml}${this.text}`;
        }
    }

    private onFileChange() {
        if (this._fileElement.files.length === 1) {
            dispatchCustomEvent(this, "fileChanged", { file: this._fileElement.files[0] });
            this._fileElement.value = null;
        }
    }

    render() {
        return html`
            <button
                ${this.htmlTitle ? htmlTitle(this.htmlTitle) : undefined}
                ?disabled=${this.disabled || this.lockHtml !== undefined}
                @click="${() => this._fileElement.click()}"
                class="button ${classMap({ active: this.active, "icon-text-button": this.icon && this.text })}"
                type="button"
                style="${styleMap({
                    ...this.customStyle,
                    font: this.size === "small" ? "var(--font-button-small)" : "var(--font-button)",
                })};"
            >
                ${this.getContent()}
            </button>
            <input type="file" id="file" style="display:none" @change=${this.onFileChange} />
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        .button {
            padding: 1px 5px;
            margin-left: 5px;
            border-radius: 3px 3px;
            transition: all 0.25s;
            background-color: var(--primary-color);
            border: 1px solid gray;
            color: black;
            font: var(--font-button);
        }
        .button:disabled {
            opacity: 1;
            background-color: var(--primary-color);
            border: 1px solid lightgray;
            color: gray;
            transition: none;
        }
        .button:hover {
            border: 1px solid black;
            background-color: white;
            box-shadow: 2px 2px 2px lightgray;
        }
        .button.red:hover {
            background-color: crimson;
            color: white;
            border: 1px solid crimson;
        }
        .button:hover:disabled {
            background-color: var(--primary-color);
            border: 1px solid lightgray;
            box-shadow: none;
        }
        .button:active {
            background-color: white;
            box-shadow: none;
        }
        .button.red:active {
            background-color: crimson;
            color: white;
            border: 1px solid crimson;
        }
        .button.active {
            background-color: white;
            box-shadow: none;
        }
        .icon-text-button {
            display: flex;
            align-items: center;
            gap: 3px;
        }
        .upload-btn-wrapper {
            position: relative;
            overflow: hidden;
            display: inline-block;
        }
        .upload-btn-wrapper input[type="file"] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }
    `;
}
