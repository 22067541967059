import { container, singleton } from "tsyringe";
import { UsageApi } from "./server-usage.api";
import { ServerApi } from "./server.api";

@singleton()
export class ServerService {
    //private _userState: UserState;
    private _serverApi: ServerApi;
    private _usageApi: UsageApi;

    get api() {
        return this._serverApi;
    }

    get usageApi() {
        return this._usageApi;
    }

    constructor() {
        //this._userState = container.resolve(UserState);
        this._serverApi = container.resolve(ServerApi);
        this._usageApi = container.resolve(UsageApi);
    }

    /*async createSpaceAsync(name: string, isDefaultAccess: boolean) {
        const res = await this.createAsync(name, isDefaultAccess);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }
    async updateSpaceAsync(id: number, name: string, isDefaultAccess: boolean) {
        const res = await this.updateAsync(id, name, isDefaultAccess);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }
    async removeSpaceAsync(id: number) {
        const res = await this.removeAsync(id);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }*/
}
