import { container, inject, singleton } from "tsyringe";
import { idName } from "../models/id-name";
import { Organization } from "../models/organization";
import { ServerAdminViewModel } from "../models/server-admin-view-model";
import { ServerGroup } from "../models/server-group";
import { ServerGroupsViewModel } from "../models/server-groups-view-model";
import { ServerOrgViewModel } from "../models/server-org-view-model";
import { ServerViewModel } from "../models/server-view-model";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";

@singleton()
export class ServerGroupApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<ServerGroup>(`/api/server-group/${id}`, this._authService.token);
    }
    async getAllAsync(pageIndex = 1, recordsPerPage = 100, sortColumn = "", sortOrder = -1) {
        return this.getObjectAsync<ServerGroupsViewModel>(
            `/api/server-group?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            this._authService.token
        );
    }
    async getListAsync() {
        return this.getObjectAsync<ServerGroupsViewModel>(`/api/server-group/list`, this._authService.token);
    }
    async createAsync(name: string, description: string) {
        return this.postObjectAsync(`/api/server-group`, { name: name, description: description }, this._authService.token);
    }

    async updateAsync(id: number, name: string, description: string) {
        return this.putObjectAsync(`/api/server-group/${id}`, { name: name, description: description }, this._authService.token);
    }

    async deleteAllAsync(ids: number[]) {
        return this.deleteAllObjectsAsync(`/api/server-group/all`, { ids: ids }, this._authService.token);
    }

    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/server-group/${id}`, this._authService.token);
    }
}
