import { LitElement, html, css, } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { BaseEditor, ObjectEditor } from '../editors/base-editor';
import { container } from 'tsyringe';
import '../components/primary-button.element';
import '../components/secondary-button.element';
import '../editors/input-editor.element';
import seq32 from '../../../../assets/seq-32.svg'

import logo from '../../../../assets/logo.png'
import twitter from '../../../../assets/twitter.svg'
import linkedin from '../../../../assets/linkedin.svg'
import instagram from '../../../../assets/instagram.svg'
import { InputEditorElement } from '../editors/input-editor.element';
import { ToasterService } from 'se-shared/services/toaster.service';
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { err, ok } from 'se-shared/utils/result'
import { MultiFactorAuthService } from '../../services/multi-factor-auth.service';
import { AuthService } from '../../services/auth.service';
import { v4 as uuidv4 } from 'uuid';

@customElement('se-multi-factor-authentication')
export class MultiFactorAuthenticationElements extends LitElement {    

    private _toasterService: ToasterService;
    private _multiFactorAuthService: MultiFactorAuthService;
    private _authService: AuthService; 
    @state() private _qrCodeSetupImageUrl: string;
    @state() private _setupCode: string;
    @state() private _account: string;

    @query('#securityCode') private _securityCodeEditor: InputEditorElement;

    @state() private _hasChanged = false;
    private rememberMe: boolean;

    constructor() {
        super();

        this._toasterService = container.resolve(ToasterService);        
        this._authService = container.resolve(AuthService);
        this._multiFactorAuthService = container.resolve(MultiFactorAuthService);
        
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect('/login');
        }
        this.rememberMe  = new URLSearchParams(location.search).get("rememberme") === "true";
        return undefined;
    }

    async connectedCallback() {
        super.connectedCallback();
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))        
    }
    disconnectedCallback() {
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev))        
        super.disconnectedCallback();
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }   

    updateChangedValues() {        
    }

    show() {
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }

    firstUpdated() {
        
    }

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false)
                return false;
        }
        return true;
    }
 
    private async next(ev) {
        ev.preventDefault();   

            if (this.reportValidity()) {
                this._hasChanged = false;

                const multiFactorStamp = uuidv4();
                await this._multiFactorAuthService.VerifyTwoFactorAuthentication(this._securityCodeEditor.liveValue, multiFactorStamp, this.rememberMe)
                    .then((result) => this.success(result)).catch(err => this.error(err));
            }
    }

    private success(result) {
        if (!result.value) {
            this._toasterService.showError("Multi-factor authentication code is invalid. Please try again.", "Error");
        }
        else {
            Router.go("/");
        }
    }

    private async cancel(ev) {
        ev.preventDefault();
        this._authService.logout();
        Router.go("/login");
    }

    private error(err: Error) {
        this._toasterService.showError("Multi-factor authentication code is invalid. Please try again.", "Error");
        this._hasChanged = true;
    }

    render() {
        return html`
            <div class="header"></div>
            <form id="editorForm" class="editor">
                 <p class="h3"><img src=${logo} class="header-logo" background /></p>
       
                 <div class="scroll-container">            
                    <h4>Multi-Factor Authentication</h4>
                    <p>Please enter the 6-digit authentication code below</p>
                    <se-input-editor class="inputEditor" id="securityCode" name="securityCode" type="text"  labelPosition="top" required size="7"></se-input-editor>
                </div>

                <div class="savePanel">
                    <se-primary-button @click="${this.next}" text="Next"></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
            <div class="footer"><div>© 2022 Sequentum  •  <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a>  •  <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a></div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter}></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin}></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram}></a>
                </div>
            </div>
        `;
    }

    static styles = css`
    :host([hidden]) {
        display: none;        
    }
    :host {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .header {
        box-sizing: border-box;
        background-color: var(--color-primary);
        color:white;
        padding-left: 15px;
        padding-top: 15px;
        display:flex;
        gap:15px;
        font: var(--font-h3);
    }
    .h3 {
        font: var(--font-h3);
        background-color: var(--color-primary);
        padding: 10px 10px 10px 10px;
    }
    h4 {
        font: var(--font-h4);
        padding: 10px 10px 10px 10px;
        margin: 0;
        text-align: center;
        font-weight:bold;
    }
    .header-logo {
        width: 175px;
    }
    .editor {        
        background-color: var(--color-light);
        display: flex;
        flex-direction: column;
        margin: auto;
        width: fit-content;
        min-height: 0;
        padding: 0px 30px 20px 30px;
        padding-top: 0px;
        border-radius: 5px 5px;
    }
    .scroll-container {
        height:100%;
        min-height: 0;
        overflow: hidden;
        padding: 10px 10px 20px 10px;
        background-color: white;
        box-sizing: border-box;        
        border-radius: 5px 5px;
        border: 1px solid gray;
        box-shadow: 2px 2px 2px lightGray;
        max-width:500px;
    }
    .inputEditor {
        padding: 10px 10px 10px 10px;
    }
    .savePanel {
        display: flex;
        flex-direction: rows;
        justify-content: right;
        margin-top: 10px;
    }
    .linkLabel {
        display: flex;
        flex:2;
        font: var(--font-small);
        align-items: right;
        text-decoration: none;
        padding-top: 15px;
        flex-direction: row;
        align-items: center;
    }
    .footer {
        color: rgba(83, 86, 122, 1);
        font: var(--font-small);
        padding: 0px 20px 10px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .footer a {
        color: rgba(83, 86, 122, 1);
        text-decoration: none;
    }
    .footer a:hover {
        color: var(--color-secondary);
        text-decoration: none;
    }
    .setupCode{
        word-wrap: break-word;
    }
    .text-center{
        text-align: center !important;
    }
  `;
}