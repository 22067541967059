import { ParallelExport } from "../enums/parallel-export";
import { RunStatus } from "../enums/run-status";

export class RunViewModel {
    id: number;
    hasFiles?: boolean;
    parallelSet?: number;
    parallelMaxConcurrency?: number;
    parallelExport?: ParallelExport;
    parallelism?: number;
    sequence?: number;
    serverName?: string;
    tableType?: string;
    configVersion?: number;
    dataCount?: number;
    inputCount?: number;
    errorCount?: number;
    status?: RunStatus;
    startTime: Date;
    endTime?: Date;
    created?: Date;
    spaceId?: number;
    configId?: number;
    message?: string;
    actionCount?: number;
    pageCount?: number;
    dynamicPageCount?: number;
    requestCount?: number;
    exportCount?: number;
    totalTime?: number;
    traffic?: number;
    proxy?: string;
    host?: string;
    runTimeSec?: number;
    runtimeDataSize?: number;
    proxyPoolName?: string;

    static canDelete(status: RunStatus) {
        return (
            status !== RunStatus.running && status !== RunStatus.starting && status !== RunStatus.exporting && status !== RunStatus.stopping
        );
    }
    static canStop(status: RunStatus) {
        return status === RunStatus.running || status === RunStatus.exporting;
    }
    static canShowBrowserImages(status: RunStatus) {
        return status === RunStatus.running || status === RunStatus.stopping || status === RunStatus.starting;
    }
}

export class RunDetailsViewModel extends RunViewModel {
    pageCount?: number;
    requetCount?: number;
    requetAction?: number;
}
