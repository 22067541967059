import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ServerKeysViewModel } from "../../models/server-keys-view-model";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ServerKeysService } from "../../services/server-keys.service";
import { BaseEditor } from "../editors/base-editor";
import { InputEditorElement } from "../editors/input-editor.element";

@customElement("se-server-key-editor")
export class SeServerKeyEditorElement extends LitElement {
    private _toasterService: ToasterService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _serverKeysService: ServerKeysService;
    private _isNew = false;
    private _serverKeyId?: number;
    private _serverKey: ServerKeysViewModel;

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#clientId") private _clientIdEditor: InputEditorElement;
    @query("#clientSecret") private _clientSecretEditor: InputEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._serverKeysService = container.resolve(ServerKeysService);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.serverKeyId) {
            this._serverKeyId = parseInt(location.params.serverKeyId.valueOf() as string);
            this._isNew = false;
            const res = await this._serverKeysService.api.getAsync(this._serverKeyId);
            if (res.isOk) {
                this._serverKey = res.value;
            } else {
                alert(res.err.message);
                return commands.prevent();
            }
        } else {
            this._isNew = true;
            this._serverKey = new ServerKeysViewModel();
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            const server = this._isNew
                ? { clientName: this._nameEditor.liveValue, clientId: null }
                : { clientName: this._nameEditor.liveValue, clientId: this._clientIdEditor.liveValue };
            const res = await this._serverKeysService.api.createOrUpdateServerKeyAsync(server);
            if (res.isOk) {
                if (this._isNew) {
                    const result = await this._modalService.openInfoDialogAsync({
                        title: "New Server Key",
                        body: `Server key has been generated successfully.<br/><br/> Please COPY the Secret key <strong><span id="copyValue">${res.value.clientSecret}</span></strong> for future reference. It'll be displayed only once.`,
                    });
                    if (result.isSave) {
                        history.back();
                    }
                } else {
                    this._toasterService.showSuccess("Server Key details updated successfully.", 30000);
                    //Router.go('/edit/server-key/' + res.value.id);
                    history.back();
                }
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    async generateSecretKeyAsync(ev) {
        ev.preventDefault();
        if (this.reportValidity()) {
            const res = await this._serverKeysService.api.generateSecretKeyAsync(this._clientIdEditor.liveValue);
            if (res.isOk) {
                const result = await this._modalService.openInfoDialogAsync({
                    title: "Copy Server Key",
                    body: `Server key has been generated successfully.<br/><br/> Please COPY the Secret key <strong><span id="copyValue">${res.value}</span></strong> for future reference. It'll be displayed only once.`,
                });
                if (result.isSave) {
                    history.back();
                }
                //<span id="copyIcon" style="cursor: pointer;">📋</span>
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "New" : "Edit"} Server Key</div>
                <div class="scroll-container">
                    <se-input-editor
                        id="name"
                        name="name"
                        type="text"
                        label="Server Name"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._serverKey.clientName}
                    ></se-input-editor>
                    <se-input-editor
                        id="clientId"
                        name="clientId"
                        type="text"
                        label="Client Id"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        disabled
                        .value=${this._serverKey.clientId}
                    ></se-input-editor>

                    <div style="display: flex; gap: 5px;margin-top: 10px;">
                        <se-input-editor
                            id="clientSecret"
                            name="clientSecret"
                            type="password"
                            label="Client Secret"
                            labelPosition="top"
                            input-type="password"
                            required
                            size="30"
                            disabled
                            .value=${this._serverKey.clientSecret ?? ""}
                        ></se-input-editor>
                        ${this._isNew
                            ? html``
                            : html` <se-primary-button
                                  .customStyle="${{ width: "max-content; margin-top: 20px;" }}"
                                  @click="${this.generateSecretKeyAsync}"
                                  text="New Secret"
                              ></se-primary-button>`}
                    </div>
                </div>
                <div class="savePanel">
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Create" : "Save"} Key"
                    ></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 20px;
        }
    `;
}
