import { container, inject, singleton } from "tsyringe";
import { integer } from "vscode-languageserver-protocol";
import { User } from "../models/user";
import { UserLabels } from "../models/user-labels";
import { UserSpace } from "../models/user-space";
import { OrganizationUser } from "../models/organization-user-view-model";
import { UserSpaces } from "../models/user-spaces";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";
import { ApiKey } from "../models/api-key";

@singleton()
export class UserApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<User>(`/api/user/${id}`, this._authService.token);
    }

    async getSpacesAsync(pageIndex = 1, recordsPerPage = 100, sortColumn = "", sortOrder = -1) {
        return this.getObjectAsync<UserSpaces>(
            `/api/user/spaces?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            this._authService.token
        );
    }
    async getLabelsAsync(spaceId: number, pageIndex = 1, recordsPerPage = 100, sortColumn = "", sortOrder = -1) {
        return this.getObjectAsync<UserLabels>(
            `/api/user/labels/space/${spaceId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            this._authService.token
        );
    }
    async getHomeLabelsAsync(pageIndex = 1, recordsPerPage = 100, sortColumn = "", sortOrder = -1) {
        return this.getObjectAsync<UserLabels>(
            `/api/user/labels/space/home?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            this._authService.token
        );
    }

    //async ResetPassword(userId: integer, email: string, password: string) {
    //    return this.postObjectAsync(`/api/user/${userId}/reset-password`, { email: email, password: password }, this._authService.token);

    //}

    async createAsync(user: User) {
        return this.postObjectAsync(`/api/user?source=controlcenter`, user, this._authService.token);
    }

    async deleteAllAsync(userIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/user/all`, { ids: userIds }, this._authService.token);
    }

    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/user/${id}`, this._authService.token);
    }

    async restoreAccessAsync(userId: number) {
        return this.putAsync(`/api/user/${userId}/restore`, this._authService.token);
    }

    async approveUserAsync(userId: number) {
        return this.putAsync(`/api/user/${userId}/approve`, this._authService.token);
    }

    async ChangePassword(userId: integer, currentPassword: string, newPassword: string) {
        return this.postObjectAsync(
            `/api/user/change-password`,
            { userId: userId, currentPassword: currentPassword, newPassword: newPassword },
            this._authService.token
        );
    }

    async ResetPassword(token: string, password: string) {
        return this.postObjectAsync(`/api/user/reset-password`, { token: token, password: password }, this._authService.token);
    }
    async updateAsync(userId: number, user: User) {
        return this.putObjectAsync(`/api/user/${userId}`, user, this._authService.token);
    }

    async changeOrganizationAsync(newOrgId: number) {
        return this.postAsync(`/api/user/change-org/${newOrgId}`, this._authService.token);
    }

    async getApiKeysAsync(userId: number) {
        return this.getObjectAsync<{ userApiKeys: ApiKey[]; userName: string }>(`/api/user/${userId}/api-keys`, this._authService.token);
    }
    async getApiKeyAsync(apiKeyId: number) {
        return this.getObjectAsync<ApiKey>(`/api/user/api-key/${apiKeyId}`, this._authService.token);
    }
    async updateApiKeyAsync(apiKeyId: number, apiKey: ApiKey) {
        return this.putObjectAsync(`/api/user/api-key/${apiKeyId}`, apiKey, this._authService.token);
    }
    async createApiKeyAsync(apiKey: ApiKey) {
        return this.postObjectAsync(`/api/user/api-key`, apiKey, this._authService.token);
    }
    async deleteApiKeyAsync(id: number) {
        return this.deleteObjectAsync(`/api/user/api-key/${id}`, this._authService.token);
    }
    async deleteAllApiKeysAsync(apiKeyIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/user/api-keys/all`, { ids: apiKeyIds }, this._authService.token);
    }
}
