import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import logo from "../../../assets/logo.png";
import { AuthService } from "../services/auth.service";
import { UserState } from "../services/user.state";
import "./left-menu.element";
import { SeLeftMenuElement } from "./left-menu.element";
import "./top-menu.element";

@customElement("se-home")
export class SeHomeElement extends LitElement {
    private _authService: AuthService;
    //private _location: RouterLocation;
    private _userState: UserState;
    @state() private _isNotFound = false;

    @query("#leftMenu") private _leftMenu: SeLeftMenuElement;
    //@query('#contextMenu') private _contextMenu: ContextMenuElement;
    //@query('#accountMenu') private _accountMenu: HTMLDivElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userState = container.resolve(UserState);
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }
        //this._location = location;
        this._userState.router = router;
        const spaceId = location.params.spaceId ? parseInt(location.params.spaceId.toString()) : this._userState.getStoredSpaceId();
        const labelId = location.params.labelId ? parseInt(location.params.labelId.toString()) : this._userState.getStoredLabelId();
        this._userState.selectedSpaceId = spaceId;
        this._userState.selectedLabelId = labelId;
    }

    onPopState() {
        this._isNotFound = false;
        if (this._userState.router.location?.route?.component === "se-space") {
            const spaceId = this._userState.router.location.params.spaceId
                ? parseInt(this._userState.router.location.params.spaceId.toString())
                : 0;
            const labelId = this._userState.router.location.params.labelId
                ? parseInt(this._userState.router.location.params.labelId.toString())
                : 0;
            this._userState.selectedSpaceId = spaceId;
            this._userState.selectedLabelId = labelId;
            this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        }

        const body = this.shadowRoot?.querySelector(".right") as HTMLElement | null;
        if (body) {
            if (this._userState.router.location.route.component === "se-dashboard") {
                body.style.overflow = "auto";
            } else {
                body.style.overflow = "visible";
            }
        }

    }
    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('not-found-screen', () => this.handleNotFound());

        window.addEventListener("popstate", (evt) => this.onPopState());
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    handleNotFound() {
        this._isNotFound = true
    }
    render() {
        return html`
            <div class="body">
                <se-left-menu id="leftMenu"></se-left-menu>
                <div class="right">
                ${this._isNotFound ? html`<se-not-found></se-not-found>` : html`<slot></slot>`}
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            color: var(--color-primary);
        }
        .header {
            background-color: var(--color-primary);
            color: white;
            padding: 10px 20px 0px 15px;
            display: flex;
            align-items: center;
            gap: 15px;
            font: var(--font-h3);
            justify-content: space-between;
            flex-direction: row-reverse;
        }

        .account {
            color: Gainsboro;
            margin-left: 12px;
        }
        .account:hover {
            color: white;
        }
        .header-logo {
            height: 25px;
        }
        .body {
            display: flex;
            direction: row;
            flex: 1;
            gap: 0px;
            min-height: 0px;
        }
        .right {
            box-sizing: border-box;
            flex: 1;
            background-color: var(--color-light);
            height: 100%;
            min-height: 0px;
            min-width: 0px;
            overflow: auto;
        }
        @media screen and (max-width: 700px) {
            .header-logo {
                display: none;
            }
        }
    `;
}
