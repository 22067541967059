import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ConfigType } from "../../enums/config-type";
import { LabelFilter } from "../../enums/label-filter";
import { RunStatus } from "../../enums/run-status";
import { Label } from "../../models/label";
import { AuthService } from "../../services/auth.service";
import { LabelService } from "../../services/label.service";
import { UserState } from "../../services/user.state";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { SelectEditorElement } from "../editors/select-editor.element";

@customElement("se-label-editor")
export class SeLabelEditorElement extends LitElement {
    private _toasterService: ToasterService;
    private _userState: UserState;
    private _authService: AuthService;
    private _labelService: LabelService;
    private _isNew = false;
    private _labelId?: number;
    @state() private _spaceId?: number;
    private _label: Label;
    @state() private _labelFilter = "-1";
    private _labelFilters = [
        { id: -1, name: "None" },
        { id: LabelFilter.Config, name: "Configs" },
    ];
    private _configType = "-1";
    private _configTypes = [
        { id: -1, name: "All" },
        { id: ConfigType.Agent, name: "Agent" },
        { id: ConfigType.Template, name: "Template" },
        { id: ConfigType.SharedFile, name: "Input Data" },
    ];
    @state() private _status = "-1";
    private _statuses = [
        { id: -1, name: "All" },
        { id: 0, name: "Never Run" },
        { id: RunStatus.completed, name: "Completed" },
        { id: RunStatus.failed, name: "Failed" },
        { id: RunStatus.failure, name: "Failure" },
        { id: RunStatus.running, name: "Running" },
        { id: RunStatus.success, name: "Success" },
    ];
    private _spaces: { id: number; name: string }[] = [];

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#labelType") private _labelTypeEditor: SelectEditorElement;
    @query("#configType") private _configTypeEditor: SelectEditorElement;
    @query("#status") private _statusEditor: SelectEditorElement;
    @query("#applyToAll") private _applyToAllEditor: CheckboxEditorElement;
    @query("#visibleToAllUsers") private _visibleToAllUsersEditor: CheckboxEditorElement;
    @query("#visibleToAllSpaces") private _visibleToAllSpacesEditor: CheckboxEditorElement;

    constructor() {
        super();
        this._toasterService = container.resolve(ToasterService);
        this._authService = container.resolve(AuthService);
        this._labelService = container.resolve(LabelService);
        this._userState = container.resolve(UserState);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }

        this._spaces = [...(await (await this._userState.getUserSpacesAsync()).value)];
        this._spaces.splice(0, 0, { id: 0, name: "Personal" });
        if (this._authService.isOrgAdmin) {
            this._spaces.splice(0, 0, { id: -1, name: "All" });
        }

        if (location.params.labelId) {
            this._labelId = parseInt(location.params.labelId.valueOf() as string);
            this._isNew = false;
            //return this._spaceApi.getAsync(this._spaceId).then(space => this._space = space).catch(err => this.error(err));
            const res = await this._labelService.api.getAsync(this._labelId);
            if (res.isOk) {
                this._label = res.value;
                this._labelFilter = this._label.labelFilter === LabelFilter.Config ? LabelFilter.Config.toString() : "-1";
                this._configType = this._label.configType ? this._label.configType.toString() : "-1";
                if (!this._label.spaceId && !this._label.userId) this._spaceId = -1;
                else this._spaceId = this._label.spaceId ?? 0;
            } else {
                alert(res.err.message);
                return commands.prevent();
            }
        } else {
            this._isNew = true;
            this._label = new Label();
            this._spaceId = location.params.spaceId ? parseInt(location.params.spaceId.valueOf() as string) : undefined;
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            const label = {
                id: this._labelId ? this._labelId : null,
                name: this._nameEditor.liveValue,
                spaceId: this._spaceId > 0 ? this._spaceId : null,
                userId: this._spaceId === 0 ? this._authService.user.id : null,
                organizationId: this._spaceId === 0 ? null : this._authService.user.organizationId,
                labelFilter: this._labelFilter != "-1" ? parseInt(this._labelFilter) : null,
                configType: this._configType != "-1" ? parseInt(this._configType) : null,
                status: this._status != "-1" ? parseInt(this._status) : null,
            };

            if (this._isNew) {
                const res = await this._labelService.api.createAsync(label);
                if (res.isOk) {
                    if (this._isNew) await this._labelService.bookmarkApi.createAsync(res.value.id);

                    const labels = await this._userState.refreshUserLabelsAsync();
                    if (labels.isErr) {
                        this._toasterService.showNetworkError(labels.err);
                    }
                    history.back();
                } else {
                    this._toasterService.showNetworkError(res.err);
                }
            } else {
                const res = await this._labelService.api.updateAsync(label);
                if (res.isOk) {
                    const labels = await this._userState.refreshUserLabelsAsync();
                    if (labels.isErr) {
                        this._toasterService.showNetworkError(labels.err);
                    }
                    history.back();
                } else {
                    this._toasterService.showNetworkError(res.err);
                }
            }
        }
    }

    cancel() {
        history.back();
    }

    private onStatusChanged() {
        this._status = this._labelTypeEditor.liveValue;
    }

    private onLabelTypeChanged() {
        if (this._labelTypeEditor.liveValue === "-1") {
            this._configType = "-1";
            this._status = "-1";
        } else if (this._labelTypeEditor.liveValue === "0") {
            this._status = "-1";
        }
        this._labelFilter = this._labelTypeEditor.liveValue;
    }

    private onConfigTypeChanged() {
        this._configType = this._configTypeEditor.liveValue;
        this._status = "-1";
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "Create" : "Edit"} Label</div>
                <div class="scroll-container">
                    <se-input-editor
                        class="inputEditor"
                        id="name"
                        name="name"
                        type="text"
                        label="Name"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._label.name}
                    ></se-input-editor>
                    <se-select-editor
                        @valueChanged=${(evt) => (this._spaceId = parseInt(evt.detail.value))}
                        class="inputEditor"
                        required
                        id="space"
                        name="space"
                        type="text"
                        label="Space"
                        labelPosition="top"
                        .value=${this._spaceId.toString()}
                        .options=${this._spaces}
                        width="100%"
                    ></se-select-editor>
                    <se-select-editor
                        @valueChanged=${this.onLabelTypeChanged}
                        class="inputEditor"
                        required
                        id="labelType"
                        name="labelType"
                        type="text"
                        label="Filter"
                        labelPosition="top"
                        .value=${this._labelFilter}
                        .options=${this._labelFilters}
                        width="100%"
                    ></se-select-editor>
                    <se-select-editor
                        .hidden=${this._labelFilter === "-1"}
                        @valueChanged=${this.onConfigTypeChanged}
                        class="inputEditor"
                        id="configType"
                        name="space"
                        type="text"
                        label="Config type"
                        labelPosition="top"
                        .value=${this._configType}
                        .options=${this._configTypes}
                        width="100%"
                    ></se-select-editor>
                    <se-select-editor
                        .hidden=${this._labelFilter === "-1"}
                        @valueChanged=${this.onStatusChanged}
                        class="inputEditor"
                        id="status"
                        name="space"
                        type="text"
                        label="Status"
                        labelPosition="top"
                        .value=${this._status}
                        .options=${this._statuses}
                        width="100%"
                    ></se-select-editor>
                </div>
                <div class="savePanel">
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Create" : "Save"} Label"
                    ></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .inputEditor {
            margin-bottom: 10px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
            min-width: 400px;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
