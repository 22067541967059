export class FileUtil {
    public static SaveFileAsText(fileData: any, downloadFileName: string) {
        const blob: Blob = new Blob([fileData], { type: "text/csv" });
        const objectUrl: string = URL.createObjectURL(blob);

        const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = downloadFileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    public static ViewFileAsText(fileData: any) {
        const blob: Blob = new Blob([fileData], { type: "text" });
        const objectUrl: string = URL.createObjectURL(blob);
        window.open(objectUrl, "_blank");

        URL.revokeObjectURL(objectUrl);
    }

    public static SaveFile(fileData: any, downloadFileName: string) {
        const blob: Blob = new Blob([fileData], { type: "application/octet-stream" });
        const objectUrl: string = URL.createObjectURL(blob);

        const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = downloadFileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

}
