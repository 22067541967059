import { css, html, LitElement, TemplateResult } from "lit";
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { customElement, property, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { when } from "lit/directives/when.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { isRunningOnServer, isRunningOnServerOrStarting, RunStatus, isReadyToRun } from "../../enums/run-status";
import { SortOrder } from "se-shared/enums/sort-order";
import { RunViewModel } from "../../models/run-view-model";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { RunService } from "../../services/run.service";
import { RunHistoryService } from "../../services/run-history.service";
import { ServerService } from "../../services/server.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import "./run-details.element";
import { RunDetailsElement } from "./run-details.element";
import { MultiSelectEditor } from "../editors/multi-select-editor.element";
import { formatSize } from "se-shared/utils/utils";

@customElement("se-org-runs")
export class SeOrgRunsElement extends LitElement {
    @property() configId;
   
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _runService: RunService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _runHistoryService: RunHistoryService;
    private _columns: DataGridColumn[] = [];
    @state() private _data: RunViewModel[];
    private _configName: string;
    private _serverService: ServerService;
    private _runRefresh = true;
    private _lastRefresh = 0;
    private _refreshId;

    private _pageIndex = 1;
    private _recordsPerPage = 15;
    private _totalRecordCount: number;
    private _sortColumn = "created";
    private _sortOrder = 1;
    private filters: {
        status?: string;
    } = {};

    @state() private _hasSelectedRows = false;
    @state() private _isLoading = true;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;
    @query("#runDetails") private _runDetails: RunDetailsElement;
    @query("#mutliSelectFilter") private _mutliSelect: MultiSelectEditor;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._runService = container.resolve(RunService);
        this._menuService = container.resolve(MenuService);
        this._serverService = container.resolve(ServerService);
        this._runHistoryService = container.resolve(RunHistoryService);
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
    }

    private isParallelMasterSet(row: RunViewModel) {
        return row.parallelism > 1 && row.parallelSet === 0;
    }
    private isParallelSubSet(row: RunViewModel) {
        return row.parallelism > 1 && row.parallelSet > 0;
    }

    private menu(row: RunViewModel, col: DataGridColumn): MenuItem[] {
        const isRunning = isRunningOnServer(row.status);
        const isRunningOrStarting = isRunningOnServerOrStarting(row.status);
        if (row.tableType == "history") {
            return [{ text: "Delete", action: this.deleteRunAsync.bind(this, row, col) }];
        }
        return [
            {
                text: "Stop",
                action: this.onStopAsync.bind(this, row),
                hidden: !isRunningOrStarting,
                disabled: row.status === RunStatus.stopping,
            },
            {
                text: "Restart",
                action: this.onRestartAsync.bind(this, row),
                hidden: isRunningOrStarting || this.isParallelMasterSet(row), //We should support restarting a complete parallel run at some point, but hide it for now.
            },
            {
                text: "Continue",
                action: this.onContinueAsync.bind(this, row),
                hidden: isRunningOrStarting || row.status !== RunStatus.stopped || !row.runtimeDataSize,
            },
            { text: "-" },
            {
                text: row.status === RunStatus.stopped ? "Retry Errors and Continue" : "Retry Errors",
                action: this.onRetryAsync.bind(this, row),
                hidden:
                    isRunningOrStarting ||
                    !row.runtimeDataSize ||
                    row.errorCount === 0 ||
                    (row.status !== RunStatus.stopped && row.status !== RunStatus.failure && row.status !== RunStatus.completed),
            },
            { text: "-" },
            { text: "Refresh Status", action: this.onRefreshStatusAsync.bind(this, row), hidden: !isRunning },
            { text: "-" },
            { text: "Fail Run", action: this.onFailRunAsync.bind(this, row), hidden: isRunningOrStarting },
            { text: "-" },
            { text: "Delete", action: this.deleteRunAsync.bind(this, row, col), hidden: isRunningOrStarting || this.isParallelSubSet(row) },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this._runRefresh = true;
        this.loadDataAsync();
    }
    disconnectedCallback() {
        this._runRefresh = false;
        super.disconnectedCallback();
    }

    private async deleteRunAsync(row) {
        if (row.parallelism > 1 && row.parallelSet > 0) {
            this._toasterService.showError("You cannot delete parallel sub runs directly. Delete the parallel master run instead.");
            return;
        }
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Run",
            body: `Are you sure you want to delete run with sequence ${row.sequence}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            let result1;
            if (row.serverName != "") {
                result1 = await this._runService.api.deleteManyBySequenceAsync(row.configId, [row.sequence]);
            } else {
                result1 = await this._runHistoryService.api.deleteManyAsync(row.configId, [row.id]);
            }
            if (result1.isOk) {
                const deletedRows = this._data.filter((p) => p.id === row.id);
                let selectionChanged = false;
                for (const deletedRow of deletedRows) {
                    const index = this._data.indexOf(deletedRow);
                    this._data.splice(index, 1);
                    if (row["selected"]) selectionChanged = true;
                }
                if (selectionChanged) this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
            } else if (result1.isErr) {
                this._toasterService.showNetworkError(result1.err);
            }
        }
    }

    private getRate(row) {
        /*const date = new Date(row.startTime);
        if (row.endTime) {
            const now = new Date(row.endTime);
            return (row.pageCount / ((now.getTime() - date.getTime()) / 1000)).toFixed(2);
        } else {
            const now = new Date();
            return (row.pageCount / ((now.getTime() - date.getTime()) / 1000)).toFixed(2);
        }*/
        const seconds = row.runTimeSec ?? row.toalTime ?? 0;
        return seconds ? (row.pageCount / seconds).toFixed(2) : "n/a";
    }

    private getStats(row: RunViewModel): TemplateResult {
        if (!row.actionCount && !row.dataCount && !row.errorCount) {
            if (isRunningOnServer(row.status)) {
                return html`<fa-icon single-color="silver" fa-class="far fa-spinner fa-spin"></fa-icon>`;
            } else {
                return html`n/a`;
            }
        } else {
            const rate = this.getRate(row);
            const totalTime = this.formatTotalTime(row);
            return html`<span
                ${htmlTitle(
                    html` <style>
                            .stats-table td:nth-child(2) {
                                text-align: right;
                            }
                            .stats-table td:nth-child(1) {
                                padding-right: 5px;
                            }
                        </style>
                        <table class="stats-table">
                            <tr>
                                <td>Actions:</td>
                                <td>${row.actionCount}</td>
                            </tr>
                            <tr>
                                <td>Data:</td>
                                <td>${row.dataCount}</td>
                            </tr>
                            <tr>
                                <td>Errors:</td>
                                <td>${row.errorCount}</td>
                            </tr>
                        </table>
                        <hr />
                        <table class="stats-table">
                            <tr>
                                <td>Total Pages:</td>
                                <td>${row.pageCount}</td>
                            </tr>
                            <tr>
                                <td>Dynamic pages:</td>
                                <td>${row.dynamicPageCount}</td>
                            </tr>
                            <tr>
                                <td>Rate (pages/sec):</td>
                                <td>${rate}</td>
                            </tr>
                            <tr>
                                <td>Requests:</td>
                                <td>${row.requestCount}</td>
                            </tr>
                            ${row.inputCount
                                ? html`<tr>
                                      <td>Inputs:</td>
                                      <td>${row.inputCount}</td>
                                  </tr>`
                                : html``}
                            ${row.exportCount || row.exportCount === 0
                                ? html`<tr>
                                      <td>Exported data:</td>
                                      <td>${row.exportCount}</td>
                                  </tr>`
                                : html``}
                            <tr>
                                <td>Traffic:</td>
                                <td>${formatSize(row.traffic)}</td>
                            </tr>
                            <tr>
                                <td>Run time:</td>
                                <td style="white-space: nowrap;">${totalTime}</td>
                            </tr>
                        </table>`,
                    true
                )}
                style="border-bottom: 1px dotted;cursor:pointer;user-select: none;"
                >a:${row.actionCount}&nbsp;d:${row.dataCount}&nbsp;e:${row.errorCount}</span
            >`;
        }
    }

    private getStatus(color: string, text: string, row: RunViewModel): TemplateResult {
        if (row.message && !isRunningOnServer(row.status)) {
            return html`<se-status
                ${htmlTitle(row.message, true)}
                status-message="${text}" status-color="${color}"  style="width: 100%; max-width: 110px"
                ></se-status`;
        } else {
            return html`<se-status style="width: 100%; max-width: 110px" status-message="${text}" status-color="${color}"></se-status>`;
        }
    }

    private formatTotalTime(row) {
        const seconds = row.runTimeSec ?? row.toalTime ?? 0;
        if (!seconds) {
            return "n/a";
        }
        if (seconds < 60) {
            return seconds + " sec";
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            if (remainingSeconds === 0) {
                return minutes + " min";
            } else {
                return minutes + " min" + " " + remainingSeconds + " sec";
            }
        } else {
            const hours = Math.floor(seconds / 3600);
            const remainingMinutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            let result = "";
            if (hours > 1) {
                result += hours + " hours";
            } else {
                result += hours + " hour";
            }
            if (remainingMinutes > 0) {
                result += " " + remainingMinutes + " min";
            }
            if (remainingSeconds > 0) {
                result += " " + remainingSeconds + " sec";
            }
            return result;
        }
    }
    //formatting time difference
    private timeDifference(startDate: Date | string, endDate: Date | string): string {
        if (startDate == null || endDate == null) {
            return " - ";
        }
        const start = new Date(startDate);
        const end = new Date(endDate);

        const diffMs = end.getTime() - start.getTime();

        const seconds = Math.floor((diffMs / 1000) % 60);
        const minutes = Math.floor((diffMs / (1000 * 60)) % 60);
        const hours = Math.floor(diffMs / (1000 * 60 * 60));

        let formattedDiff = "";

        if (hours) {
            formattedDiff += `${hours} hour${hours !== 1 ? "s" : ""} `;
        }

        if (minutes) {
            formattedDiff += `${minutes} minute${minutes !== 1 ? "s" : ""} `;
        }

        if (seconds) {
            formattedDiff += `${seconds} second${seconds !== 1 ? "s" : ""}`;
        }

        if (!formattedDiff) {
            return "0 seconds";
        }

        return formattedDiff.trim();
    }

    private async loadDataAsync(isShowLoading = true) {
        if (this._refreshId) {
            clearTimeout(this._refreshId)
        }
        if (this._hasSelectedRows || this._menuService.isMenuOpen()) {
            return;
        }

        this._columns = [
            {
                field: "configName",
                title: "Config Name",
                align: "left",
                template: (row, col) => html`<a href="javascript:;" @click=${(e) => { e.stopPropagation(); col.action(row, col); }}>${row.configName}</a>`,
                action: (row) => this.openDetails(row)
            },
            {
                field: "parallelism",
                title: "Parallelism",
                align: "center",
                hidden: true,
                template: (row) => (row.parallelSet ? html`${row.parallelSet}/${row.parallelism}` : html`${row.parallelism}`),
            },
            { field: "serverName", title: "Server" },
            {
                field: "status",
                title: "Status",
                sortable: true,
                align: "center",
                template: (row) =>
                    html`${choose(
                        row.status,
                        [
                            [undefined || null, () => html``],
                            [RunStatus.waiting, () => this.getStatus("teal", "Waiting", row)],
                            [RunStatus.queuing, () => this.getStatus("--color-gray-4", "Queuing", row)],
                            [RunStatus.starting, () => this.getStatus("--color-purple", "Starting", row)],
                            [RunStatus.success, () => this.getStatus("--color-status-blue", "Success", row)],
                            [RunStatus.stopping, () => this.getStatus("--color-purple", "Stopping", row)],
                            [RunStatus.stopped, () => this.getStatus("--color-gray-4", "Stopped", row)],
                            [RunStatus.failure, () => this.getStatus("--color-status-red", "Failure", row)],
                            [RunStatus.failed, () => this.getStatus("--color-status-red", "Failed", row)],
                            [RunStatus.completed, () => this.getStatus("--color-status-blue", "Completed", row)],
                            [RunStatus.running, () => this.getStatus("--color-purple", "Running", row)],
                            [RunStatus.exporting, () => this.getStatus("--color-purple", "Exporting", row)],
                        ],
                        () => html`${row.status}`
                    )}`,
            },
            {
                field: "created",
                title: "Created",
                align: "center",
                sortable: true,
                template: (row) => {
                    const date = new Date(row.created);
                    return html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
            },
            { title: "Run Time", template: (row) => html`${this.timeDifference(row.startTime, row.endTime)}` },
            {
                field: "message",
                title: "Message",
                template: (row) => html`<span style="word-break: break-all;">${row.message}</span>`,
                cellStyle: { width: "30%" },
            },
            { title: "Stats", align: "center", template: (row) => this.getStats(row) },
            {
                // View details column
                name: "info",
                cellStyle: { textAlign: "center", width: "20px" },
                template: (row) => {
                    let tooltip = "";
                    if (isRunningOnServer(row.status)) {
                        tooltip = "View live information";
                    } else {
                        tooltip = "View run details and files";
                    }
                    return html`<se-secondary-button
                        title="${tooltip}"
                        icon="far fa-browser"
                        @click=${() => this.runDetails(row)}
                    ></se-secondary-button>`;
                },
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];

        if (isShowLoading) this._isLoading = true;
        try {
            this._lastRefresh = Date.now();

            //filters
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.size > 0) {
                const filterStatus = urlParams.get('status');
                this.filters["status"] = filterStatus;
            }


            const body = {
                pageIndex: this._pageIndex,
                recordsPerPage: this._recordsPerPage,
                sortColumn: this._sortColumn,
                sortOrder: this._sortOrder,
                filters: this.filters, 
            };
            const result = await this._runService.api.getOrgRunsAsync(body);
            if (result.isOk) {
                this._data = result.value.runsHistory;
                this._totalRecordCount = result.value.totalRecordCount;
                this._columns.find((p) => p.field === "parallelism").hidden = !this._data.some((p) => p.parallelism && p.parallelism > 1);

                const isQuickUpdate = this._data.some(
                    (row) => row.status === RunStatus.queuing || row.status === RunStatus.starting || row.status === RunStatus.stopping
                );
                this._refreshId = setTimeout(() => this.loadDataAsync(false), isQuickUpdate ? 2000 : 10000);
            } else {
                this._lastRefresh = 0;
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
        if (this._selectAll) this._selectAll.value = false;
    }
    private openDetails(row: RunViewModel) {
        this._userState.selectedSpaceId = row.spaceId;
        this._userState.selectedLabelId = 0;
        Router.go(`/space/${row.spaceId}/config/${row.configId}/details/runs`);
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        this._sortColumn = evt.detail.sortColumn;
        this._sortOrder = evt.detail.sortOrder == SortOrder.Ascending ? 1 : 0;
        this.loadDataAsync();
    }

    private refresh() {
        this.loadDataAsync();
    }

    private async onDeleteManyAsync(event: MouseEvent) {
        event.stopPropagation();

        if (this._dataGrid.selectedRows.some((p) => !RunViewModel.canDelete(p.status))) {
            this._toasterService.showError("You cannot delete active runs. Please stop the runs first.");
            return;
        }

        const deletedRows = this._dataGrid.selectedRows.filter((p) => !p.parallelSet);
        if (deletedRows.length === 0) {
            this._toasterService.showError("You cannot delete parallel sub runs directly. Delete the parallel master run instead.");
            return;
        }
        const count = deletedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Runs",
            body: `Are you sure you want to delete ${count === 1 ? `1 run` : `${count} runs`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const sequencesByConfigId = {};
            const idsByConfigId = {};

            deletedRows
                .filter((row) => row.tableType != "run")
                .forEach((p) => {
                    if (sequencesByConfigId[p.configId] === undefined) {
                        sequencesByConfigId[p.configId] = [];
                    }
                    sequencesByConfigId[p.configId].push(p.sequence as number);
                });
            deletedRows
                .filter((row) => row.tableType == "history")
                .forEach((p) => {
                    if (idsByConfigId[p.configId] === undefined) {
                        idsByConfigId[p.configId] = [];
                    }
                    idsByConfigId[p.configId].push(p.id as number);
                });

            for (const configId in sequencesByConfigId) {
                const sequences = sequencesByConfigId[configId];
                const result1 = await this._runService.api.deleteManyBySequenceAsync(parseInt(configId), sequences);
                if (result1.isOk) {
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this._data = this._data.filter((p) => !sequences.includes(p.sequence));
                } else if (result1.isErr) {
                    this._toasterService.showNetworkError(result1.err);
                }
            }
            for (const configId in idsByConfigId) {
                const ids = idsByConfigId[configId];
                const result2 = await this._runHistoryService.api.deleteManyAsync(parseInt(configId), ids);
                if (result2.isOk) {
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this._data = this._data.filter((p) => !ids.includes(p.id));
                } else if (result2.isErr) {
                    this._toasterService.showNetworkError(result2.err);
                }
            }
        }
    }

    private async onSetFailedStatusOfManyAsync(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Fail Runs",
            body: `Are you sure you want to set ${count === 1 ? `1 run` : `${count} runs`} to Failed status?`,
            saveCaption: "Set Status",
        });
        if (result.isSave) {
            const idsByConfigId = {};
            const selectedRows = this._dataGrid.selectedRows.filter((row) => !isReadyToRun(row.status));
            selectedRows.forEach((p) => {
                if (idsByConfigId[p.configId] === undefined) {
                    idsByConfigId[p.configId] = [];
                }
                idsByConfigId[p.configId].push(p.id as number);
            });
            for (const configId in idsByConfigId) {
                const runIds = idsByConfigId[configId];
                const result1 = await this._runService.api.failMany(parseInt(configId), runIds);
                if (result1.isOk) {
                    this._dataGrid.selectedRows
                        .filter((row) => !isReadyToRun(row.status))
                        .forEach((p) => {
                            p.status = RunStatus.failed;
                            p.endTime = p.endTime ? p.endTime : new Date();
                        });
                    this._dataGrid.requestUpdate();
                } else if (result1.isErr) {
                    this._toasterService.showNetworkError(result1.err);
                }
            }
        }
    }
    private async onStopManyAsync(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Stop Runs",
            body: `Are you sure you want to stop ${count === 1 ? `1 run` : `${count} runs`}?`,
            saveCaption: "Stop Runs",
        });
        if (result.isSave) {
            const idsByConfigId = {};
            const selectedRows = this._dataGrid.selectedRows.filter((row) => !isReadyToRun(row.status));
            selectedRows.forEach((p) => {
                if (idsByConfigId[p.configId] === undefined) {
                    idsByConfigId[p.configId] = [];
                }
                idsByConfigId[p.configId].push(p.id as number);
            });

            for (const configId in idsByConfigId) {
                const runIds = idsByConfigId[configId];
                const result1 = await this._runService.api.stopMany(parseInt(configId), runIds);
                if (result1.isOk) {
                    this._dataGrid.selectedRows
                        .filter((row) => !isReadyToRun(row.status))
                        .forEach((p) => {
                            p.status = RunStatus.stopping;
                        });
                    this._dataGrid.clearSelection();
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this._dataGrid.requestUpdate();
                } else if (result1.isErr) {
                    this._toasterService.showNetworkError(result1.err);
                }
            }
        }
    }
    private async onStopAsync(row) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Stop Run",
            body: `Are you sure you want to stop run with sequence ${row.sequence}?`,
            saveCaption: "Stop Run",
        });
        if (result.isSave) {
            const result = await this._runService.api.stopMany(row.configId, [row.id]);
            if (result.isOk) {
                row.status = RunStatus.stopping;
                this._dataGrid.requestUpdate();
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onRestartAsync(row) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Restart Run",
            body: `Are you sure you want to restart run with sequence ${row.sequence}?`,
            saveCaption: "Restart Run",
        });
        if (result.isSave) {
            const result = await this._runService.api.restart(row.id);
            if (result.isOk) {
                row.status = RunStatus.queuing;
                this._dataGrid.requestUpdate();
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onContinueAsync(row) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Continue Run",
            body: `Are you sure you want to continue run with sequence ${row.sequence}?`,
            saveCaption: "Continue Run",
        });
        if (result.isSave) {
            const result = await this._runService.api.continue(row.id);
            if (result.isOk) {
                row.status = RunStatus.queuing;
                this._dataGrid.requestUpdate();
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onRetryAsync(row) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: row.status === RunStatus.stopped ? "Retry Errors and Continue" : "Retry Errors",
            body: `Are you sure you want to ${
                row.status === RunStatus.stopped ? "retry errors and continue" : "retry errors for"
            } run with sequence ${row.sequence}?`,
            saveCaption: row.status === RunStatus.stopped ? "Retry Errors and Continue" : "Retry Errors",
        });
        if (result.isSave) {
            const result = await this._runService.api.retry(row.id);
            if (result.isOk) {
                row.status = RunStatus.queuing;
                this._dataGrid.requestUpdate();
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async onRefreshStatusAsync(row) {
        const result = await this._runService.api.checkStatus(row.id);
        if (result.isOk) {
            row.status = result.value.status;
            this._dataGrid.requestUpdate();
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }
    private async onFailRunAsync(row) {
        const result = await this._runService.api.failRun(row.id);
        if (result.isOk) {
            if (row.parallelism > 1) {
                //Changing status on a parallel set may effect statuses on other sets of the sequence.
                this.refresh();
            } else {
                row.status = RunStatus.failed;
                row.endTime = row.endTime ? row.endTime : new Date();
                this._dataGrid.requestUpdate();
            }
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    private async runDetails(row: RunViewModel) {
        this._userState.selectedSpaceId = row.spaceId;
        this._userState.selectedLabelId = 0;
        if (row.tableType == "run") {
            Router.go(`/space/${row.spaceId}/config/${row.configId}/details/run-details/run/${row.id}`);
        } else {
            Router.go(`/space/${row.spaceId}/config/${row.configId}/details/run-history-details/run/${row.id}`);
        }
    }
    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadDataAsync();
    }

    get runStatusOptions() {
        const selectedStatuses = this.filters["status"]?.split(',').map(Number)||[];
        const opts = Object.keys(RunStatus)
            .filter(key => isNaN(Number(key)))  
            .map(key => ({
                id: RunStatus[key as keyof typeof RunStatus],   
                name: key.charAt(0).toUpperCase() + key.slice(1), 
                value: selectedStatuses.includes(RunStatus[key as keyof typeof RunStatus]) 
            }))
            .sort((a, b) => a.name.localeCompare(b.name) )
        return opts;
    }

    updateURLQueryParam(key: string, value: string) {
        const url = new URL(window.location.href);
        url.searchParams.set(key, value);
        window.history.replaceState(null, '', url.toString());
    }

    handleSelectionChange() {
        const selectedOptions: number[] = this._mutliSelect.selectedValues;
        this.filters["status"] = selectedOptions.join(',');
        this.updateURLQueryParam("status", this.filters["status"]);
       this.loadDataAsync(false)
    }

    handleSelectAll() {
        const selectedOptions: number[] = Object.keys(RunStatus).filter(key => isNaN(Number(key))).map(key => RunStatus[key as keyof typeof RunStatus]);
        this.filters["status"] = selectedOptions.join(',');
        this.updateURLQueryParam("status", this.filters["status"]);
        this.loadDataAsync(false)
    }

    handleClearAll() {
        const selectedOptions: number[] = [];
        this.filters["status"] = selectedOptions.join(',');
        this.updateURLQueryParam("status", this.filters["status"]);
        this.loadDataAsync(false)
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        const canStop = this._hasSelectedRows && this._dataGrid.selectedRows.some((p) => RunViewModel.canStop(p.status));
        return html`
            <div class="body">
                <div class="title"><h1>All Organization Runs</h1></div>
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${(this._data?.length ?? 0) === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteManyAsync(event)}
                                      ${htmlTitle("Delete selected runs")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                                  ${when(
                                      canStop,
                                      () =>
                                          html`<se-secondary-button
                                              @mousedown=${(event) => this.onStopManyAsync(event)}
                                              ${htmlTitle("Stop selected runs")}
                                              .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                              icon="fas fa-square"
                                              iconColor="dimgray"
                                          ></se-secondary-button>`
                                  )}
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onSetFailedStatusOfManyAsync(event)}
                                      ${htmlTitle("Fail selected runs")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-times-hexagon"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    <se-multi-select-editor id="mutliSelectFilter"
                    @valueChanged=${this.handleSelectionChange}
                    @selectAll=${this.handleSelectAll}
                    @clearAll=${this.handleClearAll}
                        .isSelectAll=${true}
                        .optionsName=${"Status"}
                        .options=${this.runStatusOptions}>
                    </se-multi-select-editor>
                    </div>
                </div>

                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No runs available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>
                <div style="overflow:hidden;display:flex;justify-content: center;">
                    <se-pagination
                        .recordCount=${this._totalRecordCount}
                        .recordsPerPage=${this._recordsPerPage}
                        @pagechanged=${this.onPageChanged}
                    ></se-pagination>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
            padding: 15px;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .right-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
