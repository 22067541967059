import "element-internals-polyfill";
import { css, html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { live } from "lit/directives/live.js";
import { styleMap } from "lit/directives/style-map.js";
import { BaseEditor } from "../base-editor";

@customElement("se-number-inline-editor")
export class NumberEditorElement extends LitElement implements BaseEditor {
    @property({ type: Number }) value: number;
    get liveValue() {
        return this._inputElement?.valueAsNumber;
    }

    @property({ type: Number }) step?: number;
    @property({ attribute: "input-width" }) inputWidth?: string;
    @property({ type: Number }) minlength?: number;
    @property({ type: Number }) maxlength?: number;
    @property() min?: string;
    @property() max?: string;
    @property() placeholder?: string;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property() name: string;
    @property() type: "number" | "range" = "number";
    @property({ attribute: "editor-size" }) editorSize: "small" | "nomal";
    @property({ type: Boolean, attribute: "use-auto-complete" }) useAutoComplete = false;

    @query("#input") private _inputElement: HTMLInputElement;

    constructor() {
        super();
    }

    valueChanged() {}
    updateValue(event) {
        if (this._inputElement.checkValidity()) this._inputElement.classList.remove("invalid");
        else this._inputElement.classList.add("invalid");
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }));
    }
    reportValidity(): boolean {
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        } else {
            this._inputElement.classList.add("invalid");
            return false;
        }
    }

    firstUpdated() {}

    cancel() {
        this._inputElement.classList.remove("invalid");
        this.requestUpdate();
    }

    render() {
        const font = { font: this.editorSize === "small" ? "var(--font-input-small)" : "var(--font-input)" };
        return html`
            <input
                style="${styleMap({ ...font, width: this.inputWidth })}"
                id="input"
                name="${this.name}"
                type="${this.type}"
                .valueAsNumber="${live(this.value)}"
                @input="${this.updateValue}"
                @change="${this.valueChanged}"
                min="${ifDefined(this.min)}"
                max="${ifDefined(this.max)}"
                maxlength="${ifDefined(this.maxlength)}"
                minlength="${ifDefined(this.minlength)}"
                step="${ifDefined(this.step)}"
                placeholder="${ifDefined(this.placeholder)}"
                ?required="${this.required}"
                ?disakled="${this.disabled}"
                autocomplete=${this.useAutoComplete ? "on" : "off"}
            />
        `;
    }

    static styles = css`
        :host {
            display: block;
        }

        input.invalid {
            outline: 2px solid pink;
        }
        #input {
            box-sizing: border-box;
        }
    `;
}
