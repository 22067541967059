import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import sanitizeHtml from "sanitize-html";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ConfigFileType } from "se-shared/enums/config-file-type";
import { ValidationStatus } from "../../enums/validation-status";
import { SharedFileInfo } from "../../models/config-display-info";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import "../components/secondary-button.element";
import { TextAreaEditorElement } from "../editors/textarea-editor.element";
import "../run/run-details.element";

@customElement("se-shared-file-info")
export class SeAgentInfoElement extends LitElement {
    private _authService: AuthService;
    private _userState: UserState;
    private _configService: ConfigService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];

    @property({ type: Number }) configId: number;
    @property({ attribute: false }) validationStatus: ValidationStatus;

    @state() private _name: string;
    @state() private _description?: string;
    @state() private _version?: number;
    @state() private _updated?: Date;
    @state() private _created?: Date;
    private _configDisplay: SharedFileInfo[] = [];
    @state() private _isEditDescription = false;
    private _editorSaving = false;

    @query("#description") private _descriptionEditor: TextAreaEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._configService = container.resolve(ConfigService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.setupDataGridAsync();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands) {
        if (!this._authService.isUser) {
            return commands.redirect("/login");
        }
        if (location.params.configId) {
            this.configId = parseInt(location.params.configId.valueOf() as string);
        } else {
            return commands.prevent();
        }
    }

    firstUpdated() {
        const options = {
            rootMargin: "0px",
            threshold: 1.0,
        };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0 && !this._name) {
                    this.onRefreshAsync();
                }
            });
        }, options);
        observer.observe(this);
    }

    private async onRefreshAsync() {
        const info = await this._configService.api.getConfigInfo(this.configId);
        if (info.isOk) {
            this._name = info.value.name;
            this._description = info.value.description === null ? null : sanitizeHtml(info.value.description.replace(/\n/g, "<br>"));
            this._version = info.value.version;
            this._updated = info.value.updated;
            this._created = info.value.created;
            if (info.value.displayJson) {
                this._configDisplay = JSON.parse(info.value.displayJson);
                //console.log(this._configDisplay);
            } else {
                this._configDisplay = undefined;
            }
        } else {
            this._toasterService.showNetworkError(info.err);
        }
    }

    private editFile(configId: number, configName: string) {
        Router.go(`/edit/shared-file/${configId}/editor?name=${configName}`);
    }

    private async setupDataGridAsync() {
        this._columns = [
            {
                field: "name",
                title: "File Name",
                template: (row: SharedFileInfo) => {
                    return html`${row.name}`;
                },
            },
            {
                field: "size",
                title: "Size",
                template: (row: SharedFileInfo) => html`${row.size}KB`,
            },
            {
                field: "lastModifiedUtc",
                title: "Last Modified",
                template: (row: SharedFileInfo) => html`${new Date(row.lastModifiedUtc + "Z").toLocaleString()}`,
            },
            {
                template: (row: SharedFileInfo) =>
                    row.fileType === ConfigFileType.Text
                        ? html`<button class=" row-button " ${htmlTitle("Edit")} @click=${() => this.editFile(this.configId, row.name)}>
                              <fa-icon single-color="" fa-class="far fa-pencil"></fa-icon>
                          </button>`
                        : html``,
            },
        ];
    }

    private editDescription() {
        this._isEditDescription = true;
    }

    private async onEditSaveAsync() {
        const result = await this._configService.api.updateDescriptionAsync(this.configId, this._descriptionEditor.liveValue);
        if (result.isOk) {
            this._description = sanitizeHtml(this._descriptionEditor.liveValue.replace(/\n/g, "<br>"));
            this._isEditDescription = false;
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    onEditCancel() {
        this._isEditDescription = false;
    }

    render() {
        return html`
            <div class="body">
                <div style="display:flex; gap: 20px">
                    <div style="max-width: 40%; min-width:200px; min-height:0;">
                        <se-data-grid class="grid" .rows=${this._configDisplay} .columns=${this._columns}></se-data-grid>
                    </div>
                    <div style="display:flex; flex-direction:column; min-width:100px">
                        <h3 style="margin-top:0">${this._name}</h3>
                        <div class="prop" style="flex-wrap: wrap">
                            <span class="prop-name">Type:</span><span>Shared File</span> <span class="prop-name">Version:</span
                            ><span>${this._version}</span> <span class="prop-name">Updated:</span
                            ><span>${new Date(this._updated).toLocaleDateString()}</span> <span class="prop-name">Created:</span
                            ><span>${new Date(this._created).toLocaleDateString()}</span>
                        </div>
                        ${this._isEditDescription
                            ? html`
                                  <se-textarea-editor
                                      id="description"
                                      style="margin-top:20px;"
                                      .value=${this._description}
                                      rows="4"
                                      placeholder="Enter description"
                                  ></se-textarea-editor>
                                  <div class="editor-div">
                                      <button
                                          class="button"
                                          ${htmlTitle("Save")}
                                          ?disabled=${this._editorSaving}
                                          @click=${() => this.onEditSaveAsync()}
                                      >
                                          <fa-icon fa-class="far fa-check"></fa-icon>
                                      </button>
                                      <button
                                          class="button"
                                          ${htmlTitle("Cancel")}
                                          ?disabled=${this._editorSaving}
                                          @click=${() => this.onEditCancel()}
                                      >
                                          <fa-icon fa-class="far fa-times"></fa-icon>
                                      </button>
                                  </div>
                              `
                            : this._description
                              ? html` <div style="position:relative;margin-top:20px">
                                    <div class="edit-div">
                                        ${unsafeHTML(this._description)}
                                        <button
                                            ?disabled=${this._editorSaving}
                                            class="button edit-icon"
                                            @mousedown=${this.editDescription}
                                            ${htmlTitle("Edit Description")}
                                        >
                                            <fa-icon fa-class="far fa-pencil"></fa-icon>
                                        </button>
                                    </div>
                                </div>`
                              : html` <div
                                    style="height:40px; margin-top:20px; padding-top:20px;border: 1px dashed Gainsboro;text-align: center;"
                                >
                                    <a style="font-size:0.75em;color:gray" href="javascript:;" @click=${this.editDescription}
                                        >Add description</a
                                    >
                                </div>`}
                    </div>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .prop {
            display: flex;
            gap: 5px;
            font-size: 0.75em;
            margin-top: 5px;
        }
        .prop-name {
            font-weight: bold;
        }
        .body {
            box-sizing: border-box;
            padding: 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: white;
            border-radius: 3px 3px;
            box-shadow: 2px 2px 2px lightGray;
            border-radius: 5px;
            border: 1px solid darkgray;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .button {
            border: 1px solid grey;
            border-radius: 3px 3px;
            background-color: white;
            transition:
                all 0.25s,
                opacity 0.5s;
            padding: 2px 4px;
            margin: 0;
        }
        .button:disabled {
            background-color: white;
            border: 1px solid lightgray;
            color: gray;
            transition: none;
        }
        .button:hover {
            box-shadow: 2px 2px 2px lightgray;
            border: 1px solid black;
        }
        .button:hover:disabled {
            border: 1px solid lightgray;
            box-shadow: none;
        }
        .button:active {
            box-shadow: none;
        }
        .editor-div {
            display: flex;
            flex-direction: row;
            justify-content: end;
            gap: 5px;
            width: 100%;
            margin-top: 2px;
        }
        div.edit-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            min-height: 1.4em;
        }
        div.edit-div:hover .edit-icon {
            opacity: 1;
        }
        .edit-icon {
            opacity: 0;
            position: absolute;
            right: 0px;
        }
    `;
}
