import { container, inject, singleton } from 'tsyringe';
import { SeEvent } from '../../utils/se-events';
import { AppConfigApi } from './app-config.api';
import { ConfigApi } from './config.api';

@singleton()
export class ConfigService {
        
    private _configApi: ConfigApi;
    get api() { return this._configApi };

    constructor() {        
        this._configApi = container.resolve(ConfigApi);
    }    

    async readTextFromFileAsync(file: File): Promise<string> {
        return new Promise<string>((resolve) => {
            if (!file) {
                resolve("");
            }

            const reader = new FileReader();

            reader.onload = () => {
                const text = reader.result.toString();
                resolve(text);
            };

            reader.readAsText(file);
        });
    }
}