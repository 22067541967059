export class ProxiesViewModel {
    poolId?: number;
    server?: string;
    username?: string;
    password?: string;

    /**
     * Create a new ProxiesViewModel given a proxy string. 
     * Break apart the string into the username, password,
     * and server.
     * @param proxy A string in the format of username:password@server or just server
     */
    constructor(server: string, username?: string, password?: string) {

        this.server = server;
        this.username = username;
        this.password = password;

    }
}