import { container, inject, singleton } from 'tsyringe';
import { AccessLevel } from '../enums/access-level';
import { LoginToken } from '../models/login';
import { UserAccess } from '../models/user-access';
import { AuthApi } from './auth.api';
import { UserApi } from './user-api';
import { err, ok } from 'se-shared/utils/result';
import { User } from '../models/user';
import { integer } from 'vscode-languageserver-protocol';
import { MultiFactorAuthApi } from './multi-factor-auth.api';

@singleton()
export class MultiFactorAuthService {
    private _multiFactorAuthApi: MultiFactorAuthApi;

    constructor() {
        this._multiFactorAuthApi = container.resolve(MultiFactorAuthApi);        
    } 

    async GetTwoFactorAuthentication() {
        const res = await this._multiFactorAuthApi.GetTwoFactorAuthentication();
        if (res.isOk) {
            return res;
        } else {
            throw res.err;
        }
    }
    async EnableTwoFactorAuthentication(securityCode: string) {
        const res = await this._multiFactorAuthApi.EnableTwoFactorAuthentication(securityCode);
        if (res.isOk) {
            return res;
        } else {
            throw res.err;
        }
    }
    async VerifyTwoFactorAuthentication(securityCode: string, multiFactorStamp: string, rememberMe: boolean) {
        const res = await this._multiFactorAuthApi.VerifyTwoFactorAuthentication(securityCode, multiFactorStamp, rememberMe);
        if (res.isOk) {
            return res;
        } else {
            throw res.err;
        }
    }
}
