import { container, inject,singleton } from 'tsyringe';
import { Label } from '../models/label';
import { Space } from '../models/space';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';

@singleton()
export class LabelApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<Label>(`/api/label/${id}`, this._authService.token);
    }

    async createAsync(label: Label) {
        return this.postObjectReturnObjectAsync<Label>(`/api/label`, label, this._authService.token);
    }

    async updateAsync(label: Label) {
        return this.putObjectAsync(`/api/label/${label.id}`, label, this._authService.token);
    }

    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/label/${id}`, this._authService.token);
    }

    async deleteAllAsync(labelIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/label/all`, { ids: labelIds }, this._authService.token);
    }
}