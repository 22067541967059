import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";

@customElement("se-loading-panel")
export class SeLoadingPanel extends LitElement {
    @property({ attribute: "loading-text" }) loadingText = "Loading...";
    @property({ type: "Boolean" }) isLoading = true;
    @property({ type: "Object" }) loadingStyle: any;

    stopLoading() {
        this.isLoading = false;
    }

    startLoading() {
        this.isLoading = true;
    }

    render() {
        return this.isLoading
            ? html`<slot name="loading"
                  ><div
                      style="display: flex; align-items: center;color: gray; font-size:0.95em;padding:10px; ${styleMap(this.loadingStyle)}"
                  >
                      <div style="display: flex; flex-direction: column; width: 100%; align-items: center;">
                          <span
                              ><fa-icon style="font-size:0.9em" fa-class="far fa-spinner fa-spin"></fa-icon>&nbsp;&nbsp;<span
                                  >${this.loadingText}</span
                              ></span
                          >
                      </div>
                  </div></slot
              >`
            : html`<slot id="main"></slot>`;
    }

    static styles = css`
        :host {
        }
    `;
}
