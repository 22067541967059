import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ConfigService } from "../../services/config.service";
import { ModalDialogService, ModelDialog } from "../../services/modal-editor.service";
import { UserState } from "../../services/user.state";
import { BaseEditor } from "../editors/base-editor";
import { InputEditorElement } from "../editors/input-editor.element";

@customElement("se-rename-dialog")
export class RenameDialogElement extends LitElement implements ModelDialog {
    private _modalService: ModalDialogService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _configService: ConfigService;

    @query("#configName") private _configName: InputEditorElement;

    private _configId: number;
    @state() private _defaultName: string;

    constructor() {
        super();

        this._userState = container.resolve(UserState);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
        this._configService = container.resolve(ConfigService);
    }

    async showDialog(options: { configId: number; defaultName: string }) {
        this._defaultName = options.defaultName;
        this._configId = options.configId;
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    updateChangedValues() {
        this.requestUpdate();
    }

    firstUpdated() {}

    private cancel(ev) {
        ev.preventDefault();
        this._modalService.close(false);
    }

    async saveAsync() {
        if (this.reportValidity()) {
            const result = await this._configService.api.renameAsync(this._configId, this._configName.liveValue);
            if (result.isOk) {
                this._userState.gridDataChanged.triggerVoid();
            } else {
                this._toasterService.showNetworkError(result.err);
            }

            this._modalService.close(true);
        }
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="header">
                    <div style="font-weight: bold">Rename Config</div>
                    <div class="icon" @click=${this.cancel}><fa-icon scale="1.2" fa-class="fas fa-times-circle"></fa-icon></div>
                </div>
                <div class="body">
                    <div class="container" id="editorContainer">
                        <se-input-editor id="configName" required label="New config name" .value="${this._defaultName}"></se-input-editor>
                    </div>
                    <div class="savePanel">
                        <se-primary-button .action="${() => this.saveAsync()}" action-delay="500" text="Rename"></se-primary-button>
                        <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                    </div>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
        }
        .editor {
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            margin: auto;
            margin-top: 20vh;
            box-shadow: 4px 4px 4px rgb(80, 80, 80);
            font: var(--font);
            width: 350px;
        }
        .body {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            padding: 0px;
            border-radius: 0px 0px 5px 5px;
            width: 100%;
        }
        .header {
            background-color: var(--color-light);
            padding: 5px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
            align-items: center;
            color: black;
            border-radius: 5px 5px 0px 0px;
            border-bottom: solid 1px whitesmoke;
        }
        .icon {
            color: gray;
        }
        .icon:hover {
            color: darkred;
        }
        .container {
            height: 100%;
            min-height: 60px;
            overflow: auto;
            padding: 15px;
            background-color: white;
            box-sizing: border-box;
            border-top: 1px solid gray;
            border-bottom: 1px solid gray;
            border-radius: 3px 3px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            padding: 8px 5px 10px 50px;
        }
    `;
}
