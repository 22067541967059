import { container, singleton } from "tsyringe";
import { CreditHistoryViewModel } from '../models/credit-history-view-model';
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";
import { OrgBilling } from '../models/org-billing';

@singleton()
export class BillingApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getTransactionsAsync( searchRequest: any) {
        return this.postObjectReturnObjectAsync<CreditHistoryViewModel>(`/api/billing/credit-history`, searchRequest, this._authService.token);
    }

    async getOrCreateCustomer() {
        return this.getObjectAsync<OrgBilling>(`/api/billing/customer?source=controlcenter`, this._authService.token);
    }
    async getAvailableCreditsAsync(orgId: number) {
        return this.getObjectAsync(`/api/billing/${orgId}/available-credits`, this._authService.token);
    }
    async getCheckoutSession(checkoutReq: any) {
        return this.postObjectReturnObjectAsync<string>(`/api/billing/checkout-session?source=controlcenter`, checkoutReq, this._authService.token);
    }

}
