import { BillingApi } from './billing.api';
import { container, singleton } from 'tsyringe';

@singleton()
export class BillingService {

    //private _userState: UserState;
    private _billingApi: BillingApi;

    get api() { return this._billingApi }
    
    constructor() {        
        //this._userState = container.resolve(UserState);
        this._billingApi = container.resolve(BillingApi);
    }    

}