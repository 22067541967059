import { LitElement, html, css } from 'lit';
import { customElement, query, state } from "lit/decorators.js";
import sadRobot from "../../../assets/sad_robot.png";

@customElement('se-not-found')
export class NotFoundPage extends LitElement {
    static styles = css`

    :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        font: var(--font)
    }

    .body{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container {
        display: flex;
        flex-direction: row;
    }

    h1 {
        font: var(--font-h1);
        font-size: 4rem;
        font-weight: 700;
        margin: 0;
    }

    h2 {
        font: var(--font-h2);
        font-size: 2rem;
        font-weight: 600;
    }
  `;

    render() {
        return html`
          <div class="body">
              <div class="container">
                <div class="text">  
                    <h1>404</h1>
                    <h2> Page Not Found</h2>
                    <div>Sorry, the page you are looking for can not be found.</div>
                </div>
                <img src="${sadRobot}" alt="Sad Robot" />
              </div>
          </div>
        `;
    }
}
