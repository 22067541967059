import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { MenuService } from "../../services/menu.service";
import { MenuItem } from "./menu.element";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";

@customElement("se-dropdown-button")
export class SeDropDownButton extends LitElement {
    private _menuService: MenuService;

    @property({ type: Array }) menu: MenuItem[] = [];
    @property() icon: string;
    @property() text: string;
    @property() ["show-menu-onclick"]: string;
    @property() title: string;
    @property({ type: Boolean }) disabled = false;

    @property({ attribute: "action-delay", type: Number }) actionDelay = 0;
    @property({ attribute: "action-text" }) actionText: string;
    @property({ attribute: "min-sizing" }) minSizing: "auto" | "growth" = "auto";
    @property({ type: Object }) action?: () => Promise<boolean>;

    @state() private _isAction = false;
    @state() private _isActionDisplay = false;

    @state() private _minWidth?: string;

    @query("#button") private _buttonElement: HTMLDivElement;

    constructor() {
        super();
        this._menuService = container.resolve(MenuService);
    }

    connectedCallback() {
        super.connectedCallback();
        addEventListener("pageshow", () => this.reset());
    }

    disconnectedCallback() {
        this.removeEventListener("pageshow", () => this.reset());
        super.disconnectedCallback();
    }

    private reset() {
        this._isAction = false;
        this._isActionDisplay = false;
    }

    async onMouseDown() {
        if (this._menuService.isMenuOpen(this._buttonElement)) return;

        if (this.action) {
            this.doAction(this.action);
        } else {
            const event = new Event("buttonclick", { bubbles: true, composed: true });
            this.dispatchEvent(event);
        }
    }

    async doAction(action: () => Promise<boolean>) {
        this._isAction = true;
        this._minWidth = this.minSizing === "auto" ? undefined : this._buttonElement.offsetWidth + "px";
        await this.updateComplete;
        const timeout =
            this.actionDelay > 0
                ? setTimeout(() => {
                      this._isActionDisplay = true;
                  }, 1000)
                : undefined;
        if (!(await action())) {
            //action returns true if the website has redirected to a new page or false if stayed on the current page.
            this._isAction = false;
            this._isActionDisplay = false;
        }
        if (timeout) {
            clearTimeout(timeout);
        }
    }

    onOpenMenu(event: Event) {
        event.stopPropagation();
        this._menuService.openContextMenu({ button: this._buttonElement, activeClass: "button-active", menu: this.menu });
    }
    onClick(event: Event) {
        event.stopPropagation();
    }

    render() {
        if ((this.icon || this._isActionDisplay) && this["show-menu-onclick"])
            return html`
                <div
                    class="button ${classMap({ disabled: this.disabled || this._isAction })}"
                    id="button"
                    @mousedown="${this.onOpenMenu}"
                    @click=${this.onClick}
                >
                    <button
                        ?disabled=${this.disabled || this._isAction}
                        title="${this.title}"
                        class="inner-button left"
                        style="display: flex; gap: 5px"
                        type="button"
                    >
                        <fa-icon fa-class="${this._isActionDisplay ? "far fa-spinner fa-spin" : this.icon}" scale="0.9"></fa-icon
                        ><span>${this._isActionDisplay ? this.actionText ?? this.text ?? "" : this.text ?? ""}</span>
                    </button>
                    <button
                        ?disabled=${this.disabled || this._isAction}
                        @mousedown="${this.onOpenMenu}"
                        title="${this.title}"
                        class="inner-button menu"
                        type="button"
                    >
                        <fa-icon fa-class="fas fa-caret-down"></fa-icon>
                    </button>
                </div>
            `;
        else if (this.icon || this._isActionDisplay)
            return html`
                <div
                    class="button ${classMap({ disabled: this.disabled || this._isAction })}"
                    style="${styleMap({ minWidth: this._minWidth })}"
                    id="button"
                    @mousedown="${this.onMouseDown}"
                    @click=${this.onClick}
                >
                    <button
                        ?disabled=${this.disabled || this._isAction}
                        title="${this.title}"
                        class="inner-button left"
                        style="display: flex; gap: 5px"
                        type="button"
                    >
                        <fa-icon fa-class="${this._isActionDisplay ? "far fa-spinner fa-spin" : this.icon}" scale="0.9"></fa-icon
                        ><span>${this._isActionDisplay ? this.actionText ?? this.text ?? "" : this.text ?? ""}</span>
                    </button>
                    <button
                        ?disabled=${this.disabled || this._isAction}
                        @mousedown="${this.onOpenMenu}"
                        title="${this.title}"
                        class="inner-button menu"
                        type="button"
                    >
                        <fa-icon fa-class="fas fa-caret-down"></fa-icon>
                    </button>
                </div>
            `;
        else if (this["show-menu-onclick"])
            return html`
                <div
                    class="button ${classMap({ disabled: this.disabled || this._isAction })}"
                    style="${styleMap({ minWidth: this._minWidth })}"
                    id="button"
                    @mousedown="${this.onOpenMenu}"
                    @click=${this.onClick}
                >
                    <button ?disabled=${this.disabled || this._isAction} title="${this.title}" class="inner-button left" type="button">
                        ${this.text}
                    </button>
                    <button
                        ?disabled=${this.disabled || this._isAction}
                        @mousedown="${this.onOpenMenu}"
                        title="${this.title}"
                        class="inner-button menu"
                        type="button"
                    >
                        <fa-icon fa-class="fas fa-caret-down"></fa-icon>
                    </button>
                </div>
            `;
        else
            return html`
                <div
                    class="button ${classMap({ disabled: this.disabled || this._isAction })}"
                    style="${styleMap({ minWidth: this._minWidth })}"
                    id="button"
                    @mousedown="${this.onMouseDown}"
                    @click=${this.onClick}
                >
                    <button ?disabled=${this.disabled || this._isAction} title="${this.title}" class="inner-button left" type="button">
                        ${this.text}
                    </button>
                    <button
                        ?disabled=${this.disabled || this._isAction}
                        @mousedown="${this.onOpenMenu}"
                        title="${this.title}"
                        class="inner-button menu"
                        type="button"
                    >
                        <fa-icon fa-class="fas fa-caret-down"></fa-icon>
                    </button>
                </div>
            `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            box-sizing: border-box;
        }

        .inner-button {
            padding: 0;
            font: var(--font-button);
            background-color: var(--color-secondary);
            color: white;
            border: none;
            vertical-align: middle;
        }

        .menu {
            padding: 3px 11px;
        }

        .left {
            padding: 5px 3px 5px 10px;
        }

        .menu:hover {
            cursor: pointer;
        }

        .button {
            cursor: default;
            display: flex;
            border-radius: 3px 3px;
            transition: all 0.25s;
            font: var(--font);
            background-color: var(--color-secondary);
            border: 1px solid var(--color-secondary-shade);
            color: white;
            vertical-align: top;
        }
        .button.disabled {
            opacity: 0.7;
            background-color: var(--color-secondary);
            border: 1px solid gray;
            color: lightgray;
            transition: none;
        }
        .button:hover {
            background-color: var(--color-secondary);
            border: 1px solid var(--color-secondary);
            box-shadow: 2px 2px 2px DarkGray;
        }
        .button.disabled:hover {
            background-color: var(--color-secondary);
            border: 1px solid gray;
            box-shadow: none;
        }
        .button:active {
            box-shadow: none;
        }
        .button-active:hover {
            box-shadow: none;
        }
    `;
}
