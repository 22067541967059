import { container, singleton } from "tsyringe";
import { ConfigVersionModel } from "../models/config-version-model";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";

@singleton()
export class ConfigVersionHistoryApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getManyAsync(configId: number) {
        return this.getObjectAsync<ConfigVersionModel[]>(`/api/config/${configId}/versions`, this._authService.token);
    }
    async restoreAsync(versionId: number, comments: string) {
        return this.postObjectAsync(`/api/config-version-history/${versionId}/restore`, { content: comments }, this._authService.token);
    }
    async deleteManyAsync(configId: number, versionIds: number[]) {
        return this.deleteAllObjectsAsync(
            `/api/config-version-history/config/${configId}/many`,
            { ids: versionIds },
            this._authService.token
        );
    }
    async deleteAsync(versionId: number) {
        return this.deleteObjectAsync(`/api/config-version-history/${versionId}`, this._authService.token);
    }
    async downloadVersionAsync(versionId: number) {
        console.log("downloadSharedFileAsync 1");
        return this.getBlobAsync<any>(`/api/config-version-history/${versionId}/view`, this._authService.token);
    }
    async updateCommentsAsync(versionId: number, comments: string) {
        return this.postObjectAsync(`/api/config-version-history/${versionId}/comments`, { content: comments }, this._authService.token);
    }
}
