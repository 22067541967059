export class ApiKey {
    constructor() {}

    public id?: number;
    public userId?: number;
    public userName?: string;
    public expiry?: string;
    public description?: string;
    public token?: string;
    public created?: string;    
}
