import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement, unsafeCSS } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import googleSigninDark from "../../../assets/google_signin_dark.png";
import instagram from "../../../assets/instagram.svg";
import linkedin from "../../../assets/linkedin.svg";
import logo from "../../../assets/logo-tagline.svg";
import msSignin from "../../../assets/ms-signin.png";
import twitter from "../../../assets/twitter.svg";
import { AppConfigService } from "../services/app-config.service";
import { AuthService } from "../services/auth.service";
import "./components/primary-button.element";
import "./components/secondary-button.element";
import { BaseEditor } from "./editors/base-editor";
import "./editors/checkbox-editor.element";
import { CheckboxEditorElement } from "./editors/checkbox-editor.element";
import "./editors/input-editor.element";
import { InputEditorElement } from "./editors/input-editor.element";
import backgroundMaze from '../../../assets/backgroundMaze.png';


@customElement("se-forgot-password")
export class ForgotPasswordElement extends LitElement {
    private _appConfigService: AppConfigService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _message: string;

    @query("#email") private _emailEditor: InputEditorElement;
    @query("#password") private _passwordEditor: InputEditorElement;
    @query("#stayLoggedIn") private _stayLoggedInEditor: CheckboxEditorElement;
    @state() private _hasSent = false;

    constructor() {
        super();

        this._appConfigService = container.resolve(AppConfigService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        {
            this._message = new URLSearchParams(location.search).get("message");
            return undefined;
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
    }
    disconnectedCallback() {
        this.removeEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        super.disconnectedCallback();
    }

    private onEditorChanged(ev) {}

    updateChangedValues() {}

    firstUpdated() {
        if (this._message) {
            this._toasterService.showWarning(this._message);
        }
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async recoveryEmail() {
        if (this.reportValidity()) {
            //todo send email
            await this._authService
                .RecoverPassword(this._emailEditor.liveValue)
                //await this._authService.RecoverPassword(this._emailEditor.liveValue, this.recoveryHtml())
                .then(() => this.success())
                .catch((err) => this.error(err));
        }
    }
    /*
    private async recoveryEmail(ev) {
        ev.preventDefault();
        Router.go("/register");
        
    }
*/

    private async login(ev) {
        ev.preventDefault();
        Router.go("/login");
    }

    private success() {
        this._toasterService.clear();
        this._hasSent = true;
        //Router.go("/");
    }

    private error(err: Error) {
        this._toasterService.clear();
        this._toasterService.showError(err.message);
    }
    /*
    private recoveryHtml() {
        var html: string;
       return  html = `
                <html>
                    <head>
                        <style>
                            header {padding: 30px;}
                            body {background-color: #1A1E4F;}
                            .logo {display: block; margin-left: auto; margin-right: auto; width: 50%;}
                            .container {display: block; margin-left: auto; margin-right: auto; width: 50%; background-color: white; padding: 3%; border-radius: 10px;}
                            a {color: #5248F9;}
                        </style>
                    </head>
                    <body>
                        <header>
                            <img src="${white_color_logo}" alt="Sequentum" class="logo">
                        </header>
                        <main>
                            <div class="container">
                                Please use the following link to reset your password: <a href="[link]">Reset password</a> <br>
                                If you did not request a password change, please feel free to ignore this message. <br><br>

                                If you have any comments or questions don't hesitate to reach us at <a href="#">[email to customer portal support team]</a>
                            </div>
                        </main>
                    </body>
                </html>
                `
        //return html;
    }
    */
    render() {
        return html`            
            <div class="header"></div>
            <form id="editorForm" class="editor">
                <div class="scroll-container">
                    ${
                        this._hasSent
                            ? html`<div style="font: var(--font);margin-bottom:10px;margin-left:10px">
                                      An email with instructions on how to reset your password has been sent to
                                      <span style="font-weight:bold">${this._emailEditor.liveValue}</span>.
                                  </div>
                                  <div class="linkLabel">
                                      <a href="Forgot Password" @click="${this.login}">Return to Login</a>
                                  </div>`
            : html` <div class="content"> 
                        <div style="text-align: center">
                            <img src=${logo} class="header-logo" />
                        </div>
                        <se-input-editor enableAutocomplete .tabindex=${"1"} class="inputEditor" id="email" name="email" size="normal" type="email" placeholder="example@company.com" label="Email" labelPosition="top" input-type="email" required ></se-input-editor>
                        <se-primary-button class="inputEditor loginButton" @click="${this.recoveryEmail}" text="Send Recovery Email" size="normal" position="first"></se-primary-button>
                        <div class="loginLink">
                            <a class="link" href="Forgot Password" @click="${this.login}">Return to Login</a>
                        </div>
                     </div>`
                    }                    
                </div>
                
                <div class="authenticationProviders" style="display: none">
                    <hr style="height:2px;width: 100%;margin-top: 15px; background-color:lightgray;border-width:0">
                    <div style="display: flex; align-items: center;justify-content: space-between;">
                        <input type="image" src=${msSignin} style="height: 30px"></input>
                        <input type="image" src=${googleSigninDark} style="height: 32px"></input>
                    </div>
                </div>
            </form>
            <div class="footer"><div>© 2022 Sequentum  •  <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a>  •  <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a></div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter}></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin}></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram}></a>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            font: Inter; background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
            background-size: cover;
        }
        .header {
            box-sizing: border-box;
            background-color: var(--color-primary);
            color: white;
            padding-left: 15px;
            padding-top: 15px;
            display: flex;
            gap: 15px;
            font: var(--font-h3);
        }
        .h3 {
            font: var(--font-h3);
            background-color: var(--color-primary);
            padding: 10px 10px 10px 10px;
        }
        .header-logo {
            width: 165px;
            height: fit-content;
            margin: 27px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0px;
            padding-top: 0px;
            border-radius: 5px;
        }
        .scroll-container {
            min-height: 0px;
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 40px 90px;
        }
        .link {
            color: var(--color-purple-dark);
            text-decoration: none;
        }
        .loginLink {
            display: flex;
            justify-content: center;
            padding: 10px;
            font: var(--font-button);
        }
        .loginButton {
            margin-top: 10px;
        }
        .button {
            width: 100%;
            padding: 5px 10px 10px 10px;
            align-items: center;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
        .primary-button {
            display: flex;
            flex-direction: rows;
            justify-content: space-evenly;
            margin-top: 5px;
        }
        .linkLabel {
            font: var(--font-small);
            padding-left: 10px;
            text-decoration: none;

            align-items: center;
        }
        .content {
            width: 300px;
            gap: 10px;
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .footer {
            color: rgba(83, 86, 122, 1);
            font: var(--font-small);
            padding: 0px 20px 10px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .footer a {
            color: rgba(83, 86, 122, 1);
            text-decoration: none;
        }
        .footer a:hover {
            color: var(--color-secondary);
            text-decoration: none;
        }
    `;
}
