import { PreventAndRedirectCommands, RedirectResult, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { UserState } from "../../services/user.state";
import { classMap } from "lit/directives/class-map.js";
import { BillingService } from "../../services/billing.service";
import { ToasterService } from "se-shared/services/toaster.service";
import "./subscriptions.element";

@customElement("se-billing")
export class SeBillingElement extends LitElement {
    private _authService: AuthService;
    private _userState: UserState;
    private _billingService: BillingService;
    @state() private _isCustomerExist = false;
    @state() private _orgBilling: any;
    private _toasterService: ToasterService;

    @state() private _selectedTab = "usage";

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userState = container.resolve(UserState);
        this._billingService = container.resolve(BillingService);
        this._toasterService = container.resolve(ToasterService);
        this.getOrCreateCustomer();
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.checkoutStatus) {
            const status = location.params.checkoutStatus.valueOf() as string;
            if (status === "success") {
                console.log("Checkout Payment successful..");
                this._toasterService.showSuccess("Payment successfull.");
            }

            if (status === "cancel") {
                console.log("Checkout Payment cancelled..");
                this._toasterService.showWarning("Payment cancelled.");
            }
            history.replaceState(null, null, "/usage");
        }
    }

    firstUpdated() {}

    private tabUsage() {
        this._selectedTab = "usage";
        history.pushState(null, null, "/billing/" + this._selectedTab);
    }
    private tabTransactions() {
        this._selectedTab = "transactions";
        history.pushState(null, null, "/billing/" + this._selectedTab);
    }
    private tabSubscriptions() {
        this._selectedTab = "subscriptions";
        history.pushState(null, null, "/billing/" + this._selectedTab);
    }
    async getOrCreateCustomer() {
        const result = await this._billingService.api.getOrCreateCustomer();
        if (result.isOk) {
            this._orgBilling = result.value;
            this._isCustomerExist = true;
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }
    private async handleCheckout() {
        try {
            const response = await this._billingService.api.getCheckoutSession({
                orgId: this._orgBilling.orgId,
                customerId: this._orgBilling.customerId,
            });
            if (response.isOk) {
                window.location.href = response.value; // Redirect to the session URL
            } else {
                this._toasterService.showUnexpectedError("Could not create a checkout session");
            }
        } catch (error) {
            this._toasterService.showUnexpectedError("Could not create a checkout session");
        }
        this.requestUpdate();
    }

    render() {
        return html`
            <div class="body">
                <div class="tabs-header">
                    <span @click=${this.tabUsage} class="tabs ${classMap({ "active-tab": this._selectedTab == "usage" })}">Usage</span>
                    <span @click=${this.tabTransactions} class="tabs ${classMap({ "active-tab": this._selectedTab == "transactions" })}"
                        >Transactions</span
                    >
<!--                    <span @click=${this.tabSubscriptions} class="tabs ${classMap({ "active-tab": this._selectedTab == "subscriptions" })}"
                        >Subscriptions</span
                    >-->
                    <se-primary-button
                        @click=${this.handleCheckout}
                        style="margin-left: auto;"
                        class="tabs inputEditor"
                        text="Buy Credits"
                    ></se-primary-button>
                </div>
                ${this._selectedTab == "usage" ? html`<se-usage></se-usage>` : ``}
                ${this._selectedTab == "transactions" ? html`<se-transactions></se-transactions>` : ``}
                ${this._selectedTab == "subscriptions" ? html`<se-subscriptions></se-subscriptions>` : ``}
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .tabs-header {
            display: flex;
            border-bottom: solid 1px gray;
            padding-bottom: 5px;
            align-items: end;
            overflow-x: clip;
        }
        .tabs {
            padding: 0px 10px 5px;
            cursor: pointer;
            margin-bottom: -5px;
        }
        .active-tab {
            border-bottom: 5px solid black;
            font-weight: 900;
            margin-bottom: -10px;
        }
        .top-tabs {
            margin: 0px;
            padding: 0px;
            list-style: none;
            box-sizing: border-box;
            display: flex;
            gap: 0px;
            align-items: end;
            font: var(--font-tab);
        }
    `;
}
