import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';

@customElement('se-secondary-button-link')
export class SeSecondaryButtonLink extends LitElement {    

    @property() customStyle: any = {};
    @property({ type: Boolean }) download = false;
    @property() href: string;
    @property() icon: string;
    @property() iconColor: string;    
    @property() text: string;
    @property() title: string;    
    @property({ type: Boolean }) redHover = false;
    @property({ type: Boolean }) disabled = false;
    @property({ type: Boolean }) active = false;

    firstUpdated() {        
    }
    
    render() {
        const style = this.active ? (this.redHover ? {
            ...this.customStyle, ...{
                backgroundColor: 'crimson',
                color: 'white',
                border: '1px solid crimson'
            }
        } : {
            ...this.customStyle, ...{backgroundColor: 'white',
                boxShadow: 'none',
                border: '1px solid black'
            }
        }) : this.customStyle;

        if (this.icon && this.text)
            return html`    
                <a ?disabled=${this.disabled} href="${this.href}" title="${this.title}" class="button ${classMap({ red: this.redHover })}" style="display: flex; align-items: center;${styleMap(style)}"><fa-icon style="font-size:0.9em" fa-class="${this.icon}" single-color="${this.iconColor}" ?download=${this.download}></fa-icon>${this.text ? '&nbsp;' + this.text : ''}</a>
            `;        
        else if (this.icon)
            return html`    
                <a ?disabled=${this.disabled} href="${this.href}" title="${this.title}" class="button ${classMap({ red: this.redHover })}" style="${styleMap(style)}"><fa-icon style="font-size:0.9em" fa-class="${this.icon}" single-color="${this.iconColor}" ?download=${this.download}></fa-icon></a>
            `;        
        else
            return html`    
                <a ?disabled=${this.disabled} href="${this.href}" title="${this.title}" class="button ${classMap({ red: this.redHover })}" style="display:block; ${styleMap(style)}" ?download=${this.download}>${this.text}</a>
            `;
    }

    static styles = css`
    :host([hidden]) {
        display: none;
    }
    :host {
    }
    .button {       
        padding: 2px 10px;
        margin-left: 5px;
        border-radius: 3px 3px;
        transition: all 0.25s;
        font: var(--font-button);
        background-color: var(--primary-color);
        border: 1px solid gray;
        color: black;
        cursor: pointer;
    }
    .button:disabled {
        opacity: 1;
        background-color: var(--primary-color);
        border: 1px solid lightgray;
        color: gray;
        transition: none;
    }
    .button:hover {
        border: 1px solid black;
        background-color: white;
        box-shadow: 2px 2px 2px lightgray;
    }
    .button.red:hover {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }
    .button:hover:disabled {
        background-color: var(--primary-color);
        border: 1px solid lightgray;
        box-shadow: none;
    }
    .button:active {
        background-color: white;
        box-shadow: none;
    }
    .button.red:active {
        background-color: crimson;
        color: white;
        border: 1px solid crimson;
    }
  `;
}