import { ServerStatus } from "../enums/server-status";

export class ServerViewModel {
        
    constructor() {        
    }
    
    name?: string;
    description?: string;
    host?: string;
    isSsl?: boolean;
    serverGroupId?: number;
    organizationId?: number;
    sessionThreshold?: number;
    cpuThreshold?: number;
    memThreshold?: number;    
}