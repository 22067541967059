import { container, singleton } from 'tsyringe';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { ServerKeysViewModel } from '../models/server-keys-view-model';
import { ServersKeysViewModel } from '../models/servers-keys-view-model';


@singleton()
export class ServerKeysApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<ServerKeysViewModel>(`/api/oauth/${id}/server-key`, this._authService.token);
    }

    async GetAllServerKeys(pageIndex = 1, recordsPerPage = 100, sortOrder: number = -1) {
        return this.getObjectAsync<ServersKeysViewModel>(`/api/oauth/server-keys?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortOrder=${sortOrder}`, this._authService.token);
    }

    async createOrUpdateServerKeyAsync(serverKey: ServerKeysViewModel) {
        return this.postObjectReturnObjectAsync<ServerKeysViewModel>(`/api/oauth/create-key`, { clientName: serverKey.clientName, clientId: serverKey.clientId }, this._authService.token);
    }

    async generateSecretKeyAsync(clientId: string) {
        return this.postObjectReturnObjectAsync(`/api/oauth/${clientId}/secret-key`, undefined, this._authService.token);
    }

    async revokeServerKeyAsync(id: number) {
        return this.postObjectAsync(`/api/oauth/${id}/revoke`, undefined, this._authService.token);
    }

    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/oauth/${id}`, this._authService.token);
    }

    async deleteAllAsync(serverIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/oauth/all`, { ids: serverIds }, this._authService.token);
    }
}