import { ConfigFileType } from "se-shared/enums/config-file-type";
import { DisplaySchema } from "./display-schema";

export enum AgentDisplayInfoTracking {
    None = "None",
    ChangeTracking = "ChangeTracking",
    DuplicateTracking = "DuplicateTracking",
}

export class AgentDisplayInfo {
    schema?: DisplaySchema;
    tracking?: AgentDisplayInfoTracking;
    successCriteria?: SuccessCriteria;
}

export class SuccessCriteria {
    minPageLoads: number;
    allowedPageLoadVariation: number;
    minDataCount: number;
    allowedDataCountVariation: number;
    minExportCount: number;
    allowedExportCountVariation: number;
    maxErrors: number;
    allowedErrorVariation: number;
}

export class SharedFileInfo {
    name: string;
    size: number;
    lastModifiedUtc: string;
    fileType: ConfigFileType;
}
