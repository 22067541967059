import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ServerType } from "../../enums/server-type";
import { idName } from "../../models/id-name";
import { ServerViewModel } from "../../models/server-view-model";
import { AuthService } from "../../services/auth.service";
import { OrganizationService } from "../../services/organization.service";
import { ServerService } from "../../services/server.service";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { NumberEditorElement } from "../editors/number-editor.element";
import { SelectEditorElement } from "../editors/select-editor.element";
import { TextAreaEditorElement } from "../editors/textarea-editor.element";

@customElement("se-editor-server-editor")
export class SeServerEditorElement extends LitElement {
    private _toasterService: ToasterService;
    private _authService: AuthService;
    private _serverService: ServerService;
    private _orgService: OrganizationService;
    private _isNew = false;
    private _serverId?: number;
    private _server: ServerViewModel;
    private _orgs: idName[];

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#description") private _descriptionEditor: TextAreaEditorElement;
    @query("#ipAddress") private _hostEditor: InputEditorElement;
    @query("#organization") private _organizationEditor: SelectEditorElement;
    @query("#sessions") private _sessionsEditor: NumberEditorElement;
    @query("#cpu") private _cpuEditor: NumberEditorElement;
    @query("#mem") private _memEditor: NumberEditorElement;
    @query("#isSsl") private _isSslEditor: CheckboxEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._serverService = container.resolve(ServerService);
        this._orgService = container.resolve(OrganizationService);
        this._toasterService = container.resolve(ToasterService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.serverId) {
            this._serverId = parseInt(location.params.serverId.valueOf() as string);
            this._isNew = false;
            //return this._spaceApi.getAsync(this._spaceId).then(space => this._space = space).catch(err => this.error(err));
            const res = await this._serverService.api.getAsync(this._serverId);
            if (res.isOk) {
                this._server = res.value;
            } else {
                alert(res.err.message);
                return commands.prevent();
            }
        } else {
            this._isNew = true;
            this._server = new ServerViewModel();
        }
        const orgRes = await this._orgService.api.getAllIdAndNamesAsync();
        if (orgRes.isOk) {
            this._orgs = orgRes.value;
            this._orgs.splice(0, 0, { id: 0, name: "Shared" });
        } else {
            alert(orgRes.err.message);
            return commands.prevent();
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            const server = {
                name: this._nameEditor.liveValue,
                description: this._descriptionEditor.liveValue,
                host: this._hostEditor.liveValue,
                organizationId: parseInt(this._organizationEditor.liveValue),
                isSsl: this._isSslEditor.liveValue,
                type: ServerType.Editor,
            };
            const res = this._isNew
                ? await this._serverService.api.createAsync(server)
                : await this._serverService.api.adminUpdateAsync(this._serverId, server);
            if (res.isOk) {
                const connectRes = await this._serverService.api.connectAsync(res.value.id);
                if (!connectRes.isOk) this._toasterService.showNetworkError(connectRes.err);
                history.back();
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "Connect New" : "Edit"} Server</div>
                <div class="scroll-container">
                    <se-input-editor
                        id="name"
                        name="name"
                        type="text"
                        label="Name"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._server.name}
                    ></se-input-editor>
                    <se-textarea-editor
                        id="description"
                        name="description"
                        type="text"
                        label="Description"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._server.description ?? ""}
                    ></se-textarea-editor>
                    <se-input-editor
                        id="ipAddress"
                        name="ipAddress"
                        type="text"
                        label="Host"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._server.host}
                    ></se-input-editor>
                    <se-checkbox-editor
                        id="isSsl"
                        name="isSsl"
                        label="SSL"
                        labelPosition="right"
                        .value=${this._server.isSsl}
                    ></se-checkbox-editor>
                    <se-select-editor
                        required
                        id="organization"
                        name="organization"
                        label="Select organization"
                        labelPosition="top"
                        .value=${this._server.organizationId ? this._server.organizationId.toString() : "0"}
                        .options=${this._orgs}
                        width="100%"
                    ></se-select-editor>
                </div>
                <div class="savePanel">
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Add" : "Save"} Server"
                    ></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
