import { container, inject,singleton } from 'tsyringe';
import { integer } from 'vscode-languageserver-protocol';
import { MultiFactorAuthentication } from '../models/multi-factor-authentication';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';

@singleton()
export class MultiFactorAuthApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async GetTwoFactorAuthentication() {
        return this.getObjectAsync<MultiFactorAuthentication>(`/api/auth/twofactor`, this._authService.token);
    }
    async EnableTwoFactorAuthentication(securityCode: string) {
        return this.postObjectReturnObjectAsync<boolean>(`/api/auth/enabletwofactor/${securityCode}`, {}, this._authService.token);
    }
    async VerifyTwoFactorAuthentication(securityCode: string, multiFactorStamp: string, rememberMe: boolean) {
        const data = JSON.parse(`{"securityCode":"${securityCode}","multiFactorStamp":"${multiFactorStamp}","rememberMe":${rememberMe} }`);    
        return this.postObjectReturnObjectAsync<boolean>(`/api/auth/verifytwofactor`, data, this._authService.token);
    }    
}