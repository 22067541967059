import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement, unsafeCSS } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import instagram from "../../../assets/instagram.svg";
import linkedin from "../../../assets/linkedin.svg";
import logo from "../../../assets/logo-tagline.svg";
import msSignin from "../../../assets/ms-signin.png";
import twitter from "../../../assets/twitter.svg";
import { LoginToken } from "../models/login";
import { AppConfigService } from "../services/app-config.service";
import { AuthService } from "../services/auth.service";
import "./components/primary-button.element";
import { SePrimaryButton } from "./components/primary-button.element";
import "./components/secondary-button.element";
import { BaseEditor } from "./editors/base-editor";
import "./editors/checkbox-editor.element";
import { CheckboxEditorElement } from "./editors/checkbox-editor.element";
import "./editors/input-editor.element";
import { InputEditorElement } from "./editors/input-editor.element";
import backgroundMaze from "../../../assets/backgroundMaze.png";

@customElement("se-login")
export class SeLoginElement extends LitElement {
    private _appConfigService: AppConfigService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _message: string;
    private _currentYear: number;

    @query("#email") private _emailEditor: InputEditorElement;
    @query("#password") private _passwordEditor: InputEditorElement;
    @query("#stayLoggedIn") private _stayLoggedInEditor: CheckboxEditorElement;
    @query("#signInWithGoogleDiv") private _signInWithGoogleDiv: HTMLDivElement;
    @query("#loginButton") private _loginButton: SePrimaryButton;
    @state() private _hasChanged = false;

    constructor() {
        super();

        this.submitLogin = this.submitLogin.bind(this);
        this.save = this.save.bind(this);

        this._appConfigService = container.resolve(AppConfigService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);

        //this._onLoad = this._onLoad.bind(this);
        //document.addEventListener("load", this._onLoad, true);
        this._currentYear = new Date().getFullYear();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (this._appConfigService.isNew) {
            return commands.redirect("/create-first-user");
        } else {
            this._message = new URLSearchParams(location.search).get("message");
            return undefined;
        }
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("keydown", this.submitLogin);
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
    }
    disconnectedCallback() {
        this.removeEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        super.disconnectedCallback();
    }
    submitLogin(event) {
        if (event.key === "Enter") {
            this._loginButton.onClick();
        }
    }
    private _onLoad() {
        if (this._appConfigService.isGoogleAuthenticationSupport) {
            this.googleInit();
        }
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }

    updateChangedValues() {}

    async firstUpdated() {
        if (this._message) {
            this._toasterService.showWarning(this._message);
        }
        await this.updateComplete;
        this._onLoad()
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async save() {
        this._toasterService.clear();
        if (this.reportValidity()) {
            //start loading animation
            this._loginButton.loading = true;
            await this._authService
                .loginAsync(this._emailEditor.liveValue, this._passwordEditor.liveValue, true)
                .then((res) => this.loginAsyncSuccess(res.value))
                .catch((err) => this.error(err))
                .finally(() => this._loginButton.loading = false);
        }
    }
    private async register(ev) {
        ev.preventDefault();
        Router.go("/register");
    }
    private async forgotPassword(ev) {
        ev.preventDefault();
        Router.go("/forgot-password");
    }

    private loginAsyncSuccess(res: LoginToken) {
        if (res.userAccess.isMfaRequired && !res.isTwoFactorVerified) {
            if (!res.userAccess.isMfaEnabled) {
                Router.go("/multi-factor-authentication-setup");
            } else {
                Router.go("/multi-factor-authentication?rememberme=true"); // + this._stayLoggedInEditor.liveValue);
            }
        } else {
            Router.go("/");
        }
    }

    private loginWithGoogleIdTokenSuccess(res: LoginToken) {
        Router.go("/");
    }

    private error(err: Error) {
        this._toasterService.showError(err.message);
    }

    private googleInit() {
        this.renderSignInWithGoogleButton();
    }

    private renderSignInWithGoogleButton() {
        const clientId = this._appConfigService.googleAuthenticationClientId;
        const signInWithGoogleDivElement = this._signInWithGoogleDiv;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (google) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            google.accounts.id.initialize({
                client_id: clientId,
                callback: this.handleGoogleCredentialResponse.bind(this),
                auto_select: false,
                cancel_on_tap_outside: true,
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            google.accounts.id.renderButton(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                signInWithGoogleDivElement,
                { type: "standard", theme: "filled_blue", size: "medium", width: 158 }
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // google.accounts.id.prompt((notification: PromptMomentNotification) => { });
        }
    }

    async handleGoogleCredentialResponse(credentialResponse) {
        if (credentialResponse) {
            if (credentialResponse.credential) {
                this._authService
                    .loginWithGoogleIdToken(credentialResponse.credential)
                    .then((res) => this.loginWithGoogleIdTokenSuccess(res.value))
                    .catch((err) => this.error(err));
            } else {
                this._toasterService.showError("Unable to login.", "Error");
            }
        } else {
            this._toasterService.showError("Unable to login.", "Error");
        }
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="scroll-container">
                    <div class="content">
                        <div style="text-align: center;margin-bottom: 10px;">
                            <img src=${logo} class="header-logo" />
                        </div>
                        <se-input-editor
                            enableAutocomplete
                            .tabindex=${"1"}
                            class="inputEditor"
                            id="email"
                            name="email"
                            size="normal"
                            type="email"
                            placeholder="example@company.com"
                            label="Email"
                            labelPosition="top"
                            input-type="email"
                            required
                        ></se-input-editor>
                        <div class="password-container">
                            <se-input-editor
                                enableAutocomplete
                                .tabindex=${"2"}
                                class="inputEditor"
                                size="normal"
                                id="password"
                                name="password"
                                type="password"
                                label="Password"
                                labelPosition="top"
                                input-type="password"
                                required
                            ></se-input-editor>
                            <a href="" class="link forgot" @click="${this.forgotPassword}">Forgot?</a>
                        </div>
                        <se-primary-button
                            id="loginButton"
                            class="inputEditor loginButton"
                            .action="${() => this.save()}"
                            action-delay="500"
                            text="Login"
                            position="first"
                            size="normal"
                        ></se-primary-button>
                        <div class="loginLink">
                            <a href="" class="link" @click="${this.register}">Register</a>
                        </div>
                    </div>
                    ${this._appConfigService.isGoogleAuthenticationSupport || this._appConfigService.isMicrosoftAuthenticationSupport
                        ? html` <div hidden class="authenticationProviders">
                              <hr style="height:2px;width: 100%;margin-top: 15px; background-color:lightgray;border-width:0" />
                              <div style="display: flex; align-items: center;justify-content: space-between;">
                                  ${this._appConfigService.isMicrosoftAuthenticationSupport
                                      ? html`
                                    <input type="image" src=${msSignin} style="height: 32px"></input>
                                    ${this._appConfigService.isGoogleAuthenticationSupport ? html` <div class="sign-spacer"></div> ` : ``}
                                `
                                      : ``}
                                  ${this._appConfigService.isGoogleAuthenticationSupport ? html` <div id="signInWithGoogleDiv"></div> ` : ``}
                              </div>
                          </div>`
                        : ``}
                </div>
            </form>
            <div class="footer">
                <div>
                    © ${this._currentYear} Sequentum •
                    <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a> •
                    <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a>
                </div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter} /></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin} /></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram} /></a>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            font-family: Inter;
            background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
            background-size: cover;
        }
        :host * {
            box-sizing: border-box;
        }
        .header {
            box-sizing: border-box;
            background-color: var(--color-primary);
            color: white;
            padding-left: 15px;
            padding-top: 15px;
            display: flex;
            gap: 15px;
            font: var(--font-h3);
        }
        .h3 {
            font: var(--font-h3);
        }
        .header-logo {
            width: 165px;
            height: fit-content;
            margin: 27px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0px;
            padding-top: 0px;
            border-radius: 5px;
        }
        .link {
            color: var(--color-purple-dark);
            text-decoration: none;
        }
        .loginLink {
            display: flex;
            justify-content: center;
            padding: 10px;
            font: var(--font-button);
        }
        .loginButton {
            margin-top: 10px;
        }
        .scroll-container {
            min-height: 0px;
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 40px 90px;
        }
        .content {
            width: 300px;
            gap: 10px;
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .button {
            width: 30px;
            padding: 10px 10px 10px 10px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
        .primary-button {
            display: flex;
            flex-direction: rows;
            justify-content: space-evenly;
            margin-top: 15px;
        }
        .linkLabel {
            display: flex;
            flex: 2;
            font: var(--font-small);
            align-items: right;
            text-decoration: none;
            padding-top: 15px;
            flex-direction: row;
            align-items: center;
        }

        .footer {
            color: rgba(83, 86, 122, 1);
            font: var(--font-small);
            padding: 0px 20px 10px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .footer a {
            color: rgba(83, 86, 122, 1);
            text-decoration: none;
        }
        .footer a:hover {
            color: var(--color-secondary);
            text-decoration: none;
        }
        .sign-spacer {
            width: 10px;
        }
        .inputEditor {
            width: 100%;
        }
        .password-container {
            position: relative;
        }
        .forgot {
            position: absolute;
            top: 0;
            right: 0;
        }
    `;
}
