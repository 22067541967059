import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from '@vaadin/router';
import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { container } from 'tsyringe';
import { AuthService } from '../../services/auth.service';
import { MenuService } from '../../services/menu.service';
import { DestinationService } from '../../services/destination.service';
import { UserState } from '../../services/user.state';

@customElement('se-oauth-response')
export class SeOAuthResponseElement extends LitElement {

    private _authService: AuthService;
    public _destinationService: DestinationService;
    private _userState: UserState;

    @state() private _hasSelectedRows = false;

    private _menuService: MenuService;
    private sheets: any;
    private tempId: any;
    private token: any;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userState = container.resolve(UserState);
        this._destinationService = container.resolve(DestinationService);
        this._menuService = container.resolve(MenuService);
    }



    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();

    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
       // console.log("oAuthResponse.element - onBeforeEnter");        
        if (!this._authService.isUser) {
            return commands.redirect('/login');
        }
        var url = new URL(window.location.href)
        url.searchParams.get("tempId")
       // alert("oAuthResponse.element - onBeforeEnter; url=" + url);
        if (url.searchParams.get("tempId")) {
            this.tempId = decodeURIComponent(url.searchParams.get("tempId"));
            this.token = decodeURIComponent(url.searchParams.get("token"));
        }
        else {
            return commands.prevent();
        }

    }




    firstUpdated() {
        //console.log("oAuthResponse.element - firstUpdated");
        window.opener.postMessage(
            {
                message:"oauthResponse",
                tempId: this.tempId,
                token: this.token,
            }, '*');
    }



    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`            
            <div class="body">

            </div>
        `;
    }

    static styles = css`
    :host {
        display: block;
        box-sizing: border-box;
        font: var(--font);
        height: 100%;
    }
    .body {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .header {
        margin-left: 5px;
        display: flex;
        align-items: end;
        justify-content: space-between;
        overflow:hidden;
        padding-right: 5px;
        margin-right: -5px;
        padding-bottom: 5px;
        margin-bottom: -5px;
    }
    .left-header {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .grid {
        flex:1;
    }
    .checkbox {
        width: 1rem;
        height: 1rem;
    }
    input[type="checkbox"]:checked {
        background-color: var(--color-secondary);
    }
    .label {
        background-color: dimgray;
        border-radius: 3px 3px;
        font: var(--font-smaller);
    }
  `;


}