import { container, inject, singleton } from 'tsyringe';
import { SeEvent } from '../../utils/se-events';
import { AgentTemplateApi } from './agent-template.api';

@singleton()
export class AgentTemplateService {
        
    private _agentTemplateApi: AgentTemplateApi;
   
    
    get api() { return this._agentTemplateApi };    

    constructor() {
        this._agentTemplateApi = container.resolve(AgentTemplateApi);        
    }    

}