import { LitElement } from "lit";
import { singleton } from "tsyringe";
import "../elements/components/context-menu.element";
import { ContextMenuElement } from "../elements/components/context-menu.element";
import { MenuDialog } from "../elements/components/menu-template";
import { MenuItem } from "../elements/components/menu.element";

@singleton()
export class MenuService {
    private _menuGroup: string;
    private _contextMenu: LitElement & MenuDialog;
    private _menuButton: HTMLElement;
    private _activeClass: string;
    private _activeProperty: string;

    private createElement(options: { component: string; button: HTMLElement; activeClass?: string; activeProperty?: string }) {
        this.removeElement();
        this._activeClass = options.activeClass;
        this._activeProperty = options.activeProperty;
        this._menuButton = options.button;
        if (this._activeProperty) {
            this._menuButton[this._activeProperty] = true;
        } else if (this._activeClass) {
            this._menuButton.classList.add(this._activeClass);
        }
        this._contextMenu = document.createElement(options.component) as ContextMenuElement;
        this._contextMenu.style.position = "absolute";
        this._contextMenu.style.zIndex = "1000";
        this._contextMenu.style.left = "-1000px";
        this._contextMenu.style.top = "-1000px";
        document.body.appendChild(this._contextMenu);

        document.onkeyup = (evt) => this.onKeyDown(evt);
    }
    private removeElement() {
        if (this._contextMenu) {
            if (this._activeProperty) {
                this._menuButton[this._activeProperty] = false;
            } else if (this._activeClass) {
                this._menuButton.classList.remove(this._activeClass);
            }
            document.body.removeChild(this._contextMenu);
            this._contextMenu = undefined;
            this._menuButton = undefined;
        }
    }

    closeMenu() {
        this.removeElement();
    }

    private onKeyDown(evt: KeyboardEvent) {
        if (evt.key === "Escape") {
            this.closeMenu();
        }
    }

    positionMenu() {
        if (this._contextMenu && this._menuButton) {
            let foldRight = true;
            const rect = this._menuButton.getBoundingClientRect();

            if (rect.left + this._contextMenu.clientWidth < window.innerWidth - 10) {
                this._contextMenu.style.left = `${rect.left}px`;
                foldRight = true;
            } else {
                this._contextMenu.style.left = `${rect.right - this._contextMenu.clientWidth}px`;
                foldRight = false;
            }
            if (rect.bottom + this._contextMenu.clientHeight < window.innerHeight - 10) {
                this._contextMenu.style.top = `${rect.bottom}px`;
            } else if (rect.top - this._contextMenu.clientHeight > 10) {
                this._contextMenu.style.top = `${rect.top - this._contextMenu.clientHeight}px`;
            } else {
                this._contextMenu.style.top = "5px";
                if (foldRight) {
                    this._contextMenu.style.left = `${rect.right}px`;
                    if (rect.right + this._contextMenu.clientWidth > window.innerWidth - 10) {
                        this._contextMenu.style.left = `${rect.left - this._contextMenu.clientWidth}px`;
                        foldRight = false;
                    }
                } else {
                    this._contextMenu.style.left = `${rect.left - this._contextMenu.clientWidth}px`;
                }
            }
        }
    }

    async openContextMenu(options: {
        isMouseEnter?: boolean;
        button: HTMLElement;
        activeClass?: string;
        activeProperty?: string;
        menu?: MenuItem[];
        group?: string;
        tag?: any;
    }) {
        if (this._contextMenu && !options.isMouseEnter && this._menuButton === options.button) {
            this.closeMenu();
        } else if (((options.isMouseEnter && this.isOpen(options.group)) || !options.isMouseEnter) && this._menuButton !== options.button) {
            this.createElement({ ...options, component: "se-context-menu" });
            await this._contextMenu.showMenu({ menu: options.menu, tag: options.tag });
            this.positionMenu();
            this._menuGroup = options.group;
        }
    }

    async openComponent(options: {
        component: string;
        isMouseEnter?: boolean;
        button: HTMLElement;
        activeClass?: string;
        activeProperty?: string;
        input?: any;
        group?: string;
    }) {
        if (this._contextMenu && !options.isMouseEnter && this._menuButton === options.button) {
            this.closeMenu();
        } else if (((options.isMouseEnter && this.isOpen(options.group)) || !options.isMouseEnter) && this._menuButton !== options.button) {
            this.createElement(options);
            await this._contextMenu.showMenu(options.input);
            this.positionMenu();
            this._menuGroup = options.group;
        }
    }

    private isOpen(group?: string) {
        return (!group || this._menuGroup === group) && this._contextMenu;
    }

    isMenuOpen(button?: HTMLElement) {
        return this._contextMenu && (!button || this._menuButton === button);
    }
}
