export class RateLimit {
    id: number;
    domainName?: string;
    organizationId?: number;
    startDelayConnections?: number;
    connectionDelayIncreaseMs?: number;
    startDelayRequestsPerSecond?: number;
    requestDelayIncreaseMs?: number;
    maxConnections?: number;
    created: Date;
    updated: Date;
}

export class RateLimitUpdateModel {
    id: number;
    domainName?: string;
    organizationId?: number;
    organizationName?: string;
    startDelayConnections?: number;
    connectionDelayIncreaseMs?: number;
    minRequestDelayMs?: number;
    requestDelayIncreaseMs?: number;
    maxConnections?: number;
}

export class RateLimitViewModel {
    id: number;
    domainName?: string;
    organizationId?: number;
    organizationName?: string;
    startDelayConnections?: number;
    connectionDelayIncreaseMs?: number;
    startDelayRequestsPerSecond?: number;
    requestDelayIncreaseMs?: number;
    maxConnections?: number;
    created: Date;
    updated: Date;
}

