import { container, inject, singleton } from 'tsyringe';
import { SeEvent } from '../../utils/se-events';
import { Label } from '../models/label';
import { LabelBookmarkApi } from './label-bookmark.api';
import { LabelApi } from './label.api';

@singleton()
export class LabelService {
        
    private _labelApi: LabelApi;
    private _labelBookmarkApi: LabelBookmarkApi;
    
    get api() { return this._labelApi };
    get bookmarkApi() { return this._labelBookmarkApi };

    constructor() {
        this._labelApi = container.resolve(LabelApi);
        this._labelBookmarkApi = container.resolve(LabelBookmarkApi);
    }

    /*async createLabelAsync(label: Label) {
        const res = await this._labelApi.createAsync(label);
        if (res.isOk) {
            this._labelsChanged.triggerVoid();
        }
        return res;
    }
    async updateLabelAsync(label: Label) {
        const res = await this._labelApi.updateAsync(label);
        if (res.isOk) {
            this._labelsChanged.triggerVoid();
        }
        return res;
    }
    async deleteLabelAsync(id: number) {
        const res = await this._labelApi.deleteAsync(id);
        if (res.isOk) {
            this._labelsChanged.triggerVoid();
        }
        return res;
    }*/

}