import { ProxiesViewModel } from "../control-center/models/proxies-view-model";

export class ProxyUtil {
    /// <summary>
    /// Parse a string and return a ProxiesViewModel object. The
    /// supported formats are:
    ///     address:port,username,password
    ///     username:password@address:port
    /// </summary>
    /// <param name="proxy"></param>
    /// <returns></returns>
    public static ParseProxy(proxy: string): ProxiesViewModel {
        if (proxy == null || proxy == "") {
            return null;
        } else if (this.IsCsv(proxy)) {
            return this.ParseCsvProxy(proxy);
        } else {
            return this.ParseStandardProxy(proxy);
        }
    }

    /// <summary>
    /// Does the proxy contain CSV commas like address:port;username;password
    /// </summary>
    /// <param name="proxy"></param>
    /// <returns></returns>
    private static IsCsv(proxy: string): boolean {
        return proxy != null && proxy.includes(",");
    }

    /// <summary>
    /// Parse a string and return a ProxiesViewModel object. The
    /// supported formats are:
    ///     address:port,username,password
    ///     address:port,username
    /// </summary>
    /// <param name="proxy"></param>
    /// <returns></returns>
    private static ParseCsvProxy(proxy: string): ProxiesViewModel {
        //break apart the columns
        const parts: Array<string> = proxy.split(",");
        let server: string = parts[0];
        let username: string = null;
        let password: string = null;

        let index = 1;
        if (parts.length >= 4) {
            server += ":" + parts[index];
            index = 2;
        }

        if (parts.length >= 3) {
            password = parts[index + 1];
        }

        if (parts.length >= 2) {
            username = parts[index];
        }

        return new ProxiesViewModel(server, username, password);
    }

    /// <summary>
    /// Parse a string and return a ProxiesViewModel object. The
    /// supported formats are:
    ///     username:password@address:port
    ///     username@address:port
    ///     address:port
    /// </summary>
    /// <param name="proxy"></param>
    /// <returns></returns>
    private static ParseStandardProxy(proxy: string): ProxiesViewModel {
        if (!proxy.includes("@")) {
            //no username or password
            return new ProxiesViewModel(proxy, null, null);
        } else {
            const parts: Array<string> = proxy.split("@");
            const credentials: string = parts[0];
            const server: string = parts[1];
            let username: string = null;
            let password: string = null;

            // split up the username and password
            if (credentials.includes(":")) {
                const credentialParts: Array<string> = credentials.split(":");
                username = credentialParts[0];
                password = credentialParts[1];
            } else {
                //only username
                username = credentials;
            }

            return new ProxiesViewModel(server, username, password);
        }
    }

    /**
     * Convert the seperate fields into a single string
     * that is in the standard proxy format of username:password@server
     */
    public static toProxyString(proxy: ProxiesViewModel): string {
        if (proxy.username != null) {
            return `${proxy.username}:${proxy.password}@${proxy.server}`;
        } else {
            return `${proxy.server}`;
        }
    }
}
