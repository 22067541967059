import { container, inject, singleton } from 'tsyringe';
import { AgentPricingApi } from './agent-pricing.api';

@singleton()
export class AgentPricingService {

    private _agentPricingApi : AgentPricingApi;

    get api() { return this._agentPricingApi };

    constructor() {
        this._agentPricingApi = container.resolve(AgentPricingApi);
    }
}