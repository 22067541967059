import { css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { DataGridColumn, DataGridTemplate } from "../components/data-grid-template";
import "../components/fa-icon.element";
import "../menu-editors/add-labels.element";

@customElement("se-name-column")
export class SeNameColumnComponent extends DataGridTemplate {
    @property({ type: Object }) row: any;
    @property({ type: Object }) column: DataGridColumn;

    constructor() {
        super();
    }

    private filterByLabel(labelId: number) {
        this.dispatchEvent(new CustomEvent("filterbylabel", { bubbles: true, composed: true, detail: { labelId: labelId } }));
    }
    private filterByAgentTemplate(agentTemplateId: number) {
        this.dispatchEvent(
            new CustomEvent("filterByAgentTemplate", { bubbles: true, composed: true, detail: { agentTemplateId: agentTemplateId } })
        );
    }
    private filterBySharedFile(sharedFileId: number) {
        this.dispatchEvent(
            new CustomEvent("filterBySharedFile", { bubbles: true, composed: true, detail: { sharedFileId: sharedFileId } })
        );
    }

    private nameMouseMove(evt: MouseEvent) {
        const target = evt.currentTarget as HTMLDivElement;
        const icon = target.getElementsByClassName("addIcon").item(0) as HTMLElement;
        icon.style.opacity = "1";
        icon.style.visibility = "visible";
    }
    private nameMouseLeave(evt: MouseEvent) {
        const target = evt.currentTarget as HTMLDivElement;
        const icon = target.getElementsByClassName("addIcon").item(0) as HTMLElement;
        icon.style.opacity = "0";
        icon.style.visibility = "hidden";
    }

    render() {
        return html`<div
            class="cell"
            @mouseover=${(evt) => {
                if (!this.row.labels) this.nameMouseMove(evt);
            }}
            @mouseleave=${(evt) => {
                if (!this.row.labels) this.nameMouseLeave(evt);
            }}
        >
            <a
                style="margin-right: 2px"
                href="javascript:;"
                @click=${(evt) => {
                    evt.preventDefault();
                    this.column.action(this.row, this.column);
                }}
                ><span
                    style="${this.row.name?.length > 50
                        ? "text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 20vw;display: inline-block;"
                        : ""}"
                    ${htmlTitle(`View details`)}
                    >${this.row.name}</span
                ></a
            >
            ${this.row.labels.map(
                (p) => html`
                    <button class="menu-icon" ${htmlTitle(`Filter by ${p.name} label`)} @mousedown="${() => this.filterByLabel(p.id)}">
                        <span>${p.name}</span>
                    </button>
                `
            )}
            ${this.row.agentTemplates.map(
                (p) => html`
                    <button
                        class="menu-template"
                        ${htmlTitle(`Filter by ${p.name} template`)}
                        @mousedown="${() => this.filterByAgentTemplate(p.id)}"
                    >
                        <span>${p.name}</span>
                    </button>
                `
            )}
            ${this.row.sharedFiles.map(
                (p) => html`
                    <button
                        class="menu-template"
                        ${htmlTitle(`Filter by ${p.name} file`)}
                        @mousedown="${() => this.filterBySharedFile(p.id)}"
                    >
                        <span>${p.name}</span>
                    </button>
                `
            )}
        </div> `;
    }

    static styles = css`
        :host {
        }
        .addIcon {
            font: var(--font-small);
            visibility: hidden;
            transition: all 0.25s;
            opacity: 0;
            color: gray;
        }
        .addIcon:hover {
            color: black;
        }
        .cell {
            display: flex;
            gap: 3px;
            align-items: baseline;
            flex-wrap: wrap;
        }
        .cell a {
            text-decoration: none;
        }
        .cell a:hover {
            text-decoration: underline;
        }
        .menu-icon {
            font: var(--font-label);
            background-color: gray;
            border: solid 1px gray;
            border-radius: 5px 5px;
            padding: 0;
            white-space: nowrap;
            height: 1.3em;
            color: white;
            opacity: 0.8;
            box-shadow: none;
            display: flex;
            gap: 5px;
            align-items: center;
            transition: all 0.25s;
            padding: 0px 3px 1px 3px;
        }
        .menu-icon:hover {
            border: solid 1px dimgray;
            box-shadow: 2px 2px 2px DarkGray;
        }
        .menu-template {
            font: var(--font-label);
            background-color: var(--color-secondary);
            border: solid 1px var(--color-secondary);
            border-radius: 5px 5px;
            padding: 0;
            white-space: nowrap;
            height: 1.3em;
            color: white;
            opacity: 0.8;
            box-shadow: none;
            display: flex;
            gap: 5px;
            align-items: center;
            transition: all 0.25s;
            padding: 0px 3px 1px 3px;
        }
        .menu-template:hover {
            border: solid 1px dimgray;
            box-shadow: 2px 2px 2px DarkGray;
        }
        .menu-active {
            box-shadow: none !important;
            border: solid 1px dimgray;
        }

        .addIcon {
            font: var(--font-smaller);
            background-color: white;
            border: solid 1px Silver;
            border-radius: 3px 3px;
            padding: 0;
            height: 1.4em;
            color: gray;
            opacity: 0.8;
            box-shadow: none;
            display: flex;
            gap: 5px;
            align-items: center;
            transition: all 0.25s;
            padding: 0px 3px 1px 3px;
        }
        .addIcon:hover {
            border: solid 1px gray;
            box-shadow: 3px 3px 3px lightgray;
        }
        .addIcon-active {
            border: solid 1px gray;
            box-shadow: none !important;
        }
    `;
}
