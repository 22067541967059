import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import instagram from "../../../assets/instagram.svg";
import linkedin from "../../../assets/linkedin.svg";
import logo from "../../../assets/logo.png";
import twitter from "../../../assets/twitter.svg";
import { AuthService } from "../services/auth.service";
import "./components/primary-button.element";
import "./components/secondary-button.element";
import { BaseEditor } from "./editors/base-editor";
import "./editors/input-editor.element";
import { InputEditorElement } from "./editors/input-editor.element";

@customElement("se-change-password")
export class ChangePasswordElements extends LitElement {
    //    private _appConfigService: AppConfigService;
    private _toasterService: ToasterService;
    private _authService: AuthService;
    private _email: string;
    //   private _user: User;
    //   private _token: string;

    @query("#currentPassword") private _currentPasswordEditor: InputEditorElement;
    @query("#password") private _passwordEditor: InputEditorElement;
    @query("#confirmPassword") private _comparePasswordEditor: InputEditorElement;

    @state() private _hasChanged = false;

    constructor() {
        super();

        //        this._appConfigService = container.resolve(AppConfigService);
        this._toasterService = container.resolve(ToasterService);
        this._authService = container.resolve(AuthService);
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }
        return undefined;
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
    }
    disconnectedCallback() {
        this.removeEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        super.disconnectedCallback();
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }

    updateChangedValues() {}

    show() {
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }

    firstUpdated() {}
    private fillDefaults() {}

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async save(ev) {
        ev.preventDefault();
        if (this.reportValidity()) {
            this._hasChanged = false;
            this._email = this._authService.user.email;
            await this._authService
                .ChangePassword(this._authService.user.id, this._currentPasswordEditor.liveValue, this._passwordEditor.liveValue)
                .then(() => this.success())
                .catch((err) => this.error(err));
        }
    }

    private async success() {
        this._toasterService.showSuccess("Password successfully updated.");
        this._authService.logout();
        this._authService
            .loginAsync(this._email, this._passwordEditor.liveValue, true)
            .then(() => this.reLogin())
            .catch((err) => this.error(err));
    }

    private reLogin() {
        history.back();
    }

    private async cancel(ev) {
        ev.preventDefault();
        history.back();
    }

    private error(err: Error) {
        alert(err.message);
        this._hasChanged = true;
    }

    passwordChanged() {
        if (this._passwordEditor.liveValue !== this._comparePasswordEditor.liveValue) {
            this._comparePasswordEditor.setCustomValidity("Passwords don't match.");
        } else {
            this._comparePasswordEditor.setCustomValidity("");
            this._comparePasswordEditor.reportValidity();
        }
    }

    render() {
        return html`
            <div class="header"></div>
            <form id="editorForm" class="editor">
                <p class="h3"><img src=${logo} class="header-logo" background /></p>
                <div class="scroll-container">
                    <se-input-editor
                        class="inputEditor"
                        id="currentPassword"
                        name="currentPassword"
                        type="text"
                        placeholder="Current Password"
                        labelPosition="top"
                        input-type="password"
                        required
                        size="30"
                        tabindex="1"
                    ></se-input-editor>
                    <se-input-editor
                        class="inputEditor"
                        @editorChanged=${this.passwordChanged}
                        id="password"
                        name="password"
                        type="text"
                        placeholder="Password"
                        labelPosition="top"
                        input-type="password"
                        required
                        size="30"
                    ></se-input-editor>
                    <se-input-editor
                        class="inputEditor"
                        @editorChanged=${this.passwordChanged}
                        id="confirmPassword"
                        name="confirmPassword"
                        type="text"
                        placeholder="Confirm password"
                        labelPosition="top"
                        input-type="password"
                        required
                        size="30"
                    ></se-input-editor>
                </div>
                <div class="savePanel">
                    <se-primary-button
                        @click="${this.save}"
                        ?disabled="${!this._hasChanged}"
                        text="Save"                        
                    ></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
            <div class="footer">
                <div>
                    © 2022 Sequentum • <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a> •
                    <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a>
                </div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter} /></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin} /></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram} /></a>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .header {
            box-sizing: border-box;
            background-color: var(--color-primary);
            color: white;
            padding-left: 15px;
            padding-top: 15px;
            display: flex;
            gap: 15px;
            font: var(--font-h3);
        }
        .h3 {
            font: var(--font-h3);
            background-color: var(--color-primary);
            padding: 10px 10px 10px 10px;
        }
        .h4 {
            font: var(--font-h3);
            background-color: white;
            padding: 10px 10px 10px 10px;
        }
        .header-logo {
            width: 175px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
            padding: 0px 30px 20px 30px;
            padding-top: 0px;
            border-radius: 5px 5px;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: hidden;
            padding: 10px 10px 20px 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
        }
        .inputEditor {
            padding: 10px 10px 10px 10px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 10px;
        }
        .linkLabel {
            display: flex;
            flex: 2;
            font: var(--font-small);
            align-items: right;
            text-decoration: none;
            padding-top: 15px;
            flex-direction: row;
            align-items: center;
        }
        .footer {
            color: rgba(83, 86, 122, 1);
            font: var(--font-small);
            padding: 0px 20px 10px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .footer a {
            color: rgba(83, 86, 122, 1);
            text-decoration: none;
        }
        .footer a:hover {
            color: var(--color-secondary);
            text-decoration: none;
        }
    `;
}
