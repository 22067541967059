import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { TypeaheadEditorElement } from "se-shared/elements/editors/inline/typeahead-editor.element";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { idName } from "../../models/id-name";
import { RateLimitUpdateModel } from "../../models/rate-limit";
import { AuthService } from "../../services/auth.service";
import { OrganizationService } from "../../services/organization.service";
import { RateLimitService } from "../../services/rate-limit.service";
import { BaseEditor } from "../editors/base-editor";
import { NumberEditorElement } from "../editors/inline/number-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";

@customElement("se-rate-limit-editor")
export class SeRateLimitEditorElement extends LitElement {
    private _authService: AuthService;
    private _rateLimitService: RateLimitService;
    private _isNew = false;
    private _rateLimitId?: number;
    private _rateLimit?: RateLimitUpdateModel;
    private _organizationService: OrganizationService;
    private _toasterService: ToasterService;

    private _organizations: idName[] = [];

    @query("#domainName") private _nameEditor: InputEditorElement;
    @query("#organization") private _organizationEditor: TypeaheadEditorElement;
    @query("#startDelayConnections") private _startDelayConnectionsEditor: NumberEditorElement;
    @query("#connectionDelayIncreaseMs") private _connectionDelayIncreaseMsEditor: NumberEditorElement;
    @query("#minRequestDelayMs") private _minRequestDelayMsEditor: NumberEditorElement;
    @query("#requestDelayIncreaseMs") private _requestDelayIncreaseMsEditor: NumberEditorElement;
    @query("#maxConnections") private _maxConnectionsEditor: NumberEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._rateLimitService = container.resolve(RateLimitService);
        this._toasterService = container.resolve(ToasterService);
        this._organizationService = container.resolve(OrganizationService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.rateLimitId) {
            this._rateLimitId = parseInt(location.params.rateLimitId.valueOf() as string);
            this._isNew = false;
            //return this._spaceApi.getAsync(this._spaceId).then(space => this._space = space).catch(err => this.error(err));
            const res = await this._rateLimitService.api.getAsync(this._rateLimitId);
            if (res.isOk) {
                this._rateLimit = res.value;
            } else {
                this._toasterService.showNetworkError(res.err);
                return commands.prevent();
            }
        } else {
            this._isNew = true;
            this._rateLimit = new RateLimitUpdateModel();
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private async getOrganizationsAsync() {
        const result = await this._organizationService.api.getAllIdAndNamesAsync();
        if (result.isOk) {
            this._organizations = result.value;
            return result.value.map((p) => p.name);
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            if (this._organizations?.length) {
                this._rateLimit.organizationId = this._organizationEditor.liveValue
                    ? this._organizations?.filter((p) => p.name === this._organizationEditor.liveValue).map((p) => p.id)[0]
                    : undefined;
            }
            this._rateLimit.domainName = this._nameEditor.liveValue;
            this._rateLimit.startDelayConnections = this._startDelayConnectionsEditor.liveValue;
            this._rateLimit.connectionDelayIncreaseMs = this._connectionDelayIncreaseMsEditor.liveValue;
            this._rateLimit.minRequestDelayMs = this._minRequestDelayMsEditor.liveValue;
            this._rateLimit.requestDelayIncreaseMs = this._requestDelayIncreaseMsEditor.liveValue;
            this._rateLimit.maxConnections = this._maxConnectionsEditor.liveValue;

            const res = this._isNew
                ? await this._rateLimitService.api.createAsync(this._rateLimit)
                : await this._rateLimitService.api.updateAsync(this._rateLimit);
            if (res.isOk) {
                history.back();
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "Add" : "Edit"} Rate Limit</div>
                <div class="scroll-container">
                    <se-input-editor
                        id="domainName"
                        label="Domain name"
                        type="text"
                        labelPosition="top"
                        input-type="text"
                        size="30"
                        .value=${this._rateLimit.domainName}
                    ></se-input-editor>
                    <se-typeahead-editor
                        id="organization"
                        label="Organization"
                        show-all
                        .value=${this._rateLimit.organizationName ?? ""}
                        .getTextOptions=${this.getOrganizationsAsync.bind(this)}
                        min-suggestion-width="200px"
                    ></se-typeahead-editor>
                    <se-number-editor
                        id="startDelayConnections"
                        label="Start delay on number of connections"
                        labelPosition="top"
                        size="30"
                        .value=${this._rateLimit.startDelayConnections}
                    ></se-number-editor>
                    <se-number-editor
                        id="connectionDelayIncreaseMs"
                        label="Connection delay increase (ms)"
                        labelPosition="top"
                        size="30"
                        .value=${this._rateLimit.connectionDelayIncreaseMs}
                    ></se-number-editor>
                    <se-number-editor
                        id="requestDelayIncreaseMs"
                        label="Request delay increase (ms)"
                        labelPosition="top"
                        size="30"
                        .value=${this._rateLimit.requestDelayIncreaseMs}
                    ></se-number-editor>
                    <se-number-editor
                        id="minRequestDelayMs"
                        label="Min. request delay (ms)"
                        labelPosition="top"
                        size="30"
                        .value=${this._rateLimit.minRequestDelayMs}
                    ></se-number-editor>
                    <se-number-editor
                        id="maxConnections"
                        label="Max. number of connections"
                        labelPosition="top"
                        size="30"
                        .value=${this._rateLimit.maxConnections}
                    ></se-number-editor>
                </div>
                <div class="savePanel">
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Add" : "Save"} Rate Limit"
                    ></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
