import { container, inject, singleton } from 'tsyringe';
import { ServerKeysApi } from './server-keys.api';

@singleton()
export class ServerKeysService {

    private _serverKeysApi: ServerKeysApi;

    get api() { return this._serverKeysApi };

    constructor() {
        this._serverKeysApi = container.resolve(ServerKeysApi);
    }
}