import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ConfigType } from "../../enums/config-type";
import { ValidationStatus } from "../../enums/validation-status";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { SpaceService } from "../../services/space.service";
import { ServerService } from "../../services/server.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import "../components/fa-icon.element";
import "./agent-info.element";
import "../run/run-details.element";
import "./config-version-history.element";
import "./agent-pricing-editor.element";
import "./agent-alerts-editor.element";
import { MenuService } from "../../services/menu.service";
import { idName, idNameSort } from "../../models/id-name";
import { styleMap } from "lit/directives/style-map.js";
import { ModalDialogService } from "../../services/modal-editor.service";

type GridType = "info" | "versions" | "runs" | "run-details" | "run-history" | "run-history-details" | "tasks" | "alerts" | "agent-pricing";

@customElement("se-agent-details")
export class SeAgentDetailsElement extends LitElement {
    private _authService: AuthService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _serverService: ServerService;
    private _configService: ConfigService;
    private _spaceService: SpaceService;
    private _menuService: MenuService;
    private _modalService: ModalDialogService;

    private _configId: number;
    @state() private _gridType: GridType;
    @state() private _spaceName: string;
    private _spaceId: number;
    private _lastSelectedLabelId: number;
    private _runId: number;

    @state() private _hasSelectedRows = false;

    private _labels: idName[];
    private _templates: idNameSort[];
    private _sharedFiles: idName[];

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._serverService = container.resolve(ServerService);
        this._configService = container.resolve(ConfigService);
        this._spaceService = container.resolve(SpaceService);
        this._menuService = container.resolve(MenuService);
        this._modalService = container.resolve(ModalDialogService);
    }

    async connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.addEventListener("agentValidationStatusChanged", (ev: CustomEvent) => this.onAgentValidationStatusChanged(ev));

        //if (this._userState.selectedConfigId !== this._configId) {
        const result = await this._configService.api.getNameAndTypeAsync(this._configId);
        //SE4 Admin change org prompt
        if (this._authService.isSE4Admin && result.value && result.value.message == "Organization Mismatch") {
            const confirmDialog = await this._modalService.openConfirmDialogAsync({
                title: `Organization Mismatch"`,
                body: `Do you want to switch organization and view the agent ?`,
                saveCaption: "Change",
            });
            if (confirmDialog.isSave) {
                window.dispatchEvent(
                    new CustomEvent("changeOrganization", { detail: { orgId: result.value.organizationId }, bubbles: true, composed: true })
                );
            }
        }
        if (result.isOk) {
            this._userState.selectedConfigId = this._configId;
            this._userState.selectedConfigName = result.value.name;
            this._userState.selectedConfigType = result.value.type;
            this._userState.selectedConfigValidationStatus = result.value.validationStatus;
        } else {
            if (result.err.status == 404) return this.showNotFound();
            this._toasterService.showNetworkError(result.err);
        }
        //}
        if (!this._userState.selectedSpaceId) {
            this._spaceName = "Personal";
            this._spaceId = 0;
            this._lastSelectedLabelId = this._userState.getStoredLabelId();
        } else {
            this._spaceName = result.value.spaceName;
            this._spaceId = this._userState.selectedSpaceId;
            this._lastSelectedLabelId = this._userState.getStoredLabelId();
        }
    }
    disconnectedCallback() {
        this.removeEventListener("agentValidationStatusChanged", (ev: CustomEvent) => this.onAgentValidationStatusChanged(ev));
        super.disconnectedCallback();
    }
    showNotFound() {
        this.dispatchEvent(
            new CustomEvent("not-found-screen", {
                bubbles: true,
                composed: true,
            })
        );
    }
    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands) {
        if (!this._authService.isUser) {
            return commands.redirect("/login");
        }
        if (location.params.configId) {
            this._configId = parseInt(location.params.configId.valueOf() as string);
        }
        if (location.params.gridType) {
            this._gridType = location.params.gridType.valueOf() as GridType;
        } else {
            return commands.prevent();
        }
        if (location.params.runId) {
            this._runId = parseInt(location.params.runId.valueOf() as string);
        }
    }

    private onAgentValidationStatusChanged(evt: CustomEvent) {
        this._userState.selectedConfigValidationStatus = evt.detail.validationStatus;
    }

    private goInfo() {
        this._gridType = "info";
        window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/config/${this._configId}/details/info`);
    }
    private goVersions() {
        this._gridType = "versions";
        window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/config/${this._configId}/details/versions`);
    }
    private goRunHistory() {
        this._gridType = "run-history";
        window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/config/${this._configId}/details/run-history`);
    }
    private goTasks() {
        this._gridType = "tasks";
        window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/config/${this._configId}/details/tasks`);
    }
    private goToAlerts() {
        this._gridType = "alerts";
        window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/config/${this._configId}/details/alerts`);
    }
    private goToPricing() {
        this._gridType = "agent-pricing";
        window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/config/${this._configId}/details/agent-pricing`);
    }
    private goRuns() {
        this._gridType = "runs";
        window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/config/${this._configId}/details/runs`);
    }
    private goRunDetails(runId: number) {
        this._gridType = "run-details";
        this._runId = runId;
        window.history.pushState(
            "",
            "",
            `/space/${this._userState.selectedSpaceId}/config/${this._configId}/details/run-details/run/${runId}`
        );
    }
    private goRunHistoryDetails(runId: number) {
        this._gridType = "run-history-details";
        this._runId = runId;
        window.history.pushState("", "");
        Router.go(`/space/${this._userState.selectedSpaceId}/config/${this._configId}/details/run-history-details/run/${runId}`);
    }
    private newRun() {
        Router.go(`space/${this._userState.selectedSpaceId}/label/${this._userState.selectedLabelId}/config/${this._configId}/run`);
    }

    private async openEditor(input: { configType?: ConfigType; draftId?: number; configId?: number; spaceId?: number }): Promise<boolean> {
        //get SeEditor URL from server
        let url = null;
        let editorServer = null;
        let hostApi = window.location.href;

        this._toasterService.clear();
        const response = await this._serverService.api.getAgentEditorDetailsAsync();

        if (response.isErr) {
            this._toasterService.showNetworkError(response.err);
            return false;
        } else {
            //url = "https://" + response.value.url;
            url = response.value.url;
            editorServer = response.value.editorServer;
            hostApi = response.value.hostApi !== null ? response.value.hostApi : hostApi;
        }

        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", url);

        const params = {
            ...{
                redirectUrl: window.location.href, //callback url when editing is complete
                apiUrl: window.location.protocol + "//" + hostApi + "/api", //internal api url to use server side
                webApiUrl: window.location.origin + "/api", //external api url to use when querying control center
                token: this._authService.token, //auth token
                isInternalOrg: this._authService.user.organizationName === "Sequentum", // Hardcoded, internal organization which has special previllages
                editorServerHost: editorServer, //editor server that editor client should connect to
                orgId: this._authService.user.organizationId,
                isProxyRequired: this._authService.orgSettings.isProxyRequired,
            },
            ...input,
        };
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        return true;
    }

    onShowRunDetails(ev: CustomEvent) {
        this.goRunDetails(ev.detail.runId);
    }

    onShowRunHistoryDetails(ev: CustomEvent) {
        this.goRunHistoryDetails(ev.detail.runId);
    }

    private getIconTemplate(icon: string) {
        return html`<span
            style="${styleMap({
                color:
                    this._userState.selectedConfigValidationStatus === ValidationStatus.Valid
                        ? "gray"
                        : this._userState.selectedConfigValidationStatus === ValidationStatus.Invalid
                          ? "salmon"
                          : "goldenrod",
            })}"
            ${this._userState.selectedConfigValidationStatus !== ValidationStatus.Valid
                ? htmlTitle(
                      this._userState.selectedConfigValidationStatus === ValidationStatus.Invalid
                          ? "Agent is invalid"
                          : "Agent has not been validated"
                  )
                : undefined}
            ><fa-icon class="icon" fa-class="far ${icon}"></fa-icon
        ></span>`;
    }

    private getConfigIcon() {
        return html`${choose(
            this._userState.selectedConfigType,
            [
                [ConfigType.Agent, () => this.getIconTemplate("fa-robot")],
                [ConfigType.Template, () => this.getIconTemplate("fa-cubes")],
                [ConfigType.SharedFile, () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-file"></fa-icon>`],
            ],
            () => html`${this._userState.selectedConfigType}`
        )}`;
    }

    private _goBack() {
        Router.go(`/space/${this._spaceId}/label/${this._lastSelectedLabelId}`);
    }

    private async openLabelsMenu(event: MouseEvent) {
        event.stopPropagation();
        this._menuService.openComponent({
            component: "se-add-labels",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: {
                configId: this._configId,
            },
        });
    }

    private async openAgentTemplatesMenu(event: MouseEvent) {
        event.stopPropagation();

        await this._menuService.openComponent({
            component: "se-apply-templates",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: {
                configId: this._configId,
                callback: () => this.templatesChanged(),
            },
        });
    }
    private templatesChanged() {
        this.validateAgent();
    }

    private openSharedFilesMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-assign-shared-files",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: {
                configId: this._configId,
            },
        });
    }

    private openMoveMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-move-configs",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: {
                selectedRows: [{ id: this._configId, name: this._userState.selectedConfigName }],
                callback: () => this.configMoved(),
            },
        });
    }
    private async configMoved() {
        const result = await this._configService.api.getConfigSpaceName(this._configId);
        if (result.isOk) {
            this._spaceName = result.value.name;
            this._spaceId = result.value.id;
            this._lastSelectedLabelId = 0;
            this._userState.selectedSpaceId = this._spaceId;
            this._userState.selectedSpaceOrLabelChanged.triggerVoid();
            this._toasterService.showSuccess(`${this._userState.selectedConfigName} moved to ${this._spaceName} successfully.`);
            this.validateAgent();
        }
    }

    private openCopyMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-copy-configs",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: {
                selectedRows: [{ id: this._configId, name: this._userState.selectedConfigName }],
                callback: () => this.configCopied(),
            },
        });
    }

    private configCopied() {
        this._toasterService.showSuccess(`${this._userState.selectedConfigName} copied successfully.`);
    }

    private async validateAgentEvent(event: MouseEvent) {
        event.stopPropagation();
        this.validateAgent();
    }

    private async validateAgent() {
        const result = await this._configService.api.validateAllAsync([this._configId]);
        if (result.isOk) {
            if (result.value.length > 0) {
                this._userState.selectedConfigValidationStatus = result.value[0].validationStatus;
            }
        } else if (result.isErr) {
            this._toasterService.showNetworkError(result.err);
        }
    }
    private async onDelete(event: MouseEvent) {
        event.stopPropagation();
        const result = await this._modalService.openConfirmDialogAsync({
            title: `Delete "${this._userState.selectedConfigName}"`,
            body: `Are you sure you want to delete "${this._userState.selectedConfigName}" and ALL related data?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._configService.api.deleteAsync(this._configId);
            if (result.isOk) {
                Router.go(`/space/${this._spaceId}/label/${this._lastSelectedLabelId}`);
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    render() {
        return html`
            <div class="breadcrumbs">
                <a href="/manage/spaces">Spaces</a>
                <span>/</span>
                <a href="/space/${this._userState.selectedSpaceId}">${this._spaceName}</a>
                <span>/</span>
                <a>${this._userState.selectedConfigName}</a>
            </div>
            <div class="top">
                <div class="top-header">
                    <div class="left-header" style="gap: 10px;">
                        <div class="title"><h1>${this._userState.selectedConfigName}</h1></div>
                        <div style="margin-top: 12px">${this.getConfigIcon()}</div>
                    </div>
                    <div class="right-header"></div>
                </div>
                <div style="display: flex; gap:2px;">
                    <se-secondary-button
                        @mousedown=${(event) => this.openLabelsMenu(event)}
                        ${htmlTitle("Apply labels", false, undefined, undefined, true)}
                        .customStyle=${{ padding: "1px 6px", margin: 0 }}
                        icon="far fa-tag"
                    ></se-secondary-button>
                    ${this._userState.selectedConfigType === ConfigType.Agent
                        ? html`<se-secondary-button
                              @mousedown=${(event) => this.openAgentTemplatesMenu(event)}
                              ${htmlTitle("Apply templates", false, undefined, undefined, true)}
                              .customStyle=${{ padding: "1px 6px", margin: 0 }}
                              icon="far fa-cubes"
                          ></se-secondary-button>`
                        : html``}
                    <se-secondary-button
                        @mousedown=${(event) => this.openSharedFilesMenu(event)}
                        ${htmlTitle("Assign shared files", false, undefined, undefined, true)}
                        .customStyle=${{ padding: "1px 6px", margin: 0 }}
                        icon="far fa-file"
                    ></se-secondary-button>
                    <se-secondary-button
                        @mousedown=${(event) => this.openMoveMenu(event)}
                        ${htmlTitle("Move to...", false, undefined, undefined, true)}
                        .customStyle=${{ padding: "1px 6px", margin: 0 }}
                        icon="far fa-truck"
                    ></se-secondary-button>
                    <!--<se-secondary-button
                            @mousedown=${(event) => this.openCopyMenu(event)}
                            ${htmlTitle("Copy to...", false, undefined, undefined, true)}
                            .customStyle=${{ padding: "1px 6px", margin: 0 }}
                            icon="far fa-copy"
                        ></se-secondary-button>-->
                    ${this._userState.selectedConfigValidationStatus === ValidationStatus.Unknown
                        ? html`<se-secondary-button
                              @mousedown=${(event) => this.validateAgentEvent(event)}
                              ${htmlTitle("Validate agent.", false, undefined, undefined, true)}
                              .customStyle=${{ padding: "1px 6px", margin: 0 }}
                              icon="far fa-clipboard-check"
                          ></se-secondary-button>`
                        : html``}
                    <se-secondary-button
                        @mousedown=${(event) => this.onDelete(event)}
                        ${htmlTitle(
                            `Delete this ${this._userState.selectedConfigType === ConfigType.Agent ? "agent" : "template"}`,
                            false,
                            undefined,
                            undefined,
                            true
                        )}
                        .customStyle=${{ padding: "1px 6px", margin: 0 }}
                        icon="far fa-trash-alt"
                        red-hover
                    ></se-secondary-button>
                </div>
            </div>
            <div style="display: flex; border-bottom: solid 1px gray; align-items: end; overflow-x:clip;">
                <span class="tabItems ${this._gridType === "info" ? `active` : ``}" @click=${this.goInfo}>Info</span>
                <span class="tabItems ${this._gridType === "versions" ? `active` : ``}" @click=${this.goVersions}>Versions</span>
                <span class="tabItems ${this._gridType === "runs" ? `active` : ``}" @click=${this.goRuns}>Runs</span>
                ${this._gridType === "run-details" ? html`<span class="tabItems active">Run Details</span>` : html``}
                <span class="tabItems ${this._gridType === "run-history" ? `active` : ``}" @click=${this.goRunHistory}>Run History</span>
                ${this._gridType === "run-history-details" ? html`<span class="tabItems active">Run History Details</span>` : html``}
                <span class="tabItems ${this._gridType === "tasks" ? `active` : ``}" @click=${this.goTasks}>Tasks</span>
                <span class="tabItems ${this._gridType === "alerts" ? `active` : ``}" @click=${this.goToAlerts}>Alerts</span>
                ${this._authService.isSE4Admin
                    ? html`<span class="tabItems ${this._gridType === "agent-pricing" ? `active` : ``}" @click=${this.goToPricing}
                          >Pricing</span
                      >`
                    : html``}
                <div style="margin-right:10px"></div>
                <div style="margin-right:10px;margin-left: auto;"></div>
                <se-primary-button
                    style="padding-bottom: 5px;"
                    text="Edit Agent"
                    .action="${() => this.openEditor({ configId: this._configId, spaceId: this._userState.selectedSpaceId })}"
                    action-text="Opening..."
                    action-delay="500"
                    min-sizing="growth"
                ></se-primary-button>
                <se-primary-button style="padding-bottom: 5px;" text="Setup Run" @click=${this.newRun}></se-primary-button>
            </div>
            <div style="flex:1; min-height:0">
                ${this._gridType === "info" ? html`<se-agent-info .configId="${this._configId}"></se-agent-info>` : ``}
                ${this._gridType === "versions"
                    ? html`<se-config-version-history .configId="${this._configId}"></se-config-version-history>`
                    : ``}
                ${this._gridType === "runs"
                    ? html`<se-runs @showRunDetails=${this.onShowRunDetails} .configId="${this._configId}"></se-runs>`
                    : ``}
                ${this._gridType === "run-details"
                    ? html`<se-run-details .configId=${this._configId} .runId=${this._runId}></se-run-details>`
                    : ``}
                ${this._gridType === "run-history"
                    ? html`<se-run-history
                          @showRunHistoryDetails=${this.onShowRunHistoryDetails}
                          .configId="${this._configId}"
                      ></se-run-history>`
                    : ``}
                ${this._gridType === "run-history-details"
                    ? html`<se-run-history-details .configId=${this._configId} .runId=${this._runId}></se-run-history-details>`
                    : ``}
                ${this._gridType === "tasks" ? html`<se-agent-tasks .configId="${this._configId}"></se-agent-tasks>` : ``}
                ${this._gridType === "agent-pricing"
                    ? html`<se-agent-pricing-editor .configId="${this._configId}"></se-agent-pricing-editor>`
                    : ``}
                ${this._gridType === "alerts" ? html`<se-agent-alerts-editor .configId="${this._configId}"></se-agent-alerts-editor>` : ``}
            </div>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
            gap: 20px;
            color: var(--color-primary);
            padding: 15px;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .tabItems {
            cursor: pointer;
            padding: 10px;
            position: relative;
        }
        .active {
            color: var(--color-purple);
            font-weight: 500;
        }
        .active::before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .top-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .right-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .breadcrumbs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            font: var(--font-smaller);
        }
        .breadcrumbs a {
            color: var(--color-primary);
            text-decoration: none;
        }
        .breadcrumbs a:hover {
            text-decoration: underline;
        }
        h1 {
            margin: 10px 0px 5px;
            font-weight: 600;
        }
    `;
}
